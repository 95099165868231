import { useState } from "react";
import { createUseStyles } from "react-jss";
// import "antd/dist/antd.css";

// Sub Components
import MyGraph from "../LineChart/LineChartComponent_gradient";
import DropdownSelector from "../LineChart/Menu";
import dataSet from "./Data";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../utils/theme";
import { ArrowUpward } from "@mui/icons-material";

function Analytics() {
  const classes = useStyles();
  const [data, setData] = useState(dataSet.Today);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const fetchCustomData = (key) => {
    // debugger;
    setData(dataSet[key]);
  };

  return (
    <div className={classes.container}>
      {/* <h1>Analytics</h1> */}
      <Box className="card-header" padding="22.75px 0 6.5px 12px">
        <Grid container spacing={2}>
          <Grid item xs={7} md={7}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" color={colors.grey[500]}>
                $
              </Typography>
              <Typography
                variant="h2"
                color={colors.grey[200]}
                paddingLeft={1}
                fontWeight="bold"
              >
                {3274.92}
              </Typography>
              <Box
                color="#4AAE8C"
                marginLeft="10px"
                backgroundColor="#DEFFEE"
                padding="4px 6px 4px 3px"
                borderRadius="15px"
                display="flex"
                alignSelf="center"
                fontSize="10px"
              >
                <ArrowUpward sx={{ height: "15px", width: "15px" }} /> {9.2} %
              </Box>
            </Box>
            <Typography variant="h6" color={colors.grey[500]}>
              Avg. Agent Earnings
            </Typography>
          </Grid>
          <Grid item xs={5} md={5}>
            <DropdownSelector fetchCustomData={fetchCustomData} />

            {/* <Box position="relative" display="inline-block" width="90%">
                  <input
                  type="text"
                  readOnly
                  value={`${dateRange[0].startDate.toDateString()} - ${dateRange[0].endDate.toDateString()}`}
                  onClick={toggleOpen}
                  style={{
                    padding: "7px",
                    width: "100%",
                      textAlign: "center",
                      background: "#F9F9F9",
                      border: "0px",
                      borderRadius: "6px",
                      color: "#7E8299",
                      }}
                      />
                  {open && (
                    <Box position="absolute" zIndex="1000" top="100%" left="0">
                    <DateRangePicker
                    onChange={handleSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    direction="horizontal"
                    />
                    </Box>
                    )}
                </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box
        className={classes.content}
        // display="flex"
        // style={{ background: "#4C6FFF" }}
      >
        <Box className={classes.buttonTabs}>
          <Button
            variant="contained"
            sx={{ width: "50%" }}
            style={{ color: "#FFFFFF", backgroundColor: "#4C6FFF" }}
            disableElevation
          >
            Earnings From Events
          </Button>
          <Button
            variant="contained"
            sx={{ width: "50%" }}
            style={{ color: "#3F4254", backgroundColor: "white" }}
            disableElevation
          >
            Earnings From Commisions
          </Button>
        </Box>
        <Box className={classes.graph}>
          <MyGraph data={data} />
        </Box>
        <Box className={classes.dataList}>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Typography variant="h6" color={colors.grey[500]}>
              January
            </Typography>
            <Box display="flex" justifyContent="space-between" minWidth="30%">
              <Typography variant="h6" color={colors.grey[100]}>
                $2,756.26
              </Typography>
              <Typography variant="h6" color={colors.redAccent[500]}>
                -139.34
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Typography variant="h6" color={colors.grey[500]}>
              February
            </Typography>
            <Box display="flex" justifyContent="space-between" minWidth="30%">
              <Typography variant="h6" color={colors.grey[100]}>
                $3,207.03
              </Typography>
              <Typography variant="h6" color={colors.greenAccent[500]}>
                +576.24
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Typography variant="h6" color={colors.grey[500]}>
              March
            </Typography>
            <Box display="flex" justifyContent="space-between" minWidth="30%">
              <Typography variant="h6" color={colors.grey[100]}>
                $3,207.03
              </Typography>
              <Typography variant="h6" color={colors.greenAccent[500]}>
                +576.24
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Typography variant="h6" color={colors.grey[500]}>
              April
            </Typography>
            <Box display="flex" justifyContent="space-between" minWidth="30%">
              <Typography variant="h6" color={colors.grey[100]}>
                $3,207.03
              </Typography>
              <Typography variant="h6" color={colors.greenAccent[500]}>
                +576.24
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Analytics;

const useStyles = createUseStyles(() => ({
  container: {
    // color: "#fff",
    // padding: '1rem',
    transition: "0.3s ease-in-out",
    // width: '1200px',
    // height: '400px',
    // display: "flex",
    // flexDirection:'column',
    position: "relative",
    // marginTop: "2vh",
  },
  content: {
    marginTop: "2vh",
  },
  graph: {
    marginTop: "2vh",
  },
  dataList: {
    marginTop: "2vh",
    padding: "0 20px 0 20px",
  },
}));
