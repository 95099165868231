import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Paper,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Box,
  IconButton,
  Grid,
  InputAdornment,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import data from "../TalentPool/data.json";

const EditModelProfile = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState(null);

  useEffect(() => {
    const modelData = data.find((item) => item.id === parseInt(id));
    if (modelData) {
      setModel(modelData);
    }
  }, [id]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModel((prevModel) => ({
      ...prevModel,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    // const newValue = e.target.checked ? 'Yes' : 'No';
    // setSendMessage(newValue);
    const { name, value } = e.target;
    setModel((prevModel) => ({
      ...prevModel,
      [name]: e.target.checked ? "Yes" : "No",
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setModel((prevModel) => ({
        ...prevModel,
        profile: { ...prevModel.profile, image: reader.result },
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleSave = () => {
    // Handle save logic
    // Example: Update the data array, make an API call, etc.
    navigate("/talentpool");
  };

  if (!model) return <div>Loading...</div>;

  return (
    <>
      <Paper sx={{ padding: "20px", margin: "20px" }}>
        <Box display="flex">
          <Box
            minWidth="20%"
            sx={{
              width: 190,
              height: 190,
              border: "2px dashed gray",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="upload-button"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="upload-button">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Profile"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <IconButton component="span">
                  <AddPhotoAlternateIcon style={{ fontSize: 80 }} />
                </IconButton>
              )}
            </label>
          </Box>
          <Box maxWidth="80%">
            <Grid container spacing={2} paddingLeft="44px">
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Instagram Profile"
                  name="profile.instagram"
                  fullWidth
                  value={model.profile.instagram}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Name"
                  name="profile.name"
                  fullWidth
                  value={model.profile.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {/* <Paper
                  component="form"
                  elevation={0}
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    border:'1px solid #cfc9c9',

                  }}
                >
                  <InputBase
                  aria-label="Send Message"
                    sx={{ ml: 1, flex: 1 }}
                    value={model.sendMessage}
                    name="sendMessage"
                    inputProps={{ "aria-label": "sendMessage" }}
                  />

                  <Switch
                    checked={model.sendMessage === "Yes"}
                    onChange={handleSwitchChange}
                  />
                </Paper> */}
                <TextField
                  label="Send Message"
                  name="sendMessage"
                  fullWidth
                  value={model.sendMessage}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.sendMessage}
                        </span>
                        <Switch
                          name="sendMessage"
                          checked={model.sendMessage === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Reply Message"
                  name="replyMessage"
                  fullWidth
                  value={model.replyMessage}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.replyMessage}
                        </span>
                        <Switch
                          name="replyMessage"
                          checked={model.replyMessage === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="First Choice"
                  name="firstChoice"
                  fullWidth
                  value={model.firstChoice}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.firstChoice}
                        </span>
                        <Switch
                          name="firstChoice"
                          checked={model.firstChoice === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Back Up Guest"
                  name="backup"
                  fullWidth
                  value={model.backup}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>{model.backup}</span>
                        <Switch
                          name="backup"
                          checked={model.backup === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Story City"
                  name="city"
                  fullWidth
                  value={model.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Dinner Only"
                  name="dinnerOnly"
                  fullWidth
                  value={model.dinnerOnly}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.dinnerOnly}
                        </span>
                        <Switch
                          name="dinnerOnly"
                          checked={model.dinnerOnly === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Non Model"
                  name="nonModel"
                  fullWidth
                  value={model.nonModel}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>{model.nonModel}</span>
                        <Switch
                          name="nonModel"
                          checked={model.nonModel === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Blacklist"
                  name="blacklist"
                  fullWidth
                  value={model.blacklist}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.blacklist}
                        </span>
                        <Switch
                          name="blacklist"
                          checked={model.blacklist === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Set Dinner Circle"
                  name="selectDinnerCircle"
                  fullWidth
                  value={model.selectDinnerCircle}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.selectDinnerCircle}
                        </span>
                        <Switch
                          name="selectDinnerCircle"
                          checked={model.selectDinnerCircle === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Elite Circle"
                  name="eliteCircle"
                  fullWidth
                  value={model.eliteCircle}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.eliteCircle}
                        </span>
                        <Switch
                          name="eliteCircle"
                          checked={model.eliteCircle === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Select Language"
                  name="language"
                  fullWidth
                  value={model.language}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Write Note"
                  name="writeNote"
                  fullWidth
                  value={model.writeNote}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Tracked Following"
                  name="trackedFollowing"
                  fullWidth
                  value={model.trackedFollowing}
                  sx={{ flex: 1 }}
                  onChange={() => {}} // No need for onChange for the TextField in this case
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {model.trackedFollowing}
                        </span>
                        <Switch
                          name="trackedFollowing"
                          checked={model.trackedFollowing === "Yes"}
                          onChange={handleSwitchChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Time"
                  name="time"
                  type="datetime-local"
                  fullWidth
                  value={model.time}
                  onChange={handleChange}
                />
              </Grid> */}
            </Grid>
            <Grid container spacing={2} marginTop="1%" paddingLeft="44px">
              <Grid item xs={12} sm={12} md={6}>
                <Button onClick={handleSave} variant="outlined" fullWidth>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Button onClick={handleSave} variant="contained" fullWidth>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default EditModelProfile;
