import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../utils/theme";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LightModeIcon from "@mui/icons-material/LightMode";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box
      display="flex"
      justifyContent="end"
      p={1}
      alignItems="center"
      backgroundColor={colors.grey[1000]}
      paddingX="30px"
      sx={{ boxShadow: '0px 10px 30px 0px rgba(82, 63, 105, 0.05)' }}
    >
      {/* Search Bar */}
      <Box
        display="flex"
        backgroundColor={colors.grey[800]}
        borderRadius="10px"
        width="75vh" //'520px'
        height="4vh" //'32px'
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* Theme Selector */}
      <IconButton
        sx={{ display: "flex", marginX: "10px" }}
        onClick={colorMode.toggleColorMode}
      >
        {theme.palette.mode === "dark" ? (
          <LightModeOutlinedIcon />
        ) : (
          <LightModeIcon />
        )}
      </IconButton>

      {/* Profile Name/Details */}
      <Box display="column" textAlign="right" mx="10px">
        <Typography variant="h5" color={colors.grey[100]} fontWeight="400">
          GES Product V1
        </Typography>
        <Typography variant="h6" color={colors.grey[400]} fontWeight="400">
          RM16,UK
        </Typography>
      </Box>

      {/* Profile Image */}
      <Box display="flex" justifyContent="center" alignItems="center" mx="10px">
        <img
          alt="Profile-user"
          src={`../../assets/profile_pic.png`}
          width="48px"
          height="48px"
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>

      <IconButton>
        <KeyboardArrowDownOutlinedIcon />
      </IconButton>
    </Box>
  );
  return <div>Topbar</div>;
};

export default Topbar;
