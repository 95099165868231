import React, { useState, useEffect } from "react";
import data from "./data.json";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import INSTA_PROFILE_LIST from "../../client/instaProfileListClient";
import { InfoOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TalentPoolProfileDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const id = 20;
  const [profileDetails, setProfileDetails] = useState(null);
  const [statusArray, setStatusArray] = useState(null);

  useEffect(() => {
    const fetchProfileByID = async () => {
      let data = await INSTA_PROFILE_LIST.getProfileByID(id);
      if (data.PROFILES.length > 0) {
        console.log("getProfileByID: ", data);
        setProfileDetails(data.PROFILES[0]);
        if (data.PROFILES[0].STATUS) {
          const tempStatusArray = data.PROFILES[0].STATUS
            ? data.PROFILES[0].STATUS.split(",")
            : [];
          setStatusArray(tempStatusArray);
        }
      } else {
        setProfileDetails(null);
        console.log("getProfileByID: null");
      }
    };

    fetchProfileByID();
  }, [id]);
  return (
    <Paper
      sx={{
        margin: "10px 20px 10px 10px",
        overflow: "hidden",
        height: "88%",
        width: "80vw",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "15px",
          borderBlockEnd: "1px solid #E1E3EA",
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton aria-label="edit" onClick={() => navigate("/talentpool")}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography fontWeight="bold">Talent Pool</Typography>
      </div>

      <Box
        sx={{
          display: "flex",
          marginTop: "3vh",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "20%",
          }}
        >
          <Avatar
            sx={{ height: "20vh", width: "20vh" }}
            alt="{row.CLIENT_NAME}"
            src="https://s3-alpha-sig.figma.com/img/5744/9ba8/7714695b75ed83e945a8387f0113a115?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AUalZBVaJVOhxRQ3gXnIacp9TFlQtvPA7HHIYP8xGi9JId8TefQqa5Whz4~9QuMyfnXzF1HvTkqTXKqGH~P-GN1mIXWzu32calo8jy~aP3fp2vWtNl7HHlWgp-2ey6RUI4rkLYp~7qgDZdMXm7VfwoV6V2-Psi5noxYERhbr8NlyNbHdMQrO6aLWQFaAFLEIKM1LooIe7j87oa261DQAl28X9MbZpqG4GrnCRmYDaufqD7DsBoeai~1d7EKHIbk7v3zd6lCd4Kq22ZE7V93A3esQEoYu3VROCTNFwGjPq4t0KUkfhQo26UHbJOZasggHlwaYG2NFnLYToAr2FhcX5A__"
            variant="rounded"
          />
          <Typography fontWeight="bold">
            {profileDetails?.TALENTPOOL_PROFILE_NAME
              ? profileDetails?.TALENTPOOL_PROFILE_NAME
              : "NA"}
          </Typography>
          <Typography color="#0878FF">
            {profileDetails?.TALENTPOOL_PROFILE_FOLLOWERS_COUNT > 1000
              ? `${profileDetails?.TALENTPOOL_PROFILE_FOLLOWERS_COUNT / 1000}k`
              : profileDetails?.TALENTPOOL_PROFILE_FOLLOWERS_COUNT}{" "}
            Followers
          </Typography>
          <Link
            href={profileDetails?.TALENTPOOL_PROFILE_URL}
            underline="hover"
            color="#B5B5C3"
          >
            Instagram Link
          </Link>
        </Box>
        <Box sx={{ minWidth: "80%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBlockEnd: "1px solid #E1E3EA",
              alignItems: "baseline",
              paddingInlineEnd: "2vw",
              paddingInlineStart: "1vw",
            }}
          >
            <Typography fontWeight="bold">Profile</Typography>
            <Box>
              <IconButton
                aria-label="edit"
                // onClick={() => handleEdit(row.id)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                // onClick={() => handleDelete(row.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "6vw",
              borderBlockEnd: "1px solid #E1E3EA",
              alignItems: "center",
              marginTop: "2vh",
              paddingBlockEnd: "1vh",
              paddingInlineStart: "1vw",
            }}
          >
            <Box>
              <Typography color="#B5B5C3">Name</Typography>
              <Typography fontWeight="bold">
                {profileDetails?.TALENTPOOL_PROFILE_NAME
                  ? profileDetails?.TALENTPOOL_PROFILE_NAME
                  : "NA"}
              </Typography>
            </Box>
            <Box>
              <Typography color="#B5B5C3">Instagram</Typography>
              <Typography fontWeight="bold">
                @
                {profileDetails?.TALENTPOOL_PROFILE_USERNAME
                  ? profileDetails?.TALENTPOOL_PROFILE_USERNAME
                  : "NA"}
              </Typography>
            </Box>
            <Box>
              <Typography color="#B5B5C3">Country</Typography>
              <Typography fontWeight="bold">
                {profileDetails?.TALENTPOOL_STORY_COUNTRY
                  ? profileDetails?.TALENTPOOL_STORY_COUNTRY
                  : "NA"}
              </Typography>
            </Box>
            <Box>
              <Typography color="#B5B5C3">Story City</Typography>
              <Typography fontWeight="bold">
                {profileDetails?.TALENTPOOL_STORY_CITY
                  ? profileDetails?.TALENTPOOL_STORY_CITY
                  : "NA"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
              marginTop: "2vh",
              paddingBlockEnd: "1vh",
              paddingInlineStart: "1vw",
            }}
          >
            {statusArray?.length > 0 ? (
              statusArray?.map((name, index) => (
                <Button
                  key={index}
                  color="success"
                  variant="contained"
                  endIcon={
                    <InfoOutlined
                      sx={{ background: "#FFFFFF", borderRadius: "100%" }}
                    />
                  }
                  sx={{
                    border: "1.5px solid black",
                    borderRight: "3.5px solid black",
                    borderBottom: "3.5px solid black",
                    borderRadius: "42px",
                    background: "#C3E329",
                    color: "black",
                  }}
                >
                  {name.trim()}
                </Button>
              ))
            ) : (
              <Typography color="#e77865">No Status available</Typography>
            )}
          </Box>
          <Box sx={{ marginTop: "5vh", paddingInlineEnd: "2vw", gap: "10px" }}>
            <Typography color="#B5B5C3">Note</Typography>
            <TextField
              id="Note"
              multiline
              rows={4}
              fullWidth
              defaultValue={profileDetails?.TALENTPOOL_PROFILE_URL}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default TalentPoolProfileDetails;
