import React, { useState } from "react";
import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import { useAuth } from "../../contexts/Auth";
import AUTH from "../../client/authClient";
import { toast } from "react-toastify";

const Login = () => {
  const { login } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    const params = { username, password };
    const data = await AUTH.login(params);
    // debugger;
    if (data && data.access) {
      login(data?.access);
      document.location.href = "/"; // Redirect to the main app page
    } else {
      setErrorMessage(data?.detail);
      toast.error(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <Grid container style={{ height: "100vh", padding: "0px" }}>
      {/* Left half - Login Form */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>
          <TextField
            fullWidth
            label="Username or username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
          >
            Login
          </Button>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </Box>
      </Grid>

      {/* Right half - Background Image */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: "100%",
            backgroundImage: `url('/assets/login_wallpaper.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Login;
