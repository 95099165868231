import axios from "axios";

// Base setup for axios client
const axiosClient = axios.create({
  baseURL: "https://talentoshr.com/",
  // baseURL: "http://20.5.248.234/api/",
  // timeout: 50_000,
  // headers: {
  //   "Content-Type": "application/x-www-form-urlencoded",
  // },
});

// Request interceptor to add authorization and user ID
axiosClient.interceptors.request.use(
  (config) => {
    try {
      // debugger;
      const user = localStorage.getItem("user");
      if (user) {
        config.headers.Authorization = `Bearer ${user}`;
      }
      return config;
    } catch (error) {
      console.error(error);
    }
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle global errors
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
