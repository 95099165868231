import React from 'react';
import Sidebar from "../../scenes/global/Sidebar";
import Topbar from "../../scenes/global/Topbar";
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return (
    <div className="app" style={{maxWidth:'100%'}}>
      <Sidebar />
      <main className="content">
        <Topbar />
        <Outlet />  {/* This is where child components will be rendered */}
      </main>
    </div>
  );
};

export default MainLayout;
