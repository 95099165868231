import WORK_CALENDAR_LIST from "./workCalendarClient";

export const API_ENDPOINTS = {
  INSTA_PROFILE_FILTER: "api/talent_pool/filter/", // TalentPool Page
  INSTA_PROFILE_LIST: "api/talent_pool/", // TalentPool Page
  INSTA_PROFILE_SINGLE: "api/talent_pool/single/", // TalentPool Page: Get single profile by ID
  INSTA_PROFILE_ADDNEW: "api/talent_pool/add/", // TalentPool Page : Add New Profile
  INSTA_PROFILE_EDIT: "api/talent_pool/edit/", // TalentPool Page : Edit Profile

  INSTA_STORY: "api/story_locations/", // Story Location Page
  INSTA_STORY_FILTER: "api/story_locations/filter/", // Story Location Page

  CLIENT_MANAGEMENT_LIST: "api/clients/",
  CLIENT_MANAGEMENT_SINGLE: "api/clients/getbyid/",
  CLIENT_MANAGEMENT_LIST_SEARCH: "api/clients/search/",
  CLIENT_MANAGEMENT_ADD: "api/clients/add/",
  CLIENT_MANAGEMENT_EDIT: "api/clients/update/",
  CLIENT_MANAGEMENT_CITIES_LIST: "api/clients/cities_filter/",
  CLIENT_MANAGEMENT_NAMES_LIST: "api/clients/names_filter/",
  
  CITY_AGENCIES_LIST: 'api/city_agencies/', // City Agencies: Get list of all agencies for table
  CITY_AGENCIES_AGENCIES_LIST: 'api/city_agencies/agencies/', // City Agencies: Get list of all agencies for filter dropdown
  CITY_AGENCIES_CITIES_LIST: 'api/city_agencies/cities/', // City Agencies: Get list of all cities for filter dropdown
  
  CITY_AGENCIES_MODELS_LIST: 'api/models_city_agencies/', // City Agencies (View Profile): Get list of all models for table
  
  WORK_CALENDAR_LIST: "api/work_calender/all/",
  WORK_CALENDAR_ADD: "api/work_calender/add/",
  WORK_CALENDAR_EDIT: "api/work_calender/edit/",

  LOGIN: "api/token/",
  LOGOUT: "api/logout",
  TOKEN: "api/token",
  CITIES: "cities",
  AGENCIES: "agencies",
};
