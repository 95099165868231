import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Avatar,
  Icon,
} from "@mui/material";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import FilterVintageOutlinedIcon from "@mui/icons-material/FilterVintageOutlined";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  AddOutlined,
  SentimentDissatisfiedOutlined,
  SentimentSatisfiedAltOutlined,
  SmartToyOutlined,
} from "@mui/icons-material";
import tableData from "./tableData.json";

function NewFaces() {
  // States
  const [buttonSelected, setButtonSelected] = useState(1);
  const [tableRows, setTableRows] = useState([]);
  const [tabOneFilters, setTabOneFilters] = useState({
    agencies: "",
    city: "",
    followersFrom: "",
    followersTo: "",
  });

  //   Constants
  const tableColumns = {
    tabOne: [
      "MODELS PROFILES",
      "STATUS",
      "CITY",
      "AGENCY NAME",
      "DATE",
      "URL",
      "CURRENT STATUS",
    ],
    tabTwo: [
      "TOTAL PROFILES",
      "STATUS",
      "CITY",
      "TRACKER PAGE",
      "AGENCY",
      "PROFESSION",
      "DATE",
      "CURRENT STATUS",
    ],
    tabThree: [
      "TRACKERS PAGES",
      "CITY",
      "TRACKER PROFESSION",
      "DATE",
      "SHOW FOLLOWING",
      "CURRENT STATUS",
    ],
    tabFour: ["AGENCIES LIST", "CITY", "DATE", "URL", "CURRENT STATUS"],
  };

  const statusOptions = [
    { label: "Open Chat", value: "Open Chat" },
    { label: "Non Model", value: "Non Model" },
    { label: "Elite Circle", value: "Elite Circle" },
    { label: "Set Dinner Circle", value: "Set Dinner Circle" },
    { label: "Travel Planner", value: "Travel Planner" },
    { label: "Dinner Only", value: "Dinner Only" },
    { label: "Tracked Following", value: "Tracked Following" },
    { label: "In Town", value: "In Town" },
    { label: "First Choice", value: "First Choice" },
    { label: "Backup", value: "Backup" },
    { label: "Blacklist", value: "Blacklist" },
    // Add more filters as needed
  ];

  useEffect(() => {
    if (buttonSelected === 1) {
      setTableRows(tableData.tableOne);
    } else if (buttonSelected === 2) {
      setTableRows(tableData.tableTwo);
    } else if (buttonSelected === 3) {
      setTableRows(tableData.tableThree);
    } else if (buttonSelected === 4) {
      setTableRows(tableData.tableFour);
    } else {
      setTableRows([]);
    }
  }, [buttonSelected]);

  const handleButtonChange = (newValue) => () => {
    setButtonSelected(newValue);
    setTableRows([]);
  };

  const renderFilters = () => {
    switch (buttonSelected) {
      case 1:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              width: "40%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">Agencies</Typography>
              <FormControl>
                <Select
                  labelId="agency-select-label"
                  id="agency-select"
                  value="Peril Queen"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--Agency--</em>
                  </MenuItem>
                  <MenuItem key="1" value="Peril Queen">
                    Peril Queen
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                  <MenuItem key={index} value={country}>
                    {country}
                  </MenuItem>
                ))} */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">City</Typography>
              <FormControl>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  value="Paris"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--City--</em>
                  </MenuItem>
                  <MenuItem key="1" value="Paris">
                    Paris
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                  <MenuItem key={index} value={country}>
                    {country}
                  </MenuItem>
                ))} */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">Followers</Typography>
              <TextField
                id="outlined-basic"
                label="From"
                variant="outlined"
                sx={{ background: "#F2F6FA" }}
              />
              <Typography color="#7E8299">:</Typography>
              <TextField
                id="outlined-basic"
                label="To"
                variant="outlined"
                sx={{ background: "#F2F6FA" }}
              />
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              width: "40%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">Tracker Name</Typography>
              <FormControl>
                <Select
                  labelId="tracker-name-select-label"
                  id="tracker-name-select"
                  value="Piuufouquetr"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--Tracker-name--</em>
                  </MenuItem>
                  <MenuItem key="1" value="Piuufouquetr">
                    Piuufouquetr
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">Agencies</Typography>
              <FormControl>
                <Select
                  labelId="agency-select-label"
                  id="agency-select"
                  value="@metrolpolitan"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--Agency--</em>
                  </MenuItem>
                  <MenuItem key="1" value="@metrolpolitan">
                    @metrolpolitan
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">City</Typography>
              <FormControl>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  value="Paris"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--City--</em>
                  </MenuItem>
                  <MenuItem key="1" value="Paris">
                    Paris
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              width: "40%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">Profession</Typography>
              <FormControl>
                <Select
                  labelId="profession-select-label"
                  id="profession-select"
                  value="Photographer"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--Profession--</em>
                  </MenuItem>
                  <MenuItem key="1" value="Photographer">
                    Photographer
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">City</Typography>
              <FormControl>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  value="Paris"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--City--</em>
                  </MenuItem>
                  <MenuItem key="1" value="Paris">
                    Paris
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">Followers</Typography>
              <TextField
                id="outlined-basic"
                label="From"
                variant="outlined"
                sx={{ background: "#F2F6FA" }}
              />
              <Typography color="#7E8299">:</Typography>
              <TextField
                id="outlined-basic"
                label="To"
                variant="outlined"
                sx={{ background: "#F2F6FA" }}
              />
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "0px",
              width: "40%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography color="#7E8299">City</Typography>
              <FormControl>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  value="Paris"
                  displayEmpty
                  sx={{
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>--City--</em>
                  </MenuItem>
                  <MenuItem key="1" value="Paris">
                    Paris
                  </MenuItem>
                  {/* {uniqueCountries.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    ))} */}
                </Select>
              </FormControl>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const renderTables = () => {
    switch (buttonSelected) {
      case 1:
        return (
          <div>
            <TableContainer>
              <Table sx={{ overflowX: "scroll" }}>
                <TableHead>
                  <TableRow>
                    {tableColumns.tabOne.map((column) => (
                      <TableCell>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar alt={row.modelName} src={row.imageURL} />
                          <Typography paddingLeft={2}>
                            {row.modelName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={row.status}
                            // onChange={handleChange}
                            label="Status" // This label is necessary if you're using a FormControl with InputLabel
                          >
                            {statusOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.agencyName}</TableCell>
                      <TableCell>
                        {new Date(row.date).toLocaleString()}
                      </TableCell>
                      <TableCell>{row.URL}</TableCell>
                      <TableCell>
                        {row.currentStatus ? (
                          row.currentStatus === "Approved" ? (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#0CA45D"
                            >
                              <SmartToyOutlined /> AI Approved
                            </Typography>
                          ) : (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#FF1F23"
                            >
                              <SmartToyOutlined /> AI Rejected
                            </Typography>
                          )
                        ) : (
                          <Box display="flex" gap="10px" justifyContent="end">
                            <Button
                              variant="contained"
                              startIcon={<SentimentSatisfiedAltOutlined />}
                              sx={{
                                color: "#FFFFFF",
                                backgroundColor: "#4C6FFF",
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<SentimentDissatisfiedOutlined />}
                              sx={{
                                border: "1px solid #4C6FFF",
                                color: "#4C6FFF",
                              }}
                            >
                              Reject
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case 2:
        return (
          <div>
            <TableContainer>
              <Table sx={{ overflowX: "scroll" }}>
                <TableHead>
                  <TableRow>
                    {tableColumns.tabTwo.map((column) => (
                      <TableCell>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar alt={row.modelName} src={row.imageURL} />
                          <Typography paddingLeft={2}>
                            {row.modelName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={row.status}
                            // onChange={handleChange}
                            label="Status" // This label is necessary if you're using a FormControl with InputLabel
                          >
                            {statusOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          id="city-input"
                          placeholder="Enter City Name"
                          value={row.city ? row.city : ""}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>{row.trackerPage}</TableCell>
                      <TableCell>{row.agencyName}</TableCell>
                      <TableCell>{row.profession}</TableCell>
                      <TableCell>
                        {new Date(row.date).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {row.currentStatus ? (
                          row.currentStatus === "Approved" ? (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#0CA45D"
                            >
                              <SmartToyOutlined /> AI Approved
                            </Typography>
                          ) : (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#FF1F23"
                            >
                              <SmartToyOutlined /> AI Rejected
                            </Typography>
                          )
                        ) : (
                          <Box display="flex" gap="10px" justifyContent="end">
                            <Button
                              variant="contained"
                              startIcon={<SentimentSatisfiedAltOutlined />}
                              sx={{
                                color: "#FFFFFF",
                                backgroundColor: "#4C6FFF",
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<SentimentDissatisfiedOutlined />}
                              sx={{
                                border: "1px solid #4C6FFF",
                                color: "#4C6FFF",
                              }}
                            >
                              Reject
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case 3:
        return (
          <div>
            <TableContainer>
              <Table sx={{ overflowX: "scroll" }}>
                <TableHead>
                  <TableRow>
                    {tableColumns.tabThree.map((column) => (
                      <TableCell>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar alt={row.modelName} src={row.imageURL} />
                          <Typography paddingLeft={2}>
                            {row.modelName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.trackerProfession}</TableCell>
                      <TableCell>
                        {new Date(row.date).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          sx={{
                            border: "1px solid #4C6FFF",
                            color: "#4C6FFF",
                          }}
                        >
                          View Following
                        </Button>
                      </TableCell>
                      <TableCell>
                        {row.currentStatus ? (
                          row.currentStatus === "Approved" ? (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#0CA45D"
                            >
                              <SmartToyOutlined /> AI Approved
                            </Typography>
                          ) : (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#FF1F23"
                            >
                              <SmartToyOutlined /> AI Rejected
                            </Typography>
                          )
                        ) : (
                          <Box display="flex" gap="10px" justifyContent="end">
                            <Button
                              variant="contained"
                              startIcon={<SentimentSatisfiedAltOutlined />}
                              sx={{
                                color: "#FFFFFF",
                                backgroundColor: "#4C6FFF",
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<SentimentDissatisfiedOutlined />}
                              sx={{
                                border: "1px solid #4C6FFF",
                                color: "#4C6FFF",
                              }}
                            >
                              Reject
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case 4:
        return (
          <div>
            <TableContainer>
              <Table sx={{ overflowX: "scroll" }}>
                <TableHead>
                  <TableRow>
                    {tableColumns.tabFour.map((column) => (
                      <TableCell>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar alt={row.modelName} src={row.imageURL} />
                          <Typography paddingLeft={2}>
                            {row.modelName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <TextField
                          id="city-input"
                          placeholder="Enter City Name"
                          value={row.city ? row.city : ""}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        {new Date(row.date).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <TextField
                          id="url-input"
                          placeholder="Enter URL"
                          value={row.URL ? row.URL : "NONE"}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        {row.currentStatus ? (
                          row.currentStatus === "Approved" ? (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#0CA45D"
                            >
                              <SmartToyOutlined /> AI Approved
                            </Typography>
                          ) : (
                            <Typography
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              gap="5px"
                              color="#FF1F23"
                            >
                              <SmartToyOutlined /> AI Rejected
                            </Typography>
                          )
                        ) : (
                          <Box display="flex" gap="10px" justifyContent="end">
                            <Button
                              variant="contained"
                              startIcon={<SentimentSatisfiedAltOutlined />}
                              sx={{
                                color: "#FFFFFF",
                                backgroundColor: "#4C6FFF",
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<SentimentDissatisfiedOutlined />}
                              sx={{
                                border: "1px solid #4C6FFF",
                                color: "#4C6FFF",
                              }}
                            >
                              Reject
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <Paper sx={{ margin: "10px 20px 10px 10px", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          padding: "30px 15px 7px 15px",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px",
            gap: "10px",
            // width: "53%",
          }}
        >
          <Button
            variant="outlined"
            startIcon={
              <AcUnitOutlinedIcon
                sx={{
                  color: "#FFC700",
                  background: "#FFF8DD",
                  borderRadius: "4px",
                  boxShadow: "0 0 5px 2px #FFF8DD",
                }}
              />
            }
            sx={{
              background: "#F2F6FA",
              border: "0px",
              borderBottom: buttonSelected === 1 ? "3px solid #4C6FFF" : "",
            }}
            onClick={handleButtonChange(1)}
          >
            Add Via Agencies
          </Button>
          <Button
            variant="outlined"
            startIcon={
              <FilterVintageOutlinedIcon
                sx={{
                  color: "#03A9F4",
                  background: "#ECF8FF",
                  borderRadius: "4px",
                  boxShadow: "0 0 5px 2px #ECF8FF",
                }}
              />
            }
            sx={{
              background: "#F2F6FA",
              border: "0px",
              borderBottom: buttonSelected === 2 ? "3px solid #4C6FFF" : "",
            }}
            onClick={handleButtonChange(2)}
          >
            Added Via Tracker
          </Button>
          <Button
            variant="outlined"
            startIcon={
              <ChangeHistoryOutlinedIcon
                sx={{
                  color: "#7239EA",
                  background: "#F8F5FF",
                  borderRadius: "4px",
                  boxShadow: "0 0 5px 2px #F8F5FF",
                }}
              />
            }
            sx={{
              background: "#F2F6FA",
              border: "0px",
              borderBottom: buttonSelected === 3 ? "3px solid #4C6FFF" : "",
            }}
            onClick={handleButtonChange(3)}
          >
            List Of Trackers
          </Button>
          <Button
            variant="outlined"
            startIcon={
              <InsertDriveFileOutlinedIcon
                sx={{
                  color: "#F1416C",
                  background: "rgb(250, 208, 218)",
                  borderRadius: "4px",
                  //   border:'5px solid rgb(250, 208, 218)'
                  boxShadow: "0 0 5px 2px rgb(250, 208, 218)",
                }}
              />
            }
            sx={{
              background: "#F2F6FA",
              border: "0px",
              borderBottom: buttonSelected === 4 ? "3px solid #4C6FFF" : "",
            }}
            onClick={handleButtonChange(4)}
          >
            List Of Agencies
          </Button>
        </Box>
        {renderFilters()}
        <Button
          variant="outlined"
          startIcon={<AddOutlined />}
          sx={{
            background: "#F2F6FA",
            border: "1px solid #4C6FFF",
            // borderBottom: buttonSelected === 1 ? "3px solid #4C6FFF" : "",
            color: "#4C6FFF",
          }}
          // onClick={handleButtonChange(1)}
        >
          New Member
        </Button>
      </Box>

      {renderTables()}
    </Paper>
  );
}

export default NewFaces;
