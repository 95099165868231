import React, { useState } from "react";
import { Box, Link, Typography, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { tokens } from "../../utils/theme";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

const styles = {
  root: {
    width: "100%"
  },
  listItem: {
    minHeight: "72px",
    borderBottom: "dashed 2px",
    borderColor: '#F4F4F4',
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: 0,
  },
  avatar: {
    width: "56px", // Adjust the size of the Avatar
    height: "56px", // Adjust the size of the Avatar
    borderRadius: "2px", // Rounded corners
    marginRight: "16px", // Space between avatar and text
  },
};

const Statbox_QueenLastWeek = () => {
  const [totalMsgSent, setTotalMsgSent] = useState("6.3k");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const members = [
    {
      id: 1,
      name: "Anna Simmons",
      link: "/path/to/image.jpg",
      image: "https://s3-alpha-sig.figma.com/img/5744/9ba8/7714695b75ed83e945a8387f0113a115?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UHtITzCbYlGK9ADdsrFhEz6QX6V1PYjWkTl1G0RUAzBxvfd89xCK4zi~Er9XJn6-vwE-4fYvKsytNiUVp3qL7LdT1qlXGnhXEyEqB8fE~zP3haNpA238mgnSpHFLpGIPn4Iq-h97UmFSh8YvYwyqCQhTgLHVeXHALW2P75j5wJOdpKvdskN56nSywdfhB2AiUeqzx3f~7LmPqDS8ZEM~a0KEUoReKXnDigOImdrK7uaoN00SFlX1O4cZ~UdNlYRksWVr7R8bun-sTkt9KF3eqngiGOJ4xvj2-3i0M3oHN4iz7NCzulJ8Mg7HqGdZzMMDBSC2H7QB7Xsp4npH3yS6NA__",
      totalSpend: 10.5,
    },
    {
      id: 2,
      name: "Jessie Clarcson",
      link: "/path/to/image.jpg",
      image: "https://s3-alpha-sig.figma.com/img/4ea7/3211/a7d460a9b82ad1b2cd5d89177e3c52f9?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=S3XyhjvrPgKjC30UG8QgcTdgN6md427wgYCueFOqbIm0JRGW9FqaQsgwcfXkieCOO2VflviRjXF8ZQ-pi3V3CqMJk0bM2HR7MYWtOMesxPLkm~aYZ0DN~v9b6XuLqYzY40RytdGoqW6IfbC~1VmnmxxgnIR7bOk1wPzkp96v1OgwnmCZaVfx3pcnHtQd7BKyYtQdsL60ZBmT3w9WVRIC7gZjABQ9r625AQQ3ksPV-W4c6vgaE7GmB1zHvathQJuXAttuwDHCriFmsBFUeu1s6daskL2P1MuTBCPvfw1iS~aCugFbBRQfYmjZVT3Gp4X1IZkrnAoQrGM2JSGGYgVjLw__",
      totalSpend: 8.5,
    },
    {
      id: 3,
      name: "Lebira Johan",
      link: "/path/to/image.jpg",
      image: "https://s3-alpha-sig.figma.com/img/1349/3425/2098473bc5a858f3cbef095f0b74559a?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OupO57rBICsASQwy1wUkQRYV8snzjFyYhK5zxFZbjWlIT3flrj05dkldiLUm1l8sgl1SbbiPI7nqJnW7Ry3YidSAUxV5ZEDouUz~H12spc-Pe1bXxqprbb1sWYPcRVy4BYM93OMZMwi908R04LKqZyodZcPcLo1Oez~A-7DwcLmnj-YgQdnZYlrtOG0wzHQr~Bz7Ipx3cbWOfrN6nFeIYmr32ecckCmpUiH-qjCnuc1lZFFRN-8qEMqIkQTED0LTbHCMJg9BrfzOx~0PPclU6TgrYvdJH-BK5-aNoE63IxFznYXdc8ryzHTULoIi~Pmeo8M6wQpQUrYZm0JJWAvHZA__",
      totalSpend: 5.5,
    },
    {
      id: 4,
      name: "Natali Goodwin",
      link: "/path/to/image.jpg",
      image: "https://s3-alpha-sig.figma.com/img/1b5a/3eb0/e898ac9104c1194b6be408e5c0092256?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MtEwiu7wLNtuOCok~jxLozn4ZO94bGAyijys9FjXVvYSDBXU3VlfVO-itQMr2~8~bU9i5j3aIW0BMZnB6QW9zdT65N-0DAnvMHGnwqpDnxBMPwKoYSa7tvwLn~VTfbRidOMrzdKesI81gk5pEbLIIWrRzaciRwYM96VNcbOEZXoVXFYrZCmV~igxA3g1n-srgG-fdZmlYEQ4A8yXo~rJIxrCyPlxpfdy9h59fj~jKgiD5FzsCL9CMu30vhtCAdkrVY~nuAAist5kdUHS~IYbf9~BXPV1vzA9oJJDnjKJFxt57X0lDcIfxlos2owU~MjYFGq4JvHiK47Gn56OZLCcvw__",
      totalSpend: 4.5,
    },
    {
      id: 5,
      name: "Katarina Watson",
      link: "/path/to/image.jpg",
      image: "https://s3-alpha-sig.figma.com/img/973e/0765/d37cc656110b7aa8c24fd94d333a60f5?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eBtEbYhpGIZzQoGOzykVOMjspYICsw4NrhRu79FdZQa2wj3MJKigm3T2gFLuYyCzE69pUT~V~UNKQwKhe-pNkupLJLxs-2yX94WyVV4x1eACScTEAXjS4izOE-ojxmY1CtLArR1B-EoTaCOeTvgSZ3FA5yNdjCuvrzrXrHSVxCQa-aen2oTOqNJIrtew1tcjFWKxR4uCxMKEOInEmDug5P6Ia~m1sm8T2Fnv3wLmj1m2nlfgvcTGKujwpQOI0GiVNkANK~9OrhIcNbPVFLyO3-4KkRsPj2xM27fkNC2CkR4e31YUHPIiG1XPrAo-IbTh0qG~jpFiZRH0LX4Pk3mPFA__",
      totalSpend: 3.5,
    },
    // Add more member objects as needed
  ];
  return (
    <Box minHeight="500px">
      <Box className="card-header" padding="22.75px 29.25px 6.5px 12px">
        <Box display="flex"></Box>
        <Typography variant="h5" color={colors.grey[100]}>
          Queen of Last Week
        </Typography>

        <Typography variant="h7" color={colors.grey[500]}>
          top 5 members
        </Typography>
      </Box>
      <Box
        className="card-content"
        display="block"
        minHeight="407px"
        alignItems="start"
        margin="10px 0 0 0"
      >
        <Box
          display="flex"
          alignItems="center"
          borderBottom="solid 1px"
          borderColor={colors.grey[700]}
        >
          <Checkbox
            disabled={true}
            sx={{ color: colors.grey[700] }}
            inputProps={{ "aria-label": "checkbox" }}
            icon={<SquareRoundedIcon />}
            checkedIcon={<SquareRoundedIcon />}
          />
          <Typography fontWeight='bold' color={colors.grey[500]}>Members</Typography>
        </Box>
        <List style={styles.root}>
          {members.map((member) => (
            <ListItem key={member.id} style={styles.listItem}>
              <Checkbox
                disabled={true}
                sx={{ color: colors.grey[700] }}
                inputProps={{ "aria-label": "checkbox" }}
                icon={<SquareRoundedIcon />}
                checkedIcon={<SquareRoundedIcon />}
              />
              <ListItemAvatar>
                <Avatar
                  alt={member.name}
                  src={member.image}
                  style={styles.avatar}
                />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography fontWeight='bold'>{member.name}</Typography>}
                secondary={`Instagram link`}
              />
            </ListItem>
          ))}
        </List>
        {/* <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Brunch this weekend?"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>
                  
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Summer BBQ"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    to Scott, Alex, Jennifer
                  </Typography>
                  
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Oui Oui"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Sandra Adams
                  </Typography>
                  
                </React.Fragment>
              }
            />
          </ListItem>
        </List> */}
      </Box>
    </Box>
  );
};

export default Statbox_QueenLastWeek;
