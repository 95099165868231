import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Link,
  IconButton,
  Paper,
  Autocomplete,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import CITY_AGENCIES_LIST from "../../client/cityAgenciesClient";
import CITIES_LIST from "../../client/citiesClient";
import AGENCIES_LIST from "../../client/agenciesClient";

const ModelProfiles = () => {
  const { id } = useParams();
  const statusList = [
    "OPEN_CHAT",
    "NON_MODEL",
    "ELITE_CIRCLE",
    "DINNER_CIRCLE",
    "TRAVEL_PLANNER",
    "DINNER_ONLY",
    // "TRACKED_FOLLOWINGS",
    // "In Town",
    "FIRST_CHOICE",
    "BACKUP_GUEST",
    // "Blacklist",
  ];
  const statusColorMap = {
    "First Choice": { color: "#e2726e", background: "#f8eaee" },
    Backup: { color: "#535ac8", background: " #ece6f0" },
    "Open Chat": { color: "#FFA500", background: "#fffde7" },
    "Non Model": { color: "#228B22", background: "#dbf5ee" },
    "Elite Circle": { color: "#868dfb", background: "#e1e2fe" },
    "Dinner Circle": { color: "#6A5ACD", background: "#e1e2fe" },
    "Travel Planner": { color: "#8A2BE2", background: "#f3e5f5" },
    "Dinner Only": { color: " #e2726e", background: "#f8eaee" },
    "Tracked Following": { color: "#4cceac", background: "#dbf5ee" },
  };
  const [rows, setRows] = useState([]);
  const [rowsNotForFiltering, setRowsNotForFiltering] = useState([]);
  const [totalModels, setTotalModels] = useState(0);

  const [citiesList, setCitiesList] = useState([]);
  const [agenciesList, setAgenciesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert to Date object
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with '0' if necessary
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (January is 0), pad with '0'
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date string
  };

  const handleCityChange = (event, newValue) => {
    setSelectedCity(newValue);
    filterRows(searchTerm, newValue, selectedAgency, selectedStatus);
  };

  const handleAgencyChange = (event, newValue) => {
    setSelectedAgency(newValue);
    filterRows(searchTerm, selectedCity, newValue, selectedStatus);
  };

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    filterRows(searchTerm, selectedCity, selectedAgency, newValue);
  };

  const clearFilters = () => {
    setSelectedCity(null);
    setSelectedAgency(null);
    setSelectedStatus(null);
    setSearchTerm("");
  };

  const filterRows = (search, city, agency, status) => {
    debugger;
    let filteredRows = rowsNotForFiltering;

    // Filter based on city
    if (city) {
      filteredRows = filteredRows.filter((row) =>
        row.TALENTPOOL_STORY_CITY
          ? row.TALENTPOOL_STORY_CITY.toLowerCase().includes(
              city.trim().toLowerCase()
            )
          : false
      );
    }

    // Filter based on agency
    if (agency) {
      filteredRows = filteredRows.filter((row) =>
        row.TALENTPOOL_AGENCY_NAME
          ? row.TALENTPOOL_AGENCY_NAME.toLowerCase().includes(
              agency.trim().toLowerCase()
            )
          : false
      );
    }
    // Filter based on Status
    if (status) {
      filteredRows = filteredRows.filter((row) =>
        row.STATUS
          ? row.STATUS.toLowerCase().includes(status.trim().toLowerCase())
          : false
      );
    }

    // Filter based on search term
    if (search) {
      filteredRows = filteredRows.filter((row) => {
        const rowData = Object.values(row).reduce((acc, value) => {
          if (value === null || value === undefined) {
            return acc;
          }

          if (typeof value === "object") {
            acc += Object.values(value).join(" ").toLowerCase();
          } else {
            acc += String(value).toLowerCase();
          }
          return acc;
        }, "");

        return rowData.includes(search.toLowerCase());
      });
    }

    // Update the state with the filtered rows
    setTotalModels(filteredRows?.length);
    setRows(filteredRows);
  };

  useEffect(() => {
    const fetchCitiesList = async () => {
      const data = await CITIES_LIST.getCitiesList();
      // debugger;
      if (data) {
        // console.log(data);
        setCitiesList(data.cities);
      }
    };

    const fetchAgenciesList = async () => {
      const data = await AGENCIES_LIST.getAgenciesListForCityAgenciesPage();
      // debugger;
      if (data) {
        // console.log(data);
        const filteredAgencies = data.agencies.filter(
          (agency) => agency !== null
        );
        setAgenciesList(filteredAgencies);
      }
    };

    const fetchProfileList = async () => {
      // debugger;
      let data;
      setIsDataLoading(true);
      data = await CITY_AGENCIES_LIST.getCityAgenciesModelsListByID(id);

      if (data) {
        console.log(data);
        setRowsNotForFiltering(data.AGENCIES);
        setRows(data.AGENCIES);
        setTotalModels(data?.AGENCIES?.length);
        setIsDataLoading(false);
      } else {
        setIsDataLoading(false);
      }
    };

    clearFilters();
    fetchCitiesList();
    fetchAgenciesList();
    fetchProfileList();
  }, []);

  return (
    <Paper
      sx={{
        margin: "10px 20px 10px 10px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <div style={{ display: "block", padding: "0px" }}>
          <Typography fontWeight="bold" fontSize="15px">
            Models Profile
          </Typography>
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* City Dropdown */}
            <Autocomplete
              value={selectedCity}
              onChange={handleCityChange}
              disablePortal
              id="city-combo-box"
              options={citiesList}
              sx={{
                border: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => <TextField {...params} label="City" />}
            />

            {/* Agencies Dropdown */}
            <Autocomplete
              value={selectedAgency}
              onChange={handleAgencyChange}
              disablePortal
              id="agency-combo-box"
              options={agenciesList}
              sx={{
                border: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => <TextField {...params} label="Agency" />}
            />

            {/* Status Dropdown */}
            <Autocomplete
              value={selectedStatus}
              onChange={handleStatusChange}
              disablePortal
              id="agency-combo-box"
              options={statusList}
              sx={{
                border: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              alignItems: "center",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              placeholder="Search"
              size="small"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  filterRows(
                    searchTerm,
                    selectedCity,
                    selectedAgency,
                    selectedStatus
                  );
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      <div
        style={{
          overflowY: "hidden", // Ensure the outer div doesn't scroll
        }}
      >
        <TableContainer style={{ maxHeight: "77vh" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  TOTAL PROFILES({totalModels})
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  STATUS
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  CITY
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  MODEL AGENCY
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  TIME
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  ------
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataLoading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : rows && rows?.length > 0 ? (
                rows?.map((row, index) => (
                  <TableRow
                    key={row.ID}
                    sx={{
                      background: index % 2 ? "#F2F6FA" : "#FFF",
                    }}
                  >
                    <TableCell
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          alt={row.TALENTPOOL_PROFILE_NAME}
                          src={row.TALENTPOOL_PROFILE_URL}
                          sx={{ cursor: "pointer" }}
                        />
                        <Box>
                          <Typography variant="h5" paddingLeft={2}>
                            {row.TALENTPOOL_PROFILE_NAME}
                          </Typography>
                          <Typography paddingLeft={2} display="flex">
                            <Typography color="#0878FF">
                              {row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT > 1000
                                ? `${
                                    row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT /
                                    1000
                                  }k`
                                : row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT}
                            </Typography>
                            <Link
                              href={row.TALENTPOOL_PROFILE_URL}
                              underline="hover"
                              color="#B5B5C3"
                              paddingLeft={1}
                            >
                              Instagram Link
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          padding: "10px",
                          width: "fit-content",
                          fontWeight: "bolder",
                          color: statusColorMap[row.STATUS]?.color || "#26AFA4",
                          backgroundColor:
                            statusColorMap[row.STATUS]?.background || "#F5FFF9",
                          borderRadius: "4px",
                        }}
                      >
                        {row.STATUS}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {row.TALENTPOOL_STORY_CITY
                        ? row.TALENTPOOL_STORY_CITY
                        : "NA"}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bolder" }}>
                      {row.TALENTPOOL_AGENCY_NAME
                        ? row.TALENTPOOL_AGENCY_NAME
                        : "NA"}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bolder" }}>
                      {row.TALENTPOOL_STORY_LAST_UPDATED
                        ? formatDate(row.TALENTPOOL_STORY_LAST_UPDATED)
                        : "NA"}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                      }}
                    >
                      {row.TALENTPOOL_PROFILE_URL ? (
                        <Link
                          href={row.TALENTPOOL_PROFILE_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "blue", textDecoration: "none" }}
                        >
                          {row.TALENTPOOL_PROFILE_URL}
                        </Link>
                      ) : (
                        "NA"
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};

export default ModelProfiles;
