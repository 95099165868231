import React, { useState } from "react";
import TableComponent from "../LocationTrackerTable";
import WorldMap from "../../components/WorldMap";
import OpenStreetMap from "../../components/OpenStreetMap";

const data = [
  { country: "USA", code: "USA", coordinates: [-100, 40], members: 1000 },
  {
    country: "Canada",
    code: "CAN",
    coordinates: [-106.3468, 56.1304],
    members: 500,
  },
  // Add more country data as needed
];

const LocationTracker = () => {
  const [markersData, setMarkersData] = useState([]);
  // console.log('LatlongArray : ', markersData)
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      {/* <WorldMap data={data} /> */}
      <OpenStreetMap markersData = {markersData}/>
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          marginTop: "30%",
        }}
      >
        <TableComponent setMarkersData={setMarkersData}/>
      </div>
    </div>
  );
};

export default LocationTracker;
