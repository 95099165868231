import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../utils/theme.js";
import PieChartWithCenterLabel from "../PieChart/PieChart.js";
import { scaleWidth } from "../../utils/units.ts";

const data = [
  { value: 27.44, label: "Need to contact models", color: "#5AE1E2" },
  { value: 72.56, label: "Contacted models", color: "#0878FF" },
];

function LegendItem({ color, label, value }) {
  return (
    <Box display="flex" flexDirection="row" gap="10px" mr="20px" mt="15px">
      <Box pt="2px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 9 9"
          fill="none"
        >
          <circle cx="4.5" cy="4.5" r="4" fill={color} />
        </svg>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6">{label}</Typography>
        <Typography variant="h3">{value + "%"}</Typography>
      </Box>
    </Box>
  );
}

function CustomLegend({ data }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        marginBottom: "10px",
      }}
    >
      {data.map((entry, index) => (
        <LegendItem
          key={`item-${index}`}
          color={entry.color}
          label={entry.label}
          value={entry.value}
        />
      ))}
    </div>
  );
}

const Statbox_totalAgencies = () => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  return (
    <Box>
        <Grid container /* maxWidth={scaleWidth(740)} */>
          <Grid xs={12} sm={12} md={8}>
            <Box p="0px 0px  1px  0px">
              <Typography variant="h5">Total Agencies</Typography>
              <Typography variant="h1">9,630</Typography>
            </Box>
            <Box p="0px  10px  0px  5px">
              <Typography variant="h6">
                Total models in all the agencies
              </Typography>
              <Typography variant="h3">36,000</Typography>
            </Box>
            <Box p="0px 10px 0px 5px">
              <CustomLegend data={data} />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <PieChartWithCenterLabel />
          </Grid>
        </Grid>
    </Box>
  );
};

export default Statbox_totalAgencies;
