import axiosClient from "./axiosClient";
// import axios from "axios";
import { API_ENDPOINTS } from "./apiEndpoints";

const CITIES = {
  async getCitiesList() {
    try {
      const token = localStorage.getItem("user");
      const response = await axiosClient.get(`/${API_ENDPOINTS.CITIES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      return error?.response?.data;
      // return { data: null, error: error.response };
    }
  },
  
  async getCitiesListForCityAgenciesPage() {
    try {
      const token = localStorage.getItem("user");
      const response = await axiosClient.get(`/${API_ENDPOINTS.CITY_AGENCIES_CITIES_LIST}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      return error?.response?.data;
      // return { data: null, error: error.response };
    }
  },
  
  async getCitiesListForClientManagementPage() {
    try {
      const token = localStorage.getItem("user");
      const response = await axiosClient.get(`/${API_ENDPOINTS.CLIENT_MANAGEMENT_CITIES_LIST}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      return error?.response?.data;
      // return { data: null, error: error.response };
    }
  },
};

export default CITIES;
