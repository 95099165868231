import * as React from "react";
import { chartsGridClasses } from "@mui/x-charts/ChartsGrid";
import { useTheme } from "@mui/material";
import { tokens } from "../../utils/theme";
import {
  lineElementClasses,
  markElementClasses,
  LineChart,
} from "@mui/x-charts/LineChart";

export default function LineChartComponent() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <LineChart
      xAxis={[{ data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
      series={[
        {
          data: [3, 2, 5.5, 2, 8.5, 1.5, 5, 15, 4, 5, 19],
        },
      ]}
      height={101}
      margin={{ left: 0, right: 0, top: 24, bottom: 0 }}
      grid={{ vertical: false, horizontal: true }}
      leftAxis={null}
      bottomAxis={null}
      sx={{
        [`& .${lineElementClasses.root}`]: {
          stroke: colors.grey[100],
          strokeWidth: 2,
        },
        [`& .${markElementClasses.root}`]: {
          stroke: "#8884d8",
          scale: "0",
          fill: "black",
          strokeWidth: 2,
        },
        [`& .${chartsGridClasses.line}`]: {
          strokeDasharray: "5 3",
          strokeWidth: 1,
        },
      }}
    />
  );
}
