import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { scaleHeight, scaleWidth } from "../../utils/units.ts";
// import ApexCharts from 'apexcharts'

const data = [
  { value: 27.44, label: "Need to contact models", color: "#5AE1E2" },
  { value: 72.56, label: "Contacted models", color: "#0878FF" },
];

const size = {
  width: 180, //'220px', // scaleWidth(220), //220,
  height: 200, //'200px'// scaleHeight(200) //200,
};

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 10,
}));

function PieCenterLabel({ data }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      <tspan x={left + width / 2} dy="-1.2em" fontWeight='bold' fontSize="20px">
        {data[1].value + "%"}
      </tspan>
      <tspan x={left + width / 2} dy="2em">
        {data[1].label}
      </tspan>
    </StyledText>
  );
}

export default function PieChartWithCenterLabel() {
  return (
    <div style={{ display: "block" }}>
      <PieChart
        series={[
          {
            data,
            innerRadius: 50,
            startAngle: -90,
            endAngle: 90,
            // innerRadius: 70,
          },
        ]}
        {...size}
        // sx={{width:'220px', height:'200px'}}
        margin={{ left: 0, right: 30, top: 30, bottom: -30 }}
        slotProps={{
          legend: {
            hidden: true,
            direction: "row",
            position: {
              vertical: "top",
              horizontal: "left",
            },
          },
        }}
      >
        <PieCenterLabel data={data} />
      </PieChart>
    </div>
  );
}
