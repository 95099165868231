import axiosClient from "./axiosClient";
import { API_ENDPOINTS } from "./apiEndpoints";

const AUTH = {
    async login(params) {
      try {
        // debugger;
        const response = await axiosClient.post(`/${API_ENDPOINTS.LOGIN}`, params, {
          headers:{
            "Content-Type": "application/x-www-form-urlencoded",
          }
        });
        return response.data;
        // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
      } catch (error) {
        return error?.response?.data;
        // return { data: null, error: error.response };
      }
    },
    // async loginWithJwt(params) {
    //   try {
    //     const response = await axiosClient.post(`/${API_ENDPOINTS.LOGIN}/jwt`, params);
    //     return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    //   } catch (error) {
    //     return { data: null, error: error.response };
    //   }
    // },
  
    // async register(params) {
    //   try {
    //     const response = await axiosClient.post(`/${API_ENDPOINTS.REGISTER}`, params);
    //     return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    //   } catch (error) {
    //     return { data: null, error: error.response };
    //   }
    // }
  };

  export default AUTH;