import React, { useState } from "react";
import { Box, Typography, useTheme, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { tokens } from "../../utils/theme";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ProgressBar from "@ramonak/react-progress-bar";

const Statbox_InitialMsgImpact = () => {
  const [totalMsgSent, setTotalMsgSent] = useState("6.3k");
  const [percentReplies, setPercentReplies] = useState(60);
  const [percentNoReplies, setPercentNoReplies] = useState(40);

  const TotalMsgSentStateFormatter = (totalMsgSent) => {
    totalMsgSent > 1000
      ? setTotalMsgSent(totalMsgSent / 1000 + "k")
      : setTotalMsgSent(totalMsgSent);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Box className="card-header" padding="22.75px 29.25px 6.5px 12px">
        <Box display="flex">
          <Typography variant="h5" color={colors.grey[100]}>
            Initial Message Impact
          </Typography>
          <div>
            <InfoIcon sx={{ marginLeft: "10px", color: "#4C6FFF" }} />
          </div>
        </Box>

        <Typography variant="h6" color={colors.grey[500]}>
          Social activities overview
        </Typography>
      </Box>
      <Box className="card-content" display='grid' minHeight='292px' height='296px' alignItems='end'>
        <Box sx={{ flexGrow: 1, margin: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              <Typography variant="h1">{totalMsgSent}</Typography>
              <Typography variant="h7" color={colors.grey[500]}>
                Total message sent
              </Typography>
            </Grid>
            <Grid item xs={7} md={7} alignSelf="center">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography variant="h7" color="textSecondary">
                  2024
                </Typography>
                <IconButton>
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <ProgressBar
                  completed="6.3"
                  maxCompleted="7"
                  customLabel=" "
                  height="6px"
                  bgColor="#7239EA"
                />
                {/* <BorderLinearProgress color="secondary" variant="determinate" value={80} /> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
        
        <Box sx={{ flexGrow: 1, margin: "10px", borderTop:'dashed 1px #E1E3EA', paddingTop:'15px'  }}>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              <Typography variant="h1">{percentReplies}%</Typography>
              <Typography variant="h7" color={colors.grey[500]}>
                Replies
              </Typography>
            </Grid>
            <Grid item xs={7} md={7} alignSelf="center">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography variant="h7" color="textSecondary">
                  2024
                </Typography>
                <IconButton>
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <ProgressBar
                  completed={percentReplies}
                  customLabel=" "
                  height="6px"
                  bgColor="#F1416C"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, margin: "10px", borderTop:'dashed 1px #E1E3EA', paddingTop:'15px'  }}>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              <Typography variant="h1">{percentNoReplies}%</Typography>
              <Typography variant="h7" color={colors.grey[500]}>
                No Replies
              </Typography>
            </Grid>
            <Grid item xs={7} md={7} alignSelf="center">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography variant="h7" color="textSecondary">
                  2024
                </Typography>
                <IconButton>
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <ProgressBar
                  completed={percentNoReplies}
                  customLabel=" "
                  height="6px"
                  bgColor="#00D9D9"
                />
                {/* <BorderLinearProgress color="secondary" variant="determinate" value={80} /> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Statbox_InitialMsgImpact;
