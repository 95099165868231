import axiosClient from "./axiosClient";
import { API_ENDPOINTS } from "./apiEndpoints";

const WORK_CALENDAR_LIST = {
  async getWorkCalendarList() {
    try {
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(
        `/${API_ENDPOINTS.WORK_CALENDAR_LIST}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profiles:", error);
      return [];
    }
  },

  async getWorkCalendarListByDate(dateSelected) {
    try {
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(
        `/${API_ENDPOINTS.WORK_CALENDAR_LIST}`,
        {
          params: { DATE_SELECTED: dateSelected },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profiles:", error);
      return [];
    }
  },

  async getWorkCalendarListByID(ID) {
    try {
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(
        `/${API_ENDPOINTS.WORK_CALENDAR_LIST}`,
        {
          params: { EVENT_ID: ID },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // debugger;
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profile:", error);
      return [];
    }
  },

  async addNewEvent(formData) {
    try {
      debugger;
      const token = localStorage.getItem("user");
      const response = await axiosClient.post(
        API_ENDPOINTS.WORK_CALENDAR_ADD,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.status === 200 ? response.data : null;
    } catch (error) {
      console.error("Error adding client:", error);
      return null;
    }
  },

  async updateEventByID(formData) {
    try {
      // debugger;
      const token = localStorage.getItem("user");
      const response = await axiosClient.post(
        API_ENDPOINTS.WORK_CALENDAR_EDIT,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.status === 200 ? response.data : null;
    } catch (error) {
      console.error("Error adding client:", error);
      return null;
    }
  },
};

export default WORK_CALENDAR_LIST;
