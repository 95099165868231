import axiosClient from "./axiosClient";
import { API_ENDPOINTS } from "./apiEndpoints";

const CLIENT_MANAGEMENT_LIST = {
  async getClientsList(pageNumber, searchTermForRequest) {
    try {
      const token = localStorage.getItem("user");
      // debugger;
      const response = await axiosClient.get(
        `${API_ENDPOINTS.CLIENT_MANAGEMENT_LIST_SEARCH}`,
        {
          params: { page: pageNumber, query: searchTermForRequest },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profiles:", error);
      return [];
    }
  },

  async getClientsNamesList() {
    try {
      const token = localStorage.getItem("user");
      const response = await axiosClient.get(`/${API_ENDPOINTS.CLIENT_MANAGEMENT_NAMES_LIST}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      return error?.response?.data;
      // return { data: null, error: error.response };
    }
  },
  
  async getClientProfileByID(ID) {
    try {
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(
        `/${API_ENDPOINTS.CLIENT_MANAGEMENT_SINGLE}`,
        {
          params: { id: ID },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // debugger;
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profile:", error);
      return [];
    }
  },

  async addClient(data) {
    try {
      const token = localStorage.getItem("user");
      const response = await axiosClient.post(
        API_ENDPOINTS.CLIENT_MANAGEMENT_ADD,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response.status === 200 ? response.data : null;
    } catch (error) {
      console.error("Error adding client:", error);
      return null;
    }
  },

  async updateClientProfileByID(data) {
    try {
      // debugger;
      const token = localStorage.getItem("user");
      const response = await axiosClient.post(
        API_ENDPOINTS.CLIENT_MANAGEMENT_EDIT,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response.status === 200 ? response.data : null;
    } catch (error) {
      console.error("Error adding client:", error);
      return null;
    }
  },
};

export default CLIENT_MANAGEMENT_LIST;
