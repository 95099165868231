import React from "react";
import "./WorkCalendar.css";
import data from "../TalentPool/data.json";
import { Button } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin7Line } from "react-icons/ri";
import { MdOutlineLocationOn } from "react-icons/md";
import { GridSelectedRowCount } from "@mui/x-data-grid";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { Box } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import qs from "qs";
import WORK_CALENDAR_LIST from "../../client/workCalendarClient";
import CLIENT_MANAGEMENT_LIST from "../../client/clientsManagementClient";
const WorkCalendarOverview = () => {
  //console.log(data);
  const colors = ["#33b2ff", "#ffce33", "#8833b9", "#dd6d8d"];

  const clients = [
    {
      image: "/assets/brooklyn.PNG",
      name: "Herman",
      type: "Casual Spender",
      money: "5000",
    },
    {
      image: "/assets/simon.PNG",
      name: "Brooklyn Simmons",
      type: "Top Spender",
      money: "25000",
    },
    {
      image: "/assets/brooklyn.PNG",
      name: "Ifra Ejaz",
      type: "casual spender",
      money: "5000",
    },
  ];
  const [eventToUpdateID, setEventToUpdateID] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsNotForFiltering, setRowsNotForFiltering] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [totalEventsScheduled, setTotalEventsScheduled] = useState(true);
  const scrollContainerRef = useRef(null);
  const [selectedButton, setSelectedButton] = useState("");
  const [dates, setDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [editbtn, seteditbtn] = useState("");
  const [deletebtn, setdeletebtn] = useState("");
  const [evtpopup, setevtpopup] = useState(false);
  const [editEvtPopup, setEditEvtPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [guests, setGuests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedConfirmedProfiles, setSelectedConfirmedProfiles] = useState(
    []
  );
  const [selectedMaybeProfiles, setSelectedMaybeProfiles] = useState([]);
  const [selectedDeclinedProfiles, setSelectedDeclinedProfiles] = useState([]);
  const [selectedNoResponseProfiles, setSelectedNoResponseProfiles] = useState(
    []
  );
  const [
    totalProfilesForPercentInShowDetails,
    setTotalProfilesForPercentInShowDetails,
  ] = useState(100);
  const [
    attendedClientsListForShowDetails,
    setAttendedClientsListForShowDetails,
  ] = useState([]);
  const [bgColorForShowDetailsPopup, setBgColorForShowDetailsPopup] =
    useState("");
  const [clientsList, setClientsList] = useState([]);
  const [organizersList, setOrganizersList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [eventDateForPostData, setEventDateForPostData] = useState("");
  const [datemsg, setdatemsg] = useState("");
  const [clubName, setClubName] = useState("");
  const [city, setCity] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [client, setclient] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState({
    left: 0,
    top: 0,
  });
  const inputRef = useRef(null);
  const [confirmedGuests, setConfirmedGuests] = useState([]);
  const [maybeGuests, setMaybeGuests] = useState([]);
  const [showGuestImages, setShowGuestImages] = useState(false);
  const [justForReload, setJustForReload] = useState(true);

  const getDatesOfMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    //console.log(year);
    const month = now.getMonth();
    //console.log(month);

    const dates = [];
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
      dates.push({
        day: day.toDateString().split(" ")[0],
        date: day.getDate(),
        month: month + 1,
      });
    }
    console.log(dates);
    return dates;
  };

  const handleButtonClick = (value) => {
    console.log(value);
    setSelectedButton(value);
  };

  const handledelete = (value) => {
    console.log(value);
    setdeletebtn(value);
  };
  /*const handledonebtn = () => {
    setShowGuestImages(true);
    setShowDropdown(false);
  };*/
  const handleDateChange = (date) => {
    const formattedDateToDDMMYYYY = formatDateToDDMMYYYY(date);
    // console.log("Date : ", formattedDateToDDMMYYYY);
    setEventDate(date);
    setEventDateForPostData(formattedDateToDDMMYYYY);

    if (date) {
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      setdatemsg(formattedDate);
    } else {
      setdatemsg("");
    }
  };
  const formatDateToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with 0 if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and pad with 0 if needed (Months are 0-indexed)
    const year = date.getFullYear(); // Get full year
    return `${day}-${month}-${year}`; // Return the formatted date
  };

  const addevent = () => {
    setevtpopup(true);
    //console.log(addbtn);
  };
  function handleInputBlur(e) {
    const input = e.target;
    const label = input.nextElementSibling;
    if (input.value) {
      label.classList.add("stay-up");
    } else {
      label.classList.remove("stay-up");
    }
  }
  const handlerowclick = (ID, index) => {
    const bgColor = colors[index % colors.length];
    const fetchEventByID = async () => {
      let data = await WORK_CALENDAR_LIST.getWorkCalendarListByID(ID);
      debugger;
      if (data?.DATA.length > 0) {
        const eventDetails = data.DATA[0];
        // setEventToUpdateID(ID);
        // setEventDateForPostData(eventDetails.EVENT_DATE);
        setdatemsg(eventDetails.EVENT_DATE);

        // setClubName(eventDetails.EVENT_CLUB_NAME);
        setCity(eventDetails.EVENT_CITY);
        setOrganizer(eventDetails.ORGANIZED_BY);

        const { CLIENT_NAME, ORGANIZED_BY } = eventDetails;
        // Filter profiles based on matching CLIENT_NAME and ORGANIZED_BY
        const matchedProfiles = clientsList.filter(
          (profile) =>
            profile.CLIENT_NAME === CLIENT_NAME ||
            profile.CLIENT_NAME === ORGANIZED_BY
        );

        // Update the attended clients list with the matched profiles
        setAttendedClientsListForShowDetails(matchedProfiles);
        setSelectedConfirmedProfiles(eventDetails.CONFIRMED_PROFILES);
        setSelectedMaybeProfiles(eventDetails.MAYBE_PROFILES);
        setSelectedDeclinedProfiles(eventDetails.DECLINED_PROFILES);
        setSelectedNoResponseProfiles(eventDetails.NO_RESPONSE);
        const totalProfiles =
          eventDetails.CONFIRMED_PROFILES?.length +
          eventDetails.MAYBE_PROFILES?.length +
          eventDetails.DECLINED_PROFILES?.length +
          eventDetails.NO_RESPONSE?.length;
        setTotalProfilesForPercentInShowDetails(totalProfiles);
        setBgColorForShowDetailsPopup(bgColor);
        setOpenPopup(true);
      } else {
      }
    };

    fetchEventByID();
  };
  const handleClosePopup = () => {
    setJustForReload(!justForReload);
    setOpenPopup(false);
    setdatemsg("");
    setCity("");
    setOrganizer("");
    setAttendedClientsListForShowDetails([]);
    setSelectedConfirmedProfiles([]);
    setSelectedMaybeProfiles([]);
    setSelectedDeclinedProfiles([]);
    setSelectedNoResponseProfiles([]);
    setTotalProfilesForPercentInShowDetails(0);
    setBgColorForShowDetailsPopup("");
  };

  const handleedit = (ID) => {
    // console.log(ID);

    const fetchEventByID = async () => {
      let data = await WORK_CALENDAR_LIST.getWorkCalendarListByID(ID);
      if (data?.DATA.length > 0) {
        const eventDetails = data.DATA[0];
        setEventToUpdateID(ID);
        setEventDateForPostData(eventDetails.EVENT_DATE);
        setdatemsg(eventDetails.EVENT_DATE);

        const regex = /^\d{2}-\d{2}-\d{4}$/;
        if (regex.test(eventDetails.EVENT_DATE)) {
          const [day, month, year] =
            eventDetails.EVENT_DATE.split("-").map(Number);
          // Create a new Date object using year, month (0-indexed), and day
          const dateObject = new Date(year, month - 1, day); // Months are 0-indexed in JavaScript (Jan = 0)
          setEventDate(dateObject);
        }

        setClubName(eventDetails.EVENT_CLUB_NAME);
        setCity(eventDetails.EVENT_CITY);

        const matchedClient = clientsList.find(
          (client) => client.CLIENT_NAME === eventDetails.CLIENT_NAME
        );
        matchedClient ? setclient(matchedClient.ID) : setclient(null);

        const matchedOrganizer = clientsList.find(
          (client) => client.CLIENT_NAME === eventDetails.ORGANIZED_BY
        );
        matchedOrganizer
          ? setOrganizer(matchedOrganizer.ID)
          : setOrganizer(null);

        const confirmedUsers = eventDetails.CONFIRMED_PROFILES.map(
          (profile) => profile.FK_GUEST_USERNAME
        );
        setSelectedConfirmedProfiles(confirmedUsers);

        const maybeUsers = eventDetails.MAYBE_PROFILES.map(
          (profile) => profile.FK_GUEST_USERNAME
        );
        setSelectedMaybeProfiles(maybeUsers);

        setEditEvtPopup(true);
      } else {
      }
    };

    fetchEventByID();
    // seteditbtn(value);
  };
  const handleUpdateEvent = async () => {
    try {
      const formData = new FormData();

      formData.append("CLIENT_ID", client || "0");
      formData.append("CLUB_NAME", clubName || "");
      formData.append("CITY", city || "");
      formData.append("EVENT_DATE", eventDateForPostData || "");
      formData.append("ORGANIZER_ID", organizer || "0");
      formData.append(
        "CONFIRMED_PROFILES",
        JSON.stringify(selectedConfirmedProfiles) || "[]"
      );
      formData.append(
        "MAYBE_PROFILES",
        JSON.stringify(selectedMaybeProfiles) || "[]"
      );
      formData.append("EVENT_ID", eventToUpdateID || "0");

      console.log("Form Data : ", formData);
      // const data = qs.stringify({
      //   CLIENT_ID: client || "", // Keep empty if not set
      //   CLUB_NAME: clubName || "", // Keep empty if not set
      //   CITY: city || "Top Spender", // Default to "Top Spender" if not set,
      //   EVENT_DATE: eventDate || "", // Keep empty if not set,
      //   ORGANIZER_ID: organizer || "0", // Default to 0 if not set,
      //   CONFIRMED_PROFILES: selectedConfirmedProfiles || "0", // Default to 0 if not set,
      //   MAYBE_PROFILES: selectedMaybeProfiles || "", // Keep empty if not set
      // });

      // console.log("Post Request Data : ", data);
      const response = await WORK_CALENDAR_LIST.updateEventByID(formData);

      if (response) {
        console.log("Event updated successfully:", response);
        CloseevtPopup();
      } else {
        console.error("Failed to update event.");
      }
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const handleAddNewEvent = async () => {
    try {
      const formData = new FormData();

      formData.append("CLIENT_ID", client || "0");
      formData.append("CLUB_NAME", clubName || "");
      formData.append("CITY", city || "");
      formData.append("EVENT_DATE", eventDateForPostData || "");
      formData.append("ORGANIZER_ID", organizer || "0");
      formData.append(
        "CONFIRMED_PROFILES",
        JSON.stringify(selectedConfirmedProfiles) || "[]"
      );
      formData.append(
        "MAYBE_PROFILES",
        JSON.stringify(selectedMaybeProfiles) || "[]"
      );

      console.log("Form Data : ", formData);
      // const data = qs.stringify({
      //   CLIENT_ID: client || "", // Keep empty if not set
      //   CLUB_NAME: clubName || "", // Keep empty if not set
      //   CITY: city || "Top Spender", // Default to "Top Spender" if not set,
      //   EVENT_DATE: eventDate || "", // Keep empty if not set,
      //   ORGANIZER_ID: organizer || "0", // Default to 0 if not set,
      //   CONFIRMED_PROFILES: selectedConfirmedProfiles || "0", // Default to 0 if not set,
      //   MAYBE_PROFILES: selectedMaybeProfiles || "", // Keep empty if not set
      // });

      // console.log("Post Request Data : ", data);
      const response = await WORK_CALENDAR_LIST.addNewEvent(formData);

      if (response) {
        console.log("Event added successfully:", response);
        CloseevtPopup();
      } else {
        console.error("Failed to add event.");
      }
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };
  const CloseevtPopup = () => {
    //console.log("Saving data...");
    // console.log("Client", client);
    // console.log("Club Name:", clubName);
    // console.log("City:", city);
    // console.log("Event Date:", eventDate);
    // console.log("Organizer:", organizer);
    // console.log("Confirmed Guests :", selectedConfirmedProfiles);
    // console.log("Maybe Guests :", selectedMaybeProfiles);
    setJustForReload(!justForReload);
    setEventToUpdateID(null);
    setdatemsg("");
    setEventDate("");
    setEventDateForPostData("");
    setClubName("");
    setCity("");
    setOrganizer("");
    setclient("");
    setevtpopup(false);
    setEditEvtPopup(false);
    setSearchTerm("");
    setSelectedGuest(null);
    setShowDropdown(false);
    setShowGuestImages(false);
    setConfirmedGuests([]);
    setMaybeGuests([]);
    setSelectedConfirmedProfiles([]);
    setSelectedMaybeProfiles([]);
  };
  const toggleDateSelection = (selectedDate) => {
    const dateString = `${selectedDate.day} ${selectedDate.date} ${selectedDate.month}`;
    setSelectedDates((prevDates) => {
      let newDates;
      if (prevDates.includes(dateString)) {
        newDates = prevDates.filter((date) => date !== dateString);
      } else {
        newDates = [...prevDates, dateString];
      }
      console.log("Updated Selected Dates:", newDates);
      return newDates;
    });
  };
  const isDateSelected = (date) => {
    const dateString = `${date.day} ${date.date} ${date.month}`;
    return selectedDates.includes(dateString);
  };
  const handleSearch = (event) => {
    // debugger;
    const { value } = event.target;
    setSearchTerm(value);
    //console.log(searchTerm);
    if (value.trim().length === 0) {
      setShowDropdown(false);
      setSelectedGuest(null);
      return;
    }
    const foundGuest = guests.find(
      (guest) => guest.profile.username === value.trim()
    );

    setSelectedGuest(value.trim());

    // if (foundGuest) {
    // setSelectedGuest(foundGuest);
    setShowDropdown(true);
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({ left: rect.right, top: rect.top });
    }
    //console.log(selectedGuest);
    //console.log("matched");
    // } else {
    //   setSelectedGuest(null);
    //   setShowDropdown(false);
    //   console.log("not matched");
    // }
  };

  const handleCheckboxChange = (type, checked) => {
    if (type === "confirmed") {
      setSelectedConfirmedProfiles((prevSelectedGuests) => {
        if (checked) {
          // Add the guest's name to confirmed profiles if checked
          if (!prevSelectedGuests.includes(selectedGuest)) {
            return [...prevSelectedGuests, selectedGuest];
          }
        } else {
          // Remove the guest's name if unchecked
          return prevSelectedGuests.filter((guest) => guest !== selectedGuest);
        }
        return prevSelectedGuests;
      });
    } else if (type === "maybe") {
      setSelectedMaybeProfiles((prevSelectedGuests) => {
        if (checked) {
          // Add the guest's name to maybe profiles if checked
          if (!prevSelectedGuests.includes(selectedGuest)) {
            return [...prevSelectedGuests, selectedGuest];
          }
        } else {
          // Remove the guest's name if unchecked
          return prevSelectedGuests.filter((guest) => guest !== selectedGuest);
        }
        return prevSelectedGuests;
      });
    }
    // setSelectedGuest((prev) => {
    //   const updatedGuest = { ...prev, [type]: !prev[type] };

    //   if (type === "confirmed") {
    //     updateConfirmedGuests(updatedGuest);
    //   } else if (type === "maybe") {
    //     updateMaybeGuests(updatedGuest);
    //   }
    //   return updatedGuest;
    // });
  };

  const updateConfirmedGuests = (guest) => {
    setConfirmedGuests((prev) => {
      const exists = prev.some(
        (g) => g.localtag_user_id === guest.localtag_user_id
      );
      let newConfirmedGuests;
      if (guest.confirmed && !exists) {
        newConfirmedGuests = [...prev, guest];
        //console.log("Adding to confirmed guests", newConfirmedGuests);
      } else if (!guest.confirmed && exists) {
        newConfirmedGuests = prev.filter(
          (g) => g.localtag_user_id !== guest.localtag_user_id
        );
        //console.log("Removing from confirmed guests", newConfirmedGuests);
      } else {
        newConfirmedGuests = prev;
      }

      return newConfirmedGuests;
    });
  };

  const updateMaybeGuests = (guest) => {
    setMaybeGuests((prev) => {
      const exists = prev.some(
        (g) => g.localtag_user_id === guest.localtag_user_id
      );
      let newmaybeGuests;
      if (guest.maybe && !exists) {
        newmaybeGuests = [...prev, guest];
      } else if (!guest.maybe && exists) {
        newmaybeGuests = prev.filter(
          (g) => g.localtag_user_id !== guest.localtag_user_id
        );
      } else {
        newmaybeGuests = prev;
      }

      //console.log(newmaybeGuests);
      return newmaybeGuests;
    });
  };
  useEffect(() => {
    setGuests(data.PROFILES);
    setDates(getDatesOfMonth());
  }, []);

  useEffect(() => {
    if (selectedDates.length > 0) {
      document.activeElement.blur();
    }
    const fetchClientsAndOrganizersList = async () => {
      const data = await CLIENT_MANAGEMENT_LIST.getClientsList(1, "");
      // debugger;
      if (data) {
        // console.log("Names list for filter: ", data.names);
        // const filteredNames = data.names.filter((name) => name !== null);
        setClientsList(data.PROFILES);
        setOrganizersList(data.PROFILES);
      }
    };

    const fetchEventsScheduled = async () => {
      let data;
      setIsDataLoading(true);
      data = await WORK_CALENDAR_LIST.getWorkCalendarList();
      if (data) {
        setRowsNotForFiltering(data.DATA);
        setRows(data.DATA);
        setTotalEventsScheduled(data?.DATA?.length);
        setIsDataLoading(false);
      } else {
        setIsDataLoading(false);
      }
    };

    fetchEventsScheduled();
    fetchClientsAndOrganizersList();
  }, [selectedDates, justForReload]);

  return (
    <div className="work-calendar-overview" style={{ position: "relative" }}>
      <div
        className="calendar-header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <p
          className="prompt-bold"
          style={{
            margin: "6px",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          Work Calendar Overview
        </p>

        <div
          className="options"
          style={{ display: "flex", gap: "15px", fontWeight: "500" }}
        >
          <button
            className="gray-button"
            onClick={() => handleButtonClick("Promoter")}
          >
            Promoter
          </button>

          <select
            style={{ border: "none", fontWeight: "bold" }}
            onChange={(e) => handleButtonClick(e.target.value)}
          >
            <option>Photographer</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
          </select>

          <button
            className="gray-button"
            onClick={() => handleButtonClick("Upcoming Event")}
          >
            Upcoming Event
          </button>
          <button
            className="light-gray-button"
            onClick={() => handleButtonClick("Day")}
          >
            Day
          </button>
          <button
            className="light-gray-button"
            onClick={() => handleButtonClick("Week")}
          >
            Week
          </button>
          <button
            className="light-gray-button"
            onClick={() => handleButtonClick("Month")}
          >
            Month
          </button>

          <input
            type="text"
            placeholder="Search"
            style={{
              borderRadius: "4px",
              border: "1px solid #ddd",
              padding: "6px",
            }}
            onChange={(e) => handleButtonClick(e.target.value)}
          />

          <Button
            onClick={addevent}
            style={{ border: "1px solid #4C6FFF" }}
            variant="outlined"
            startIcon={<AddOutlined />}
            sx={{
              background: "#F2F6FA",
              color: "#4C6FFF",
            }}
          >
            New Event
          </Button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          ref={scrollContainerRef}
          className="hide-scrollbar"
          style={{
            overflowX: "auto",
            display: "flex",
            width: "970px",
            scrollBehavior: "smooth",
          }}
        >
          {dates.map(({ day, date, month }, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "60px",
                alignItems: "center",
              }}
            >
              <button
                className={`calendar-button ${
                  isDateSelected({ day, date, month }) ? "selected" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  toggleDateSelection({ day, date, month });
                  e.target.blur();
                }}
                style={{
                  backgroundColor: isDateSelected({ day, date, month })
                    ? "#4C6FFF"
                    : "transparent",
                  color: isDateSelected({ day, date, month })
                    ? "white"
                    : "#c9c1c1",
                }}
              >
                <span>{day}</span>
                <span className="date">{date}</span>
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="event-list">
        <div className="table-header">
          <div
            className="header-item"
            style={{ marginLeft: "7px", width: "25%" }}
          >
            TOTAL EVENTS SCHEDULED
          </div>
          <div
            className="header-item"
            style={{ width: "12.5%", textAlign: "center" }}
          >
            CONFIRMED
          </div>
          <div
            className="header-item"
            style={{ width: "12.5%", textAlign: "center" }}
          >
            NO RESPONSE
          </div>
          <div
            className="header-item"
            style={{ width: "12.5%", textAlign: "center" }}
          >
            DECLINED
          </div>
          <div
            className="header-item"
            style={{ width: "12.5%", textAlign: "center" }}
          >
            MAYBE
          </div>
          <div
            className="header-item"
            style={{ width: "12.5%", textAlign: "center" }}
          >
            CLIENT
          </div>
          <div
            style={{ width: "12.5%", textAlign: "center" }}
            className="header-item"
          >
            -----------
          </div>
        </div>
        <div className="table-body">
          {isDataLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "6vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Loading...
            </div>
          ) : rows && rows.length > 0 ? (
            rows?.map((event, index) => (
              <div className="event-item" key={index}>
                <div
                  onClick={() => handlerowclick(event.ID, index)}
                  key={event.ID}
                  style={{ display: "flex", width: "25%" }}
                >
                  <span
                    style={{
                      marginLeft: "5px",
                      width: "3px",
                      height: "3.5rem",
                      backgroundColor: colors[index % colors.length],
                    }}
                  ></span>
                  <div style={{ marginLeft: "7px" }} className="event-details">
                    <div style={{ fontWeight: "bolder", fontSize: "12px" }}>
                      {event.EVENT_DATE}
                    </div>
                    <div
                      style={{
                        color: "#979595",
                        fontWeight: "bolder",
                        fontSize: "10px",
                      }}
                    >
                      <MdOutlineLocationOn />
                      {event.EVENT_CITY}
                    </div>
                    <div style={{ color: "#c9c1c1", fontWeight: "bolder" }}>
                      Organized by{" "}
                      <span
                        style={{
                          color: colors[index % colors.length],
                          fontWeight: "bolder",
                        }}
                      >
                        {event.ORGANIZED_BY}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    fontWeight: "bolder",
                  }}
                  className="event-status"
                >
                  <span className="span-grn">
                    {event.CONFIRMED_PROFILES?.length}-profiles
                  </span>
                  <span className="span-blue">
                    {event.MAYBE_PROFILES?.length}-profiles
                  </span>
                  <span className="span-pink">
                    {event.DECLINED_PROFILES?.length}-profiles
                  </span>
                  <span className="span-purple">
                    {event.MAYBE_PROFILES?.length}-profiles
                  </span>
                </div>
                <div
                  className="event-client"
                  style={{
                    fontWeight: "bolder",
                    textAlign: "center",
                    width: "12.5%",
                    wordWrap: "break-word",
                  }}
                >
                  {event.clients && event.clients.length > 0
                    ? event.clients.join(", ")
                    : "No clients"}
                </div>
                <div
                  style={{
                    width: "12.5%",
                    flexDirection: "row",
                    textAlign: "center",
                  }}
                  className="event-actions"
                >
                  <button
                    onClick={() => handleedit(event.ID)}
                    className="edit-button"
                  >
                    <MdModeEdit />
                  </button>
                  <button
                    onClick={() => handledelete("delete")}
                    className="delete-button"
                  >
                    <RiDeleteBin7Line />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "6vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No data
            </div>
          )}
        </div>
        <Popup
          open={openPopup}
          className="hide-pscrollbar"
          closeOnDocumentClick
          onClose={handleClosePopup}
          position="right center"
          contentStyle={{
            width: "30%",
            maxHeight: "90%",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
            overflowY: "auto",
            overflowX: "hidden",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
        >
          <div>
            {openPopup && (
              <>
                <span
                  style={{
                    width: "4px",
                    height: "4.5rem",
                    backgroundColor: bgColorForShowDetailsPopup,
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    zIndex: 1000,
                  }}
                ></span>
                <div
                  className="event-details"
                  style={{
                    width: "100%",
                    marginLeft: "14px",
                    padding: "4px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bolder",
                      fontSize: "18px",
                      marginBottom: "4px",
                    }}
                  >
                    {datemsg}
                  </div>
                  <div
                    style={{
                      color: "#979595",
                      fontWeight: "bolder",
                      fontSize: "12px",
                      marginBottom: "4px",
                    }}
                  >
                    <MdOutlineLocationOn />
                    {city}
                  </div>
                  <div
                    style={{
                      color: "#c9c1c1",
                      fontWeight: "bolder",
                      fontSize: "12px",
                    }}
                  >
                    Organized by{" "}
                    <span
                      style={{
                        color: bgColorForShowDetailsPopup,
                        fontWeight: "bolder",
                      }}
                    >
                      {organizer}
                    </span>
                  </div>
                </div>
                <p style={{ color: "#e6dcdc" }}>
                  ---------------------------------------------------------------------------
                </p>
                <p
                  style={{
                    marginLeft: "14px",
                    fontWeight: "bolder",
                    fontSize: "14px",
                    marginBottom: "0px",
                  }}
                >
                  Attended Clients
                </p>
                <div>
                  {attendedClientsListForShowDetails?.map((client, index) => (
                    <div key={index} className="attended-clients">
                      <img
                        src="https://byuc.wordpress.com/wp-content/uploads/2012/07/avat-2.jpg"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                        alt="Client Avatar"
                      />
                      <div className="client-info">
                        <p className="client-name">{client.CLIENT_NAME}</p>
                        <p className="client-type">
                          {client.CLIENT_SPENDER_CATEGORY}
                        </p>
                      </div>
                      <div className="client-money">
                        <FontAwesomeIcon
                          icon={faSackDollar}
                          style={{ color: "#31b993" }}
                        />
                        0
                      </div>
                    </div>
                  ))}
                </div>
                <p
                  style={{
                    marginLeft: "14px",
                    fontWeight: "bolder",
                    fontSize: "14px",
                    marginBottom: "4px",
                    marginTop: "0px",
                  }}
                >
                  Confirmed Models
                </p>
                <Box style={{ marginLeft: "14px" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {selectedConfirmedProfiles
                        ?.slice(0, 5)
                        .map((client, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              marginBottom: "4px",
                              zIndex: 5 - index,
                              marginLeft: index > 0 ? "-10px" : "0",
                            }}
                          >
                            <img
                              src="https://byuc.wordpress.com/wp-content/uploads/2012/07/avat-2.jpg"
                              alt={client.name}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "2px solid white",
                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                          </div>
                        ))}
                      {selectedConfirmedProfiles?.length > 5 && (
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: "#e0e0e0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "-10px",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#555",
                          }}
                        >
                          +{selectedConfirmedProfiles?.length - 5}
                        </div>
                      )}
                    </div>
                    <p
                      style={{
                        position: "absolute",
                        right: "16px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#c2b9b9",
                      }}
                    >
                      {(selectedConfirmedProfiles?.length /
                        totalProfilesForPercentInShowDetails) *
                        100}
                      %
                    </p>
                  </div>

                  <ProgressBar
                    completed={selectedConfirmedProfiles?.length}
                    maxCompleted={totalProfilesForPercentInShowDetails}
                    customLabel=" "
                    height="6px"
                    bgColor="#6870fa"
                  />
                </Box>
                <p
                  style={{
                    marginLeft: "14px",
                    fontWeight: "bolder",
                    fontSize: "14px",
                    marginBottom: "4px",
                    marginTop: "10px",
                  }}
                >
                  Insights
                </p>
                <p
                  style={{
                    color: "#a1a4ab",
                    marginLeft: "14px",
                    marginTop: "0px",
                    fontSize: "12px",
                  }}
                >
                  total messages sent
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginLeft: "14px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        margin: "0",
                        color: "#333",
                      }}
                    >
                      Confirmed
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      <ProgressBar
                        completed={selectedConfirmedProfiles?.length}
                        maxCompleted={totalProfilesForPercentInShowDetails}
                        customLabel=" "
                        height="6px"
                        width="120px"
                        bgColor="#6870fa"
                        marginRight="4px"
                      />
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "12px",
                          color: "#a1a4ab",
                          marginRight: "22px",
                        }}
                      >
                        {(selectedConfirmedProfiles?.length /
                          totalProfilesForPercentInShowDetails) *
                          100}
                        %
                      </span>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "#a1a4ab",
                      fontSize: "12px",
                      margin: "0",
                    }}
                  >
                    {(selectedConfirmedProfiles?.length /
                      totalProfilesForPercentInShowDetails) *
                      100}
                    % total confirmed attendees
                  </p>
                  <p style={{ color: "#e6dcdc" }}>
                    ---------------------------------------------------------------------------
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginLeft: "14px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        margin: "0",
                        color: "#333",
                      }}
                    >
                      Declined
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      <ProgressBar
                        completed={selectedDeclinedProfiles?.length}
                        maxCompleted={totalProfilesForPercentInShowDetails}
                        customLabel=" "
                        height="6px"
                        width="120px"
                        bgColor="#e2726e"
                        marginRight="4px"
                      />
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "12px",
                          color: "#a1a4ab",
                          marginRight: "22px",
                        }}
                      >
                        {(selectedDeclinedProfiles?.length /
                          totalProfilesForPercentInShowDetails) *
                          100}
                        %
                      </span>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "#a1a4ab",
                      fontSize: "12px",
                      margin: "0",
                    }}
                  >
                    {(selectedDeclinedProfiles?.length /
                      totalProfilesForPercentInShowDetails) *
                      100}
                    % total declined invitations
                  </p>
                  <p style={{ color: "#e6dcdc" }}>
                    ---------------------------------------------------------------------------
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginLeft: "14px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        margin: "0",
                        color: "#333",
                      }}
                    >
                      No response
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      <ProgressBar
                        completed={selectedNoResponseProfiles?.length}
                        maxCompleted={totalProfilesForPercentInShowDetails}
                        customLabel=" "
                        height="6px"
                        width="120px"
                        bgColor="#868dfb"
                        marginRight="4px"
                      />
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "12px",
                          color: "#a1a4ab",
                          marginRight: "22px",
                        }}
                      >
                        {(selectedNoResponseProfiles?.length /
                          totalProfilesForPercentInShowDetails) *
                          100}
                        %
                      </span>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "#a1a4ab",
                      fontSize: "12px",
                      margin: "0",
                    }}
                  >
                    {(selectedNoResponseProfiles?.length /
                      totalProfilesForPercentInShowDetails) *
                      100}
                    % awaiting responses
                  </p>
                  <p style={{ color: "#e6dcdc" }}>
                    ---------------------------------------------------------------------------
                  </p>
                </div>
                <button
                  style={{
                    width: "100%",
                    backgroundColor: "#4c6fff",
                    color: "white",
                    border: "none",
                    height: "55px",
                    borderRadius: "10px",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                  onClick={handleClosePopup}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </Popup>
      </div>
      {evtpopup && (
        <>
          <Popup
            open={evtpopup}
            className="hide-pscrollbar"
            closeOnDocumentClick
            onClose={CloseevtPopup}
            position="right center"
            contentStyle={{
              width: "28%",
              maxHeight: "90%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
          >
            <div style={{ fontSize: "27px", textAlign: "right" }}>
              <IoClose onClick={CloseevtPopup} style={{ cursor: "pointer" }} />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="date-label">
                <input
                  className="event-date"
                  type="text"
                  onBlur={handleInputBlur}
                  placeholder=" "
                  value={datemsg}
                  onChange={(e) => setdatemsg(e.target.value)}
                  style={{ paddingTop: "32px" }}
                ></input>
                <span className="date-span">Event date</span>
              </div>
              <DatePicker
                selected={eventDate}
                onChange={handleDateChange}
                customInput={
                  <div
                    style={{
                      backgroundColor: "#6870fa",
                      width: "47px",
                      height: "46px",
                      marginTop: "5px",
                      borderRadius: "8px",
                    }}
                  >
                    <FaCalendar
                      style={{
                        color: "white",
                        fontSize: "22px",
                        marginLeft: "13px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                }
                showPopperArrow={false}
              />
            </div>
            <div className="date-label">
              <input
                className="event-date"
                type="text"
                onBlur={handleInputBlur}
                placeholder=" "
                value={clubName}
                onChange={(e) => setClubName(e.target.value)}
                style={{ paddingTop: "32px" }}
              ></input>
              <span className="date-span">Club Name</span>
            </div>
            <div className="date-label">
              <input
                className="event-date"
                type="text"
                onBlur={handleInputBlur}
                placeholder=" "
                value={city}
                onChange={(e) => setCity(e.target.value)}
                style={{ paddingTop: "32px" }}
              ></input>
              <span className="date-span">City</span>
            </div>
            <div className="date-label">
              <select
                className="event-date"
                onBlur={handleInputBlur}
                value={client}
                onChange={(e) => setclient(e.target.value)}
                style={{ paddingTop: "32px" }}
              >
                <option value="" disabled selected hidden></option>
                {clientsList?.map((c) => (
                  <option
                    key={c.ID}
                    value={c.ID}
                    style={{
                      backgroundColor: "#f1f2fa",
                      border: "none",
                      color: "#727681",
                    }}
                  >
                    {c.CLIENT_NAME}
                  </option>
                ))}
              </select>
              <span className="date-span">Client Name</span>
            </div>

            <div className="date-label">
              <select
                className="event-date"
                onBlur={handleInputBlur}
                style={{ paddingTop: "32px" }}
                value={organizer}
                onChange={(e) => setOrganizer(e.target.value)}
              >
                <option value="" disabled selected hidden></option>
                {organizersList?.map((o) => (
                  <option
                    key={o.ID}
                    value={o.ID}
                    style={{
                      backgroundColor: "#f1f2fa",
                      border: "none",
                      color: "#727681",
                    }}
                  >
                    {o.CLIENT_NAME}
                  </option>
                ))}
              </select>
              <span className="date-span">Organized by</span>
            </div>

            <div
              className="date-label"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                ref={inputRef}
                className="event-date"
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                onBlur={handleInputBlur}
                placeholder=" "
                style={{ width: "100%", paddingTop: "32px" }}
              />
              <span className="date-span">
                Guest Username (@alice.monvoisin)
              </span>
              {selectedGuest && showDropdown && (
                <div
                  className="dropdown-menu"
                  style={{
                    position: "fixed",
                    padding: "20px",
                    backgroundColor: "#f1f2fa",
                    display: "flex",
                    flexDirection: "column",
                    left: `${dropdownPosition.left}px`,
                    top: `${dropdownPosition.top}px`,
                    zIndex: 1000,
                    width: "250px",
                    borderRadius: "10px",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      color: "#999",
                      fontWeight: "bold",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedGuest.confirmed}
                      onChange={(e) =>
                        handleCheckboxChange("confirmed", e.target.checked)
                      }
                    />
                    Confirmed
                  </label>

                  <label
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      color: "#999",
                      fontWeight: "bold",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedGuest.maybe}
                      onChange={(e) =>
                        handleCheckboxChange("maybe", e.target.checked)
                      }
                    />
                    Maybe
                  </label>
                </div>
              )}
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            </div>

            <div>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "12px",
                  margin: "0",
                  marginLeft: "6px",
                  color: "#333",
                }}
              >
                Confirmed
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {selectedConfirmedProfiles.map((guest, index) => (
                  <div key={index} className="profile-image-container">
                    <img
                      src="https://byuc.wordpress.com/wp-content/uploads/2012/07/avat-2.jpg"
                      alt="profile"
                      className="profile-image"
                    />
                  </div>
                ))}
                {selectedConfirmedProfiles.length > 5 && (
                  <div className="more-profiles">
                    +{selectedConfirmedProfiles.length - 5}
                  </div>
                )}
              </div>

              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "12px",
                  margin: "0",
                  marginLeft: "6px",
                  color: "#333",
                }}
              >
                Maybe
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {selectedMaybeProfiles.map((guest, index) => (
                  <div key={index} className="profile-image-container">
                    <img
                      src="https://byuc.wordpress.com/wp-content/uploads/2012/07/avat-2.jpg"
                      alt="profile"
                      className="profile-image"
                    />
                  </div>
                ))}
                {selectedMaybeProfiles.length > 5 && (
                  <div className="more-profiles">
                    +{selectedMaybeProfiles.length - 5}
                  </div>
                )}
              </div>
            </div>
            <button
              style={{
                width: "100%",
                backgroundColor: "#4c6fff",
                color: "white",
                border: "none",
                height: "55px",
                borderRadius: "10px",
                fontWeight: "bolder",
                fontSize: "15px",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={handleAddNewEvent}
            >
              Add New Event
            </button>
          </Popup>
        </>
      )}
      {editEvtPopup && (
        <>
          <Popup
            open={editEvtPopup}
            className="hide-pscrollbar"
            closeOnDocumentClick
            onClose={CloseevtPopup}
            position="right center"
            contentStyle={{
              width: "28%",
              maxHeight: "90%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
          >
            <div style={{ fontSize: "27px", textAlign: "right" }}>
              <IoClose onClick={CloseevtPopup} style={{ cursor: "pointer" }} />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="date-label">
                <input
                  className="event-date"
                  type="text"
                  onBlur={handleInputBlur}
                  placeholder=" "
                  value={datemsg}
                  onChange={(e) => setdatemsg(e.target.value)}
                  style={{ paddingTop: "32px" }}
                ></input>
                <span className="date-span">Event date</span>
              </div>
              <DatePicker
                selected={eventDate}
                onChange={handleDateChange}
                customInput={
                  <div
                    style={{
                      backgroundColor: "#6870fa",
                      width: "47px",
                      height: "46px",
                      marginTop: "5px",
                      borderRadius: "8px",
                    }}
                  >
                    <FaCalendar
                      style={{
                        color: "white",
                        fontSize: "22px",
                        marginLeft: "13px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                }
                showPopperArrow={false}
              />
            </div>
            <div className="date-label">
              <input
                className="event-date"
                type="text"
                onBlur={handleInputBlur}
                placeholder=" "
                value={clubName}
                onChange={(e) => setClubName(e.target.value)}
                style={{ paddingTop: "32px" }}
              ></input>
              <span className="date-span">Club Name</span>
            </div>
            <div className="date-label">
              <input
                className="event-date"
                type="text"
                onBlur={handleInputBlur}
                placeholder=" "
                value={city}
                onChange={(e) => setCity(e.target.value)}
                style={{ paddingTop: "32px" }}
              ></input>
              <span className="date-span">City</span>
            </div>
            <div className="date-label">
              <select
                className="event-date"
                onBlur={handleInputBlur}
                value={client}
                onChange={(e) => setclient(e.target.value)}
                style={{ paddingTop: "32px" }}
              >
                <option value="" disabled selected hidden></option>
                {clientsList?.map((c) => (
                  <option
                    key={c.ID}
                    value={c.ID}
                    style={{
                      backgroundColor: "#f1f2fa",
                      border: "none",
                      color: "#727681",
                    }}
                  >
                    {c.CLIENT_NAME}
                  </option>
                ))}
              </select>
              <span className="date-span">Client Name</span>
            </div>

            <div className="date-label">
              <select
                className="event-date"
                onBlur={handleInputBlur}
                style={{ paddingTop: "32px" }}
                value={organizer}
                onChange={(e) => setOrganizer(e.target.value)}
              >
                <option value="" disabled selected hidden></option>
                {organizersList?.map((o) => (
                  <option
                    key={o.ID}
                    value={o.ID}
                    style={{
                      backgroundColor: "#f1f2fa",
                      border: "none",
                      color: "#727681",
                    }}
                  >
                    {o.CLIENT_NAME}
                  </option>
                ))}
              </select>
              <span className="date-span">Organized by</span>
            </div>

            <div
              className="date-label"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                ref={inputRef}
                className="event-date"
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                onBlur={handleInputBlur}
                placeholder=" "
                style={{ width: "100%", paddingTop: "32px" }}
              />
              <span className="date-span">
                Guest Username (@alice.monvoisin)
              </span>
              {selectedGuest && showDropdown && (
                <div
                  className="dropdown-menu"
                  style={{
                    position: "fixed",
                    padding: "20px",
                    backgroundColor: "#f1f2fa",
                    display: "flex",
                    flexDirection: "column",
                    left: `${dropdownPosition.left}px`,
                    top: `${dropdownPosition.top}px`,
                    zIndex: 1000,
                    width: "250px",
                    borderRadius: "10px",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      color: "#999",
                      fontWeight: "bold",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedGuest.confirmed}
                      onChange={(e) =>
                        handleCheckboxChange("confirmed", e.target.checked)
                      }
                    />
                    Confirmed
                  </label>

                  <label
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      color: "#999",
                      fontWeight: "bold",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedGuest.maybe}
                      onChange={(e) =>
                        handleCheckboxChange("maybe", e.target.checked)
                      }
                    />
                    Maybe
                  </label>
                </div>
              )}
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            </div>

            <div>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "12px",
                  margin: "0",
                  marginLeft: "6px",
                  color: "#333",
                }}
              >
                Confirmed
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {selectedConfirmedProfiles.map((guest, index) => (
                  <div key={index} className="profile-image-container">
                    <img
                      src="https://byuc.wordpress.com/wp-content/uploads/2012/07/avat-2.jpg"
                      alt="profile"
                      className="profile-image"
                    />
                  </div>
                ))}
                {selectedConfirmedProfiles.length > 5 && (
                  <div className="more-profiles">
                    +{selectedConfirmedProfiles.length - 5}
                  </div>
                )}
              </div>

              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "12px",
                  margin: "0",
                  marginLeft: "6px",
                  color: "#333",
                }}
              >
                Maybe
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {selectedMaybeProfiles.map((guest, index) => (
                  <div key={index} className="profile-image-container">
                    <img
                      src="https://byuc.wordpress.com/wp-content/uploads/2012/07/avat-2.jpg"
                      alt="profile"
                      className="profile-image"
                    />
                  </div>
                ))}
                {selectedMaybeProfiles.length > 5 && (
                  <div className="more-profiles">
                    +{selectedMaybeProfiles.length - 5}
                  </div>
                )}
              </div>
            </div>
            <button
              style={{
                width: "100%",
                backgroundColor: "#4c6fff",
                color: "white",
                border: "none",
                height: "55px",
                borderRadius: "10px",
                fontWeight: "bolder",
                fontSize: "15px",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={handleUpdateEvent}
            >
              Update Event
            </button>
          </Popup>
        </>
      )}
    </div>
  );
};

export default WorkCalendarOverview;
