import { ColorModeContext, useMode } from "./utils/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Dashboard from "./scenes/dashboard";
import TalentPool from "./scenes/TalentPool/index.js";
import TalentPoolProfileDetails from "./scenes/TalentPoolProfileDetails";
import EditModelProfile from "./scenes/EditModelProfile";
import LocationTracker from "./scenes/LocationTracker";
import WorkCalendar from "./scenes/WorkCalendar";
import NewFaces from "./scenes/NewFaces";
import ClientManagement from "./scenes/ClientManagement";
import CityAgencies from "./scenes/CityAgencies";
import ModelProfiles from "./scenes/ModelProfiles";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {/* All routes wrapped inside MainLayout will have Sidebar and Topbar */}
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/talentpool" element={<TalentPool />} />
            <Route
              path="/talentpool/profile/:id"
              element={<TalentPoolProfileDetails />}
            />
            <Route path="/talentpool/edit/:id" element={<EditModelProfile />} />
            <Route path="/locationtracker" element={<LocationTracker />} />
            <Route path="/workcalendar" element={<WorkCalendar />} />
            <Route path="/newfaces" element={<NewFaces />} />
            <Route path="/clientmanagement" element={<ClientManagement />} />
            <Route path="/cityagencies" element={<CityAgencies />} />
            <Route
              path="/cityagencies/modelprofile/:id"
              element={<ModelProfiles />}
            />
            {/* Add more routes here as needed */}
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

// import { ColorModeContext, useMode } from "./utils/theme";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { Routes, Route } from "react-router-dom";
// import Topbar from "./scenes/global/Topbar";
// import Sidebar from "./scenes/global/Sidebar";
// // import Dashboard from "./scenes/Dashboard";
// import Dashboard from "./scenes/dashboard";
// // import ManageChats from "./scenes/ManageChats";
// import TalentPool from "./scenes/TalentPool";
// import EditModelProfile from "./scenes/EditModelProfile";
// import LocationTracker from "./scenes/LocationTracker";
// import Login from "./scenes/Login";

// // import CityAgencies from "./scenes/CityAgencies";
// // import WorkCalendar from "./scenes/WorkCalendar";
// // import NewFaces from "./scenes/NewFaces";
// // import ClientManagement from "./scenes/ClientManagement";
// // import LocationTracker from "./scenes/LocationTracker";
// // import Setting from "./scenes/Setting";
// // import Logout from "./scenes/Logout";

// function App() {
//   const [theme, colorMode] = useMode();
//   return (
//     <ColorModeContext.Provider value={colorMode}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <div className="app">
//           <div>
//             <Sidebar />
//           </div>
//           <main className="content">
//             <Topbar />
//             <Routes>
//               {/* <Route path="/login" element={<Login />} /> */}
//               <Route path="/" element={<Dashboard />} />
//               {/* <Route path="/managechats" element={<ManageChats />} /> */}
//               <Route path="/talentpool" element={<TalentPool />} />
//               <Route path="/talentpool/edit/:id" element={<EditModelProfile />} />
//               {/* <Route path="/cityagencies" element={<CityAgencies />} /> */}
//               {/* <Route path="/workcalendar" element={<WorkCalendar />} /> */}
//               {/* <Route path="/newfaces" element={<NewFaces />} /> */}
//               {/* <Route path="/clientmanagement" element={<ClientManagement />} /> */}
//               <Route path="/locationtracker" element={<LocationTracker />} />
//               {/* <Route path="/setting" element={<Setting />} /> */}
//               {/* <Route path="/logout" element={<Logout />} /> */}
//             </Routes>
//           </main>
//         </div>
//       </ThemeProvider>
//     </ColorModeContext.Provider>
//   );
// }

// export default App;
