import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { Tooltip, Typography } from "@mui/material";
import LocationOn from "@mui/icons-material/LocationOn";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const WorldMap = ({ data }) => {
  // debugger;
  const countryCodes = new Set(data.map((country) => country.code));

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
      <ComposableMap>
        <Geographies geography="/topojsonFeatures.json">
          {({ geographies }) =>
            geographies.map((geo) => {
              // debugger;
              // Get ISO3 code for the current country
              const iso3 = geo.id;
              // Check if the country is in the data
              const isInData = countryCodes.has(iso3);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={isInData ? "#FF2D2E" : "#D6D6DA"} // Color countries in data
                  stroke="#FFF"
                />
              );
              //   <Geography
              //     key={geo.rsmKey}
              //     geography={geo}
              //     fill="#FFFFFF"
              //     stroke="#F3F7FB"
              //   />
            })
          }
        </Geographies>
        {data.map(({ coordinates, name, members }) => (
          <Marker key={name} coordinates={coordinates}>
            <Tooltip
              title={
                <>
                  <Typography color="inherit">{name}</Typography>
                  <span>Members: {members}</span>
                </>
              }
              arrow
            >
              <g
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-12, -24)"
              >
                <circle cx="12" cy="10" r="3" />
                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
              </g>
              {/* <circle r={5} fill="#F00" stroke="#fff" strokeWidth={2} /> */}
            </Tooltip>
          </Marker>
        ))}
      </ComposableMap>
    </div>
  );
};

export default WorldMap;
