import axiosClient from "./axiosClient";
import { API_ENDPOINTS } from "./apiEndpoints";

const INSTA_STORY = {
  async getProfileList(pageNumber) {
    try {
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(`/${API_ENDPOINTS.INSTA_STORY}`, {
        params: { page: pageNumber },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      // debugger;
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profiles:", error);
      return [];
    }
  },

  async getFilteredProfileList(
    searchTermForRequest,
    selectedCountry,
    selectedCity,
    selectedAgency,
    allChecked,
    openChatChecked,
    nonModelChecked,
    eliteCircleChecked,
    setDinnerCircleChecked,
    trackedFollowingChecked,
    firstChoiceChecked,
    backupGuestChecked,
    blacklistChecked,
    pageNumber
  ) {
    try {
      // debugger;
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(`/${API_ENDPOINTS.INSTA_STORY_FILTER}`, {
        params: {
          q: searchTermForRequest,
          country: selectedCountry,
          city: selectedCity,
          agency: selectedAgency,
          all: allChecked,
          openchat: openChatChecked,
          nonmodel: nonModelChecked,
          elitecircle: eliteCircleChecked,
          dinnercircle: setDinnerCircleChecked,
          trackedfollowing: trackedFollowingChecked,
          firstchoice: firstChoiceChecked,
          backupguest: backupGuestChecked,
          blacklist: blacklistChecked,
          page_number: pageNumber,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profiles:", error);
      return [];
    }
  },
};

export default INSTA_STORY;
