import React from "react";
// import { LineChart, Line, XAxis, YAxis,  } from 'recharts';
import { Box, Card, CardContent, Typography } from "@mui/material";
import { createUseStyles } from "react-jss";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  LineChart,
  Line,
} from "recharts";
import { scaleWidth } from "../../utils/units.ts";

const data = [
  { name: "Jan", value: 400 },
  { name: "Feb", value: 300 },
  { name: "Mar", value: 200 },
  { name: "Apr", value: 278 },
  { name: "May", value: 189 },
  { name: "Jun", value: 239 },
  { name: "Jul", value: 349 },
  { name: "Aug", value: 200 },
  { name: "Sep", value: 300 },
  { name: "Oct", value: 400 },
];

const GradientColors = () => {
  return (
    <linearGradient id="colorView" x1="0" y1="0" x2="0" y2="1">
      <stop
        offset="30%"
        stopColor="#F1416C" //'#F1416C29'//"#8884d8"
        stopOpacity={0.25}
      />
      <stop
        offset="75%"
        stopColor="#FFFFFF0A"
        //  "#ff9bff81"
        stopOpacity={0.3}
      />
      === ADD MORE COLOURS HERE ===
      <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.2} />
    </linearGradient>
  );
};

const GradientLineChart = () => (
  <Card>
    <CardContent>
      {/* <Typography variant="h5" component="div">
        Line Chart with Gradient Area
      </Typography> */}
      <ResponsiveContainer width="100%">
        <LineChart data={data}>
          {/* <Box style={{ background: "red", height: 200, width: 200 }}></Box> */}
          {/* <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs> */}
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            stroke="red"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Line type="monotone" dataKey="value" stroke="red" />
          <Box style={{ background: "red", height: 200, width: 200 }}></Box>
        </LineChart>
      </ResponsiveContainer>
    </CardContent>
  </Card>
);

// export default GradientLineChart;

const MyGraph = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* <h1> Plays </h1> */}
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data}>
          <defs>
            <GradientColors />
          </defs>
          <Tooltip
            itemStyle={{ color: "#fff", backgroundColor: "#0A1322" }}
            contentStyle={{ backgroundColor: "#0A1322" }}
          />
          <CartesianGrid
            strokeDasharray="4 4"
            stroke="#B6B6B6" //'#F1416C'//"#8884d8"
            opacity={0.4}
            vertical={false}
          />
          <XAxis dataKey="name" tick={{ fill: "#B6BAC3" }} stroke="#EEEEEE" hide='true' />
          <YAxis
            tick={{ fill: "black" }}
            stroke="transparent"
            fontSize={14}
            fontWeight={600}
            tickCount={6}
            alignmentBaseline="before-edge"
            ticks={data.value}
          />
          <Area
            dataKey="view"
            type="monotone"
            stroke="#F1416C" //"#8884d8"
            strokeWidth={3}
            strokeOpacity={1}
            fill="url(#colorView)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
export default MyGraph;

const useStyles = createUseStyles(() => ({
  container: {
    color: "#fff",
    // backgroundColor: 'blue', //"rgb(255, 255, 255)",
    // padding: "1rem",
    transition: "0.3s ease-in-out",
    // width: scaleWidth(666),//"100%",
    // marginTop: "6vh",
    height: "200px",
  },
}));
