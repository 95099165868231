import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Link,
  IconButton,
  Paper,
  Autocomplete,
} from "@mui/material";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { AddOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import CITY_AGENCIES_LIST from "../../client/cityAgenciesClient";
import CITIES_LIST from "../../client/citiesClient";
import AGENCIES_LIST from "../../client/agenciesClient";

const ClientManagement = () => {
  const [rows, setRows] = useState([]);
  const [rowsNotForFiltering, setRowsNotForFiltering] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalModels, setTotalModels] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [citiesList, setCitiesList] = useState([]);
  const [agenciesList, setAgenciesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState(null);

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert to Date object
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with '0' if necessary
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (January is 0), pad with '0'
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date string
  };

  const viewprofile = (id) => {
    console.log("ID: ", id);
    navigate(`/cityagencies/modelprofile/${id}`);
  };

  const handleCityChange = (event, newValue) => {
    // debugger;
    setSelectedCity(newValue);
    filterRows(searchTerm, newValue, selectedAgency, from, to);
  };

  const handleAgencyChange = (event, newValue) => {
    setSelectedAgency(newValue);
    filterRows(searchTerm, selectedCity, newValue, from, to);
  };

  const clearFilters = () => {
    setSelectedCity(null);
    setSelectedAgency(null);
    setFrom("");
    setTo("");
    setSearchTerm("");
  };

  const filterRows = (search, city, agency, from, to) => {
    debugger;
    let filteredRows = rowsNotForFiltering;

    // Filter based on city
    if (city) {
      filteredRows = filteredRows.filter((row) =>
        row.CITY
          ? row.CITY.toLowerCase().includes(city.trim().toLowerCase())
          : false
      );
    }

    // Filter based on agency
    if (agency) {
      filteredRows = filteredRows.filter((row) =>
        row.AGENCY_NAME
          ? row.AGENCY_NAME.toLowerCase().includes(agency.trim().toLowerCase())
          : false
      );
    }

    // Filter based on search term
    if (search) {
      filteredRows = filteredRows.filter((row) => {
        const rowData = Object.values(row).reduce((acc, value) => {
          if (value === null || value === undefined) {
            return acc;
          }

          if (typeof value === "object") {
            acc += Object.values(value).join(" ").toLowerCase();
          } else {
            acc += String(value).toLowerCase();
          }
          return acc;
        }, "");

        return rowData.includes(search.toLowerCase());
      });
    }

    // Filter based on 'from' and 'to' values for NUMBER_OF_MODELS
    if (from && to) {
      filteredRows = filteredRows.filter(
        (row) =>
          parseInt(row.NUMBER_OF_MODELS) >= parseInt(from) &&
          parseInt(row.NUMBER_OF_MODELS) <= parseInt(to)
      );
    } else if (from) {
      filteredRows = filteredRows.filter(
        (row) => parseInt(row.NUMBER_OF_MODELS) >= parseInt(from)
      );
    } else if (to) {
      filteredRows = filteredRows.filter(
        (row) => parseInt(row.NUMBER_OF_MODELS) <= parseInt(to)
      );
    }

    // Update the state with the filtered rows
    setTotalModels(filteredRows.length);
    setRows(filteredRows);
  };

  useEffect(() => {
    const fetchCitiesList = async () => {
      const data = await CITIES_LIST.getCitiesListForCityAgenciesPage();
      // debugger;
      if (data) {
        // console.log(data);
        setCitiesList(data.cities);
      }
    };
    const fetchAgenciesList = async () => {
      const data = await AGENCIES_LIST.getAgenciesListForCityAgenciesPage();
      // debugger;
      if (data) {
        // console.log(data);
        const filteredAgencies = data.agencies.filter(
          (agency) => agency !== null
        );
        setAgenciesList(filteredAgencies);
      }
    };
    const fetchProfileList = async () => {
      // debugger;
      let data;
      setIsDataLoading(true);
      data = await CITY_AGENCIES_LIST.getCityAgenciesList();

      if (data) {
        console.log(data);
        setRowsNotForFiltering(data.AGENCIES);
        setRows(data.AGENCIES);
        setTotalModels(data.TOTAL);
        setIsDataLoading(false);
      } else {
        setIsDataLoading(false);
      }
    };

    clearFilters();
    fetchCitiesList();
    fetchAgenciesList();
    fetchProfileList();
  }, []);

  const Row = ({ index, style }) => {
    const row = rows[index];
    return (
      <TableRow
        key={row.ID}
        style={{
          ...style,
          display: "flex",
          width: "100%",
          boxSizing: "border-box",
          background: index % 2 ? "#F2F6FA" : "#FFF",
        }}
      >
        <TableCell style={{ flex: 2 }}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={row.AGENCY_NAME}
              src={row.URL}
              sx={{ cursor: "pointer" }}
            />
            <Box>
              <Typography variant="h5" paddingLeft={2}>
                {row.AGENCY_NAME}
              </Typography>
              <Typography paddingLeft={2} display="flex">
                <Typography color="#0878FF">
                  {row.FOLLOWERS_COUNT > 1000
                    ? `${row.FOLLOWERS_COUNT / 1000}k`
                    : row.FOLLOWERS_COUNT}
                </Typography>
                <Link
                  href={row.URL}
                  underline="hover"
                  color="#B5B5C3"
                  paddingLeft={1}
                >
                  Instagram Link
                </Link>
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell style={{ flex: 1 }}>
          {row.NUMBER_OF_MODELS && row.NUMBER_OF_MODELS > 0
            ? `${row.NUMBER_OF_MODELS} Models`
            : "0 Models"}
        </TableCell>
        <TableCell style={{ flex: 1 }}>{row.CITY ? row.CITY : "NA"}</TableCell>
        <TableCell style={{ flex: 1, fontWeight: "bolder" }}>
          {row.MODEL_AGENCY ? row.MODEL_AGENCY : "NA"}
        </TableCell>
        <TableCell
          style={{
            flex: 1,
            fontWeight: "bolder",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.URL ? (
            <Link
              href={row.URL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              {row.URL}
            </Link>
          ) : (
            "NA"
          )}
        </TableCell>
        <TableCell style={{ flex: 1, fontWeight: "bolder" }}>
          {row.DATE ? formatDate(row.DATE) : "NA"}
        </TableCell>
        <TableCell style={{ flex: 1 }}>
          <Button
            onClick={() => viewprofile(row.ID)}
            style={{
              borderRadius: "4px",
              border: "1px solid blue",
              color: "blue",
              textTransform: "none",
            }}
          >
            View Profiles
          </Button>
        </TableCell>
        <TableCell style={{ flex: 1 }}>
          <IconButton
            aria-label="edit"
            // onClick={() => handleEdit(row.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            // onClick={() => handleDelete(row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper
      sx={{
        margin: "10px 20px 10px 10px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <div style={{ display: "block", padding: "0px" }}>
          <Typography fontWeight="bold" fontSize="15px">
            City Agencies Management
          </Typography>
          <Typography color="#B5B5C3">
            Total number of models in agencies ({totalModels})
          </Typography>
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* City Dropdown */}
            <Autocomplete
              value={selectedCity}
              onChange={handleCityChange}
              disablePortal
              id="city-combo-box"
              options={citiesList}
              sx={{
                border: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => <TextField {...params} label="City" />}
            />

            {/* Agencies Dropdown */}
            <Autocomplete
              value={selectedAgency}
              onChange={handleAgencyChange}
              disablePortal
              id="agency-combo-box"
              options={agenciesList}
              sx={{
                border: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => <TextField {...params} label="Agency" />}
            />

            <Typography color="#7E8299">Number of Models</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "10px",
                background: "none",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <TextField
                  variant="outlined"
                  value={from}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Ensure that the value is not negative
                    if (value >= 0) {
                      setFrom(value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      filterRows(
                        searchTerm,
                        selectedCity,
                        selectedAgency,
                        e.target.value,
                        to
                      );
                    }
                  }}
                  size="small"
                  placeholder="From"
                  type="number"
                  InputProps={{
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      backgroundColor: "#f0f0f0",
                      width: "60px",
                      borderRadius: "5px",
                    },
                    inputProps: {
                      min: 0, // Prevent negative numbers
                    },
                  }}
                  inputProps={{
                    style: { color: "black", fontWeight: "bolder" },
                  }}
                  sx={{
                    width: "60px",
                  }}
                />
              </Box>
              <Typography>:</Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <TextField
                  variant="outlined"
                  value={to}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Ensure that the value is not negative
                    if (value >= 0) {
                      setTo(value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      filterRows(
                        searchTerm,
                        selectedCity,
                        selectedAgency,
                        from,
                        e.target.value
                      );
                    }
                  }}
                  size="small"
                  placeholder="To"
                  type="number"
                  InputProps={{
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      backgroundColor: "#f0f0f0",
                      width: "60px",
                      borderRadius: "5px",
                    },
                    inputProps: {
                      min: 0, // Prevent negative numbers
                    },
                  }}
                  inputProps={{
                    style: { color: "black", fontWeight: "bolder" },
                  }}
                  sx={{
                    width: "60px",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              alignItems: "center",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              placeholder="Search"
              size="small"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  filterRows(searchTerm, selectedCity, selectedAgency, from, to);
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      <div
        style={{
          // overflowY: "auto",
          maxHeight: "60%",
          maxWidth: "100%",
        }}
      >
        <TableContainer>
          <Table sx={{ overflowX: "scroll" }}>
            <TableHead>
              <TableRow
                style={{
                  display: "flex",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <TableCell style={{ flex: 2, fontWeight: "650" }}>
                  TOTAL AGENCIES({totalModels})
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  NUMBER OF MODELS
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  CITY
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  MODEL AGENCY
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  URL
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  DATE
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  VIEW MODELS
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  -----------
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataLoading ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "6vh", //max: 70vh
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Loading...
                </div>
              ) : rows && rows.length > 0 ? (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      height={500}
                      itemCount={rows.length}
                      itemSize={80}
                      width={width}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "6vh",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No data
                </div>
                // <TableRow>
                //   <TableCell align="center">No data</TableCell>
                // </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};

export default ClientManagement;
