import React from "react";

import "./styles.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";

export default function OpenStreetMap({ markersData }) {
  const customIcon = new Icon({
    iconUrl: require("../../icons/map-marker-icon.png"),
    iconSize: [38, 38],
  });
  return (
    <MapContainer
      style={{ height: "100vh", position: "sticky" }}
      center={[48.8566, 2.3522]}
      zoom={5}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {markersData.map((marker) => (
        <Marker position={marker.geoCode} icon={customIcon}>
          <Popup>{marker.location}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}
