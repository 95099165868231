import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import qs from "qs";

import { FaCalendar } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import INSTA_PROFILE_LIST from "../../client/instaProfileListClient";

const EditTalentPoolProfile = ({ ID, closeEdit }) => {
  const [selectedNewClientImage, setSelectedNewClientImage] = useState(null);
  const [instaProfile, setInstaProfile] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");
  const [message4, setMessage4] = useState("");
  const [d_city, setd_city] = useState("");
  const [non_model, setModel] = useState("");
  const [blocklist, setblockLList] = useState("");
  const [dinner, setdinner] = useState("");
  const [elite, setelite] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [city, setCity] = useState("");
  const [openAddNewMemberModal, setOpenAddNewMemberModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checkedl, setCheckedl] = useState(false);
  const [language, setlanguage] = useState("");
  const [note, setnote] = useState("");
  const [track, settrack] = useState("");
  const languages = ["English", "Urdu","Spanish","French","German","Portuguese", "Spanish"];
  const [editmodal, seteditmodal] = useState(false);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    setMessage(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
    setMessage2(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
    setMessage3(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
    setMessage4(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange5 = (event) => {
    setChecked5(event.target.checked);
    setd_city(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange6 = (event) => {
    setChecked6(event.target.checked);
    setModel(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange7 = (event) => {
    const isChecked = event.target.checked;
    setChecked7(isChecked);
    if (isChecked) {
      const formattedDate = startDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      setblockLList(`Blocked Till ${formattedDate}`);
    } else {
      setblockLList("");
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    if (checked7) {
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      setblockLList(`Blocked Till ${formattedDate}`);
    }
  };

  const handleCheckboxChange8 = (event) => {
    setChecked8(event.target.checked);
    setdinner(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange9 = (event) => {
    setChecked9(event.target.checked);
    setelite(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChangel = (event) => {
    setCheckedl(event.target.checked);
    settrack(event.target.checked ? "True" : "False");
  };
  const resetAll = () => {
    setChecked(false);
    setChecked2(false);
    setChecked3(false);
    setChecked4(false);
    setChecked5(false);
    setChecked6(false);
    setChecked7(false);
    setChecked8(false);
    setChecked9(false);
    setCheckedl(false);
    setlanguage("");
    setnote("");
    settrack("");
    setStartDate(null);
    setMessage("");
    setMessage2("");
    setMessage3("");
    setMessage4("");
    setd_city("");
    setModel("");
    setblockLList("");
    setdinner("");
    setelite("");
    setCity("");
    setInstaProfile("");
    setName("");
    setSelectedNewClientImage(null);
  };

  const handleNewClientImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedNewClientImage(URL.createObjectURL(e.target.files[0]));
    }
    console.log("Picture URL : ", selectedNewClientImage);
  };

  useEffect(() => {
    // console.log('profileToEditID: ', ID);
    const fetchProfileByID = async () => {
      let data = await INSTA_PROFILE_LIST.getProfileByID(ID);
      if (data?.PROFILES.length > 0) {
        console.log("fetchProfileByID : ", data);
        const profileDetails = data.PROFILES[0];
        setInstaProfile(profileDetails.TALENTPOOL_PROFILE_USERNAME);
        setName(profileDetails.TALENTPOOL_PROFILE_NAME);
        setMessage(
          profileDetails.TALENTPOOL_SEND_MESSAGE
            ? profileDetails.TALENTPOOL_SEND_MESSAGE
            : false
        );
        setChecked(
          profileDetails.TALENTPOOL_SEND_MESSAGE
            ? profileDetails.TALENTPOOL_SEND_MESSAGE
            : false
        );
        setMessage2(
          profileDetails.TALENTPOOL_REPLY_MESSAGE
            ? profileDetails.TALENTPOOL_REPLY_MESSAGE
            : false
        );
        setChecked2(
          profileDetails.TALENTPOOL_REPLY_MESSAGE
            ? profileDetails.TALENTPOOL_REPLY_MESSAGE
            : false
        );
        setMessage3(
          profileDetails.TALENTPOOL_FIRST_CHOICE
            ? profileDetails.TALENTPOOL_FIRST_CHOICE
            : false
        );
        setChecked3(
          profileDetails.TALENTPOOL_FIRST_CHOICE
            ? profileDetails.TALENTPOOL_FIRST_CHOICE
            : false
        );
        setMessage4(
          profileDetails.TALENTPOOL_BACKUP_GUEST
            ? profileDetails.TALENTPOOL_BACKUP_GUEST
            : false
        );
        setChecked4(
          profileDetails.TALENTPOOL_BACKUP_GUEST
            ? profileDetails.TALENTPOOL_BACKUP_GUEST
            : false
        );
        setCity(profileDetails.TALENTPOOL_STORY_CITY);
        setd_city(
          profileDetails.TALENTPOOL_DINNER_ONLY
            ? profileDetails.TALENTPOOL_DINNER_ONLY
            : false
        );
        setChecked5(
          profileDetails.TALENTPOOL_DINNER_ONLY
            ? profileDetails.TALENTPOOL_DINNER_ONLY
            : false
        );
        setModel(
          profileDetails.TALENTPOOL_NON_MODEL
            ? profileDetails.TALENTPOOL_NON_MODEL
            : false
        );
        setChecked6(
          profileDetails.TALENTPOOL_NON_MODEL
            ? profileDetails.TALENTPOOL_NON_MODEL
            : false
        );
        setblockLList(
          profileDetails.TALENTPOOL_BLACKLIST !== null
            ? `${profileDetails.TALENTPOOL_BLACKLIST}`
            : ""
          // !isNaN(Date.parse(profileDetails.TALENTPOOL_BLACKLIST))
          //   ? `Blocked Till ${profileDetails.TALENTPOOL_BLACKLIST}`
          //   : ""
        );
        setChecked7(
          !isNaN(Date.parse(profileDetails.TALENTPOOL_BLACKLIST)) ? true : false
        );
        setdinner(
          profileDetails.TALENTPOOL_DINNER_CIRCLE
            ? profileDetails.TALENTPOOL_DINNER_CIRCLE
            : false
        );
        setChecked8(
          profileDetails.TALENTPOOL_DINNER_CIRCLE
            ? profileDetails.TALENTPOOL_DINNER_CIRCLE
            : false
        );
        setelite(
          profileDetails.TALENTPOOL_ELITE_CIRCLE
            ? profileDetails.TALENTPOOL_ELITE_CIRCLE
            : false
        );
        setChecked9(
          profileDetails.TALENTPOOL_ELITE_CIRCLE
            ? profileDetails.TALENTPOOL_ELITE_CIRCLE
            : false
        );
        setlanguage(
          languages.includes(profileDetails.TALENTPOOL_LANGUAGE)
            ? profileDetails.TALENTPOOL_LANGUAGE
            : "English"
        );
        setnote(profileDetails.TALENTPOOL_NOTE);
        settrack(
          profileDetails.TALENTPOOL_TRACKED_FOLLOWINGS
            ? profileDetails.TALENTPOOL_TRACKED_FOLLOWINGS
            : false
        );
        setCheckedl(
          profileDetails.TALENTPOOL_TRACKED_FOLLOWINGS
            ? profileDetails.TALENTPOOL_TRACKED_FOLLOWINGS
            : false
        );
      } else {
      }
    };

    fetchProfileByID();
  }, [ID]);

  const handleUpdateProfile = async () => {
    try {
      const data = qs.stringify({
        client_id: ID,
        model_name: name || "", // Keep empty if not set
        instagram_profile: instaProfile || "", // Keep empty if not set
        send_message: message || "False", // Default to false if not set
        reply_message: message2 || "False", // Default to false if not set
        first_choice: message3 || "False", // Default to false if not set
        backup_guest: message4 || "False", // Default to false if not set
        Story_City: city || "", // Keep empty if not set
        Block_list: blocklist || "", // Default to false if not set
        non_model: non_model || "False", // Default to false if not set
        dinner_only: d_city || "False", // Keep empty if not set
        select_dinner_circle: dinner || "False", // Default to false if not set
        elite_circle: elite || "False", // Default to false if not set
        language: language || "English", // Keep empty if not set
        note: note || "", // Keep empty if not set
        tracked_following: track || "False", // Default to false if not set
      });

      console.log("Post Request Data : ", data);
      const response = await INSTA_PROFILE_LIST.updateProfileByID(data);

      if (response) {
        console.log("Client updated successfully:", response);
        closeEdit();
      } else {
        console.error("Failed to upadte client.");
      }
    } catch (error) {
      console.error("Error Updating client:", error);
    }

    // setOpenAddNewMemberModal(false);
    // resetAll();
    // setJustForReload(!justForReload);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "1px solid #DDE2E5",
        borderRadius: "20px",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Typography variant="h4">Update Talent Pool Profile</Typography>
      <Box display="flex" marginTop="10px">
        <Box
          minWidth="20%"
          sx={{
            width: "10vw",
            height: "10vw",
            border: "2px dashed gray",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-button"
            type="file"
            onChange={handleNewClientImageUpload}
          />
          <label htmlFor="upload-button">
            {selectedNewClientImage ? (
              <img
                src={selectedNewClientImage}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <IconButton component="span">
                <AddPhotoAlternateIcon style={{ fontSize: 80 }} />
              </IconButton>
            )}
          </label>
        </Box>
        <Box maxWidth="80%">
          <Grid container spacing={2} paddingLeft="44px">
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Instagram Profile"
                name="instaProfile"
                fullWidth
                value={instaProfile}
                onChange={(e) => setInstaProfile(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ paddingTop: "12px", marginLeft: "0px" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Send Message"
                  name="Send Message"
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckboxChange}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Reply Message"
                  name="Reply Message"
                  fullWidth
                  value={message2}
                  onChange={(e) => setMessage2(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked2}
                          onChange={handleCheckboxChange2}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ paddingTop: "12px", marginLeft: "0px" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="First Choice"
                  name="Fisrt Choice"
                  fullWidth
                  value={message3}
                  onChange={(e) => setMessage3(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked3}
                          onChange={handleCheckboxChange3}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Backup Guest"
                  name="Backup Guest"
                  fullWidth
                  value={message4}
                  onChange={(e) => setMessage4(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked4}
                          onChange={handleCheckboxChange4}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Story City"
                name="Story City"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Dinner Only"
                name="Dinner Only"
                fullWidth
                value={d_city}
                onChange={(e) => setd_city(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Checkbox
                        checked={checked5}
                        onChange={handleCheckboxChange5}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ paddingTop: "12px", marginLeft: "0px" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Non Model"
                  name="Non Model"
                  fullWidth
                  value={non_model}
                  onChange={(e) => setModel(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked6}
                          onChange={handleCheckboxChange6}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={10} sm={10} md={4.5}>
                <TextField
                  label="Block List"
                  name="Block List"
                  fullWidth
                  value={blocklist}
                  onChange={(e) => setblockLList(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked7}
                          onChange={handleCheckboxChange7}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                customInput={
                  <div
                    style={{
                      backgroundColor: "#6870fa",
                      width: "50px",
                      height: "44px",
                      marginTop: "18px",
                      borderRadius: "8px",
                      marginLeft: "6px",
                    }}
                  >
                    <FaCalendar
                      style={{
                        color: "white",
                        fontSize: "22px",
                        marginLeft: "13px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                }
                showPopperArrow={false}
              />
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ paddingTop: "12px", marginLeft: "0px" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Select Dinner Circle"
                  name="Set Dinner Circle"
                  value={dinner}
                  fullWidth
                  onChange={(e) => setdinner(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked8}
                          onChange={handleCheckboxChange8}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Elite Circle"
                  name="Elite Circle"
                  value={elite}
                  fullWidth
                  onChange={(e) => setelite(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          checked={checked9}
                          onChange={handleCheckboxChange9}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="language-select-label">
                  Select Language
                </InputLabel>
                <Select
                  labelId="language-select-label"
                  id="language-select-label"
                  name="language"
                  value={language}
                  label="Select Language"
                  onChange={(e) => setlanguage(e.target.value)}
                >
                  {languages.map((language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Write Note"
                name="Write Note"
                fullWidth
                value={note}
                onChange={(e) => setnote(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Tracked Following"
                name="Tracked Following"
                value={track}
                fullWidth
                onChange={(e) => settrack(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Checkbox
                        checked={checkedl}
                        onChange={handleCheckboxChangel}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop="1%" paddingLeft="44px">
            <Grid item xs={12} sm={12} md={6}>
              <Button
                disableElevation
                onClick={closeEdit}
                sx={{
                  background: "#FFFFFF",
                  border: "1px solid #0D6EFD",
                  marginRight: "10px",
                  borderRadius: "4px",
                  textTransform: "none",
                  width: "100%",
                  color: "#0D6EFD",
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Button
                disableElevation
                onClick={handleUpdateProfile}
                sx={{
                  background: "#0D6EFD",
                  border: "1px solid #0D6EFD",
                  marginRight: "10px",
                  borderRadius: "4px",
                  textTransform: "none",
                  width: "100%",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#0256d9",
                    borderColor: "#0256d9",
                  },
                }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default EditTalentPoolProfile;
