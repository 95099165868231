import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import qs from "qs";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Default styles for the phone input
import CLIENT_MANAGEMENT_LIST from "../../client/clientsManagementClient";

const EditClientManagementClientProfile = ({ ID, handleCloseEditClientModal }) => {
  const Prefaces = ["Clubbing", "Beach club", "Dinner", "Trips"];
  const SpenderCategories = ["Top Spender", "Casual Spender"];

  const [selectedClientImage, setSelectedClientImage] = useState(null);
  const [instaProfile, setInstaProfile] = useState("");
  const [name, setName] = useState("");
  const [spenderCategory, setSpenderCategory] = useState("");
  const [phone, setPhone] = useState("");
  const [totalSpend, setTotalSpend] = useState("");
  const [commissionPercent, setCommissionPercent] = useState("");
  const [city, setCity] = useState("");
  const [selectedPrefaces, setSelectedPrefaces] = useState([]);

  const handleClientImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedClientImage(URL.createObjectURL(e.target.files[0]));
    }
    console.log("Picture URL : ", selectedClientImage);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (!selectedPrefaces.includes(value)) {
      setSelectedPrefaces([...selectedPrefaces, value]);
    }

    console.log("Selected Prefaces", selectedPrefaces.join(", "));
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setSelectedPrefaces((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  };

  const handleUpdateClient = async () => {
    try {
      const data = qs.stringify({
        client_id: ID,
        client_name: name || "", // Keep empty if not set
        client_instagram_profile: instaProfile || "", // Keep empty if not set
        client_spender_category: spenderCategory || "Top Spender", // Default to "Top Spender" if not set
        client_phone_number: phone || "", // Keep empty if not set
        client_total_spend: totalSpend || "0", // Default to 0 if not set
        client_commission: commissionPercent || "0", // Default to 0 if not set
        client_city: city || "", // Keep empty if not set
        client_preferences: selectedPrefaces.join(", ") || "", // Keep empty if not set
      });

      console.log("Post Update Request Data : ", data);
      const response = await CLIENT_MANAGEMENT_LIST.updateClientProfileByID(
        data
      );

      if (response) {
        console.log("Client Updated successfully:", response);
        handleCloseEditClientModal();
      } else {
        console.error("Failed to update client.");
      }
    } catch (error) {
      console.error("Error updating client:", error);
    }
  };

  useEffect(() => {
    const fetchClientProfileByID = async () => {
      let data = await CLIENT_MANAGEMENT_LIST.getClientProfileByID(ID);
      console.log("ID of client : ", ID);
      console.log("getClientProfileByID : ", data);
      if (data?.CLIENT?.length > 0) {
        const profileDetails = data.CLIENT[0];
        setInstaProfile(profileDetails.CLIENT_INSTAGRAM_PROFILE);
        setName(profileDetails.CLIENT_NAME);
        setSpenderCategory(profileDetails.CLIENT_SPENDER_CATEGORY);
        setPhone(profileDetails.CLIENT_PHONE_NUMBER);
        setTotalSpend(profileDetails.CLIENT_TOTAL_SPEND);
        setCommissionPercent(profileDetails.CLIENT_COMMISSION);
        setCity(profileDetails.CLIENT_CITY);

        const preferencesString = profileDetails.CLIENT_PREFERENCES;
        const preferencesArray = preferencesString
          .split(",")
          .map((item) => item.trim());
        setSelectedPrefaces(preferencesArray);
      } else {
      }
    };
    fetchClientProfileByID();
  }, [ID]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "1px solid #DDE2E5",
        borderRadius: "20px",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Typography variant="h4">Update Client</Typography>
      <Box display="flex" marginTop="10px">
        <Box
          minWidth="20%"
          sx={{
            width: "10vw",
            height: "10vw",
            border: "2px dashed gray",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-button"
            type="file"
            onChange={handleClientImageUpload}
          />
          <label htmlFor="upload-button">
            {selectedClientImage ? (
              <img
                src={selectedClientImage}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <IconButton component="span">
                <AddPhotoAlternateIcon style={{ fontSize: 80 }} />
              </IconButton>
            )}
          </label>
        </Box>
        <Box maxWidth="80%">
          <Grid container spacing={2} paddingLeft="44px">
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Instagram Profile"
                name="instaProfile"
                fullWidth
                value={instaProfile}
                onChange={(e) => setInstaProfile(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="spender-category-select-label">
                  Spender Category
                </InputLabel>
                <Select
                  labelId="spender-category-select-label"
                  id="spender-category-select"
                  name="spenderCategory"
                  value={spenderCategory}
                  label="Spender Category"
                  onChange={(e) => setSpenderCategory(e.target.value)}
                >
                  {SpenderCategories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <PhoneInput
                country={"pk"} // Default country
                value={phone}
                onChange={(phone) => setPhone(phone)}
                inputStyle={{
                  border: "none",
                  height: "100%",
                  fontSize: "16px",
                  width: "100%",
                  borderRadius: "4px",
                }}
                buttonStyle={{
                  backgroundColor: "transparent",
                  borderRadius: "4px 0 0 4px",
                  border: "none",
                }}
                dropdownStyle={{
                  borderRadius: "8px",
                }}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #acacb1",
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Total Spend"
                name="totalSpend"
                fullWidth
                value={totalSpend}
                onChange={(e) => setTotalSpend(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Commission %"
                name="commissionPercent"
                fullWidth
                value={commissionPercent}
                onChange={(e) => setCommissionPercent(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="City"
                name="city"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="preface-label">Prefaces</InputLabel>
                <Select
                  labelId="preface-label"
                  value=""
                  onChange={handleSelectChange}
                  input={<OutlinedInput label="Prefaces" />}
                >
                  {Prefaces.map((preface) => (
                    <MenuItem key={preface} value={preface}>
                      {preface}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", gap: 1, marginTop: 2 }}>
                {selectedPrefaces.map((preface, index) => (
                  <Chip
                    key={index}
                    label={preface}
                    onDelete={handleDeleteChip(preface)}
                    sx={{
                      backgroundColor: "#C3E329",
                      color: "#000",
                      fontWeight: "bold",
                      "& .MuiChip-deleteIcon": {
                        color: "#000",
                      },
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop="1%" paddingLeft="44px">
            <Grid item xs={12} sm={12} md={6}></Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Button
                disableElevation
                sx={{
                  background: "#0D6EFD",
                  border: "1px solid #0D6EFD",
                  marginRight: "10px",
                  borderRadius: "4px",
                  textTransform: "none",
                  width: "100%",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#0256d9",
                    borderColor: "#0256d9",
                  },
                }}
                onClick={handleUpdateClient}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default EditClientManagementClientProfile;
