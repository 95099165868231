import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../utils/theme";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
import { LocationOn } from "@mui/icons-material";

const styles = {
  root: {
    width: "100%",
  },
  listItem: {
    minHeight: "72px",
    borderBottom: "dashed 2px",
    borderColor: "#F4F4F4",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: 0,
    justifyContent: "space-between",
  },
  avatar: {
    width: "56px", // Adjust the size of the Avatar
    height: "56px", // Adjust the size of the Avatar
    borderRadius: "2px", // Rounded corners
    marginRight: "16px", // Space between avatar and text
  },
};

const Statbox_UpcomingEvents = () => {
  const [totalMsgSent, setTotalMsgSent] = useState("6.3k");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const events = [
    {
      id: 1,
      startDate: "19th Jun",
      endDate: "23rd Jun",
      location: "9 Degree Project Estimation Meeting",
      image:
        "https://s3-alpha-sig.figma.com/img/5744/9ba8/7714695b75ed83e945a8387f0113a115?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UHtITzCbYlGK9ADdsrFhEz6QX6V1PYjWkTl1G0RUAzBxvfd89xCK4zi~Er9XJn6-vwE-4fYvKsytNiUVp3qL7LdT1qlXGnhXEyEqB8fE~zP3haNpA238mgnSpHFLpGIPn4Iq-h97UmFSh8YvYwyqCQhTgLHVeXHALW2P75j5wJOdpKvdskN56nSywdfhB2AiUeqzx3f~7LmPqDS8ZEM~a0KEUoReKXnDigOImdrK7uaoN00SFlX1O4cZ~UdNlYRksWVr7R8bun-sTkt9KF3eqngiGOJ4xvj2-3i0M3oHN4iz7NCzulJ8Mg7HqGdZzMMDBSC2H7QB7Xsp4npH3yS6NA__",
      totalSpend: 10.5,
      eventColor: "#7239EA",
      organizer: "Lead by Bob",
      models: 40
    },
    {
      id: 2,
      startDate: "25th Jun",
      endDate: "28th Jun",
      location: "9 Degree Project Estimation Meeting",
      image:
        "https://s3-alpha-sig.figma.com/img/4ea7/3211/a7d460a9b82ad1b2cd5d89177e3c52f9?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=S3XyhjvrPgKjC30UG8QgcTdgN6md427wgYCueFOqbIm0JRGW9FqaQsgwcfXkieCOO2VflviRjXF8ZQ-pi3V3CqMJk0bM2HR7MYWtOMesxPLkm~aYZ0DN~v9b6XuLqYzY40RytdGoqW6IfbC~1VmnmxxgnIR7bOk1wPzkp96v1OgwnmCZaVfx3pcnHtQd7BKyYtQdsL60ZBmT3w9WVRIC7gZjABQ9r625AQQ3ksPV-W4c6vgaE7GmB1zHvathQJuXAttuwDHCriFmsBFUeu1s6daskL2P1MuTBCPvfw1iS~aCugFbBRQfYmjZVT3Gp4X1IZkrnAoQrGM2JSGGYgVjLw__",
      totalSpend: 8.5,
      eventColor: "#F1416C",
      organizer: "Lead by Bob",
      models: 22
    },
    {
      id: 3,
      startDate: "02nd Jul",
      endDate: "08th Jul",
      location: "9 Degree Project Estimation Meeting",
      image:
        "https://s3-alpha-sig.figma.com/img/1349/3425/2098473bc5a858f3cbef095f0b74559a?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OupO57rBICsASQwy1wUkQRYV8snzjFyYhK5zxFZbjWlIT3flrj05dkldiLUm1l8sgl1SbbiPI7nqJnW7Ry3YidSAUxV5ZEDouUz~H12spc-Pe1bXxqprbb1sWYPcRVy4BYM93OMZMwi908R04LKqZyodZcPcLo1Oez~A-7DwcLmnj-YgQdnZYlrtOG0wzHQr~Bz7Ipx3cbWOfrN6nFeIYmr32ecckCmpUiH-qjCnuc1lZFFRN-8qEMqIkQTED0LTbHCMJg9BrfzOx~0PPclU6TgrYvdJH-BK5-aNoE63IxFznYXdc8ryzHTULoIi~Pmeo8M6wQpQUrYZm0JJWAvHZA__",
      totalSpend: 5.5,
      eventColor: "#00D9D9",
      organizer: "Lead by Bob",
      models: 25
    },
    {
      id: 4,
      startDate: "09th Jul",
      endDate: "10th Jul",
      location: "9 Degree Project Estimation Meeting",
      image:
        "https://s3-alpha-sig.figma.com/img/1b5a/3eb0/e898ac9104c1194b6be408e5c0092256?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MtEwiu7wLNtuOCok~jxLozn4ZO94bGAyijys9FjXVvYSDBXU3VlfVO-itQMr2~8~bU9i5j3aIW0BMZnB6QW9zdT65N-0DAnvMHGnwqpDnxBMPwKoYSa7tvwLn~VTfbRidOMrzdKesI81gk5pEbLIIWrRzaciRwYM96VNcbOEZXoVXFYrZCmV~igxA3g1n-srgG-fdZmlYEQ4A8yXo~rJIxrCyPlxpfdy9h59fj~jKgiD5FzsCL9CMu30vhtCAdkrVY~nuAAist5kdUHS~IYbf9~BXPV1vzA9oJJDnjKJFxt57X0lDcIfxlos2owU~MjYFGq4JvHiK47Gn56OZLCcvw__",
      totalSpend: 4.5,
      eventColor: "#FFC700",
      organizer: "Lead by Bob",
      models: 15
    },
    {
      id: 5,
      startDate: "15th Jul",
      endDate: null,
      location: "9 Degree Project Estimation Meeting",
      image:
        "https://s3-alpha-sig.figma.com/img/973e/0765/d37cc656110b7aa8c24fd94d333a60f5?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eBtEbYhpGIZzQoGOzykVOMjspYICsw4NrhRu79FdZQa2wj3MJKigm3T2gFLuYyCzE69pUT~V~UNKQwKhe-pNkupLJLxs-2yX94WyVV4x1eACScTEAXjS4izOE-ojxmY1CtLArR1B-EoTaCOeTvgSZ3FA5yNdjCuvrzrXrHSVxCQa-aen2oTOqNJIrtew1tcjFWKxR4uCxMKEOInEmDug5P6Ia~m1sm8T2Fnv3wLmj1m2nlfgvcTGKujwpQOI0GiVNkANK~9OrhIcNbPVFLyO3-4KkRsPj2xM27fkNC2CkR4e31YUHPIiG1XPrAo-IbTh0qG~jpFiZRH0LX4Pk3mPFA__",
      totalSpend: 3.5,
      eventColor: "#03A9F4",
      organizer: "Lead by Bob",
      models: 30
    },
    // Add more member objects as needed
  ];

  return (
    <Box minHeight="500px">
      <Box className="card-header" padding="22.75px 29.25px 6.5px 12px">
        <Box display="flex"></Box>
        <Typography variant="h5" color={colors.grey[100]}>
          Upcoming Events
        </Typography>

        <Typography variant="h7" color={colors.grey[500]}>
          Total {events.length}
        </Typography>
      </Box>
      <Box
        className="card-content"
        display="block"
        minHeight="419px"
        alignItems="start"
        margin="10px 0 0 0"
      >
        <List style={styles.root}>
          {events.map((event) => (
            <ListItem key={event.id} style={styles.listItem}>
              <Box
                display="flex"
                borderLeft="solid 4px"
                borderColor={event.eventColor}
                padding="10px"
                marginX="10px"
              >
                <ListItemText
                  primary={
                    <Typography variant="h4">
                      {event.startDate}
                      {event.endDate !== null ? " - " + event.endDate : ""}
                    </Typography>
                  }
                  secondary={
                    <Box>
                      <Box display="flex">
                        <LocationOn />
                        <Typography variant="h6">{event.location}</Typography>
                      </Box>
                      <Box display="flex" alignItems='center'>
                        <Typography variant="h7">Organized by </Typography>
                        <Typography
                          color={event.eventColor}
                          variant="h7"
                          paddingLeft="5px"
                        >
                          {event.organizer}
                        </Typography>
                        <Typography
                          color='#4AAE8C'
                          variant="h7"
                          marginLeft="10px"
                          backgroundColor='#DEFFEE'
                          paddingY='5px'
                          paddingX='20px'
                          borderRadius='15px'
                        >
                          {event.models} models
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
              <Box maxWidth="146px" width="40%" display={"flex"} justifyContent='flex-end'>
                <Button
                  variant="contained"
                  style={{ color: "#7E8299", backgroundColor: '#F9F9F9' }}
                  disableElevation
                >
                  View
                </Button>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Statbox_UpcomingEvents;
