import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  InputLabel,
  TableRow,
  Paper,
  Button,
  Avatar,
  TextField,
  IconButton,
  Typography,
  FormControl,
  Select,
  Grid,
  Modal,
  MenuItem,
  Pagination,
  Box,
  Link,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { FaCalendar } from "react-icons/fa6";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddOutlined, InfoOutlined } from "@mui/icons-material";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import qs from "qs";
import INSTA_PROFILE_LIST from "../../client/instaProfileListClient";
import CITIES_LIST from "../../client/citiesClient";
import AGENCIES_LIST from "../../client/agenciesClient";

import allCountriesList from "../../data/countriesList.json";
import EditTalentPoolProfile from "../EditTalentPoolProfile";

const filtersArray = [
  { label: "All", value: "all" },
  { label: "Open Chat", value: "OPEN_CHAT" },
  { label: "Non Model", value: "NON_MODEL" },
  { label: "Elite Circle", value: "ELITE_CIRCLE" },
  { label: "Select Dinner Circle", value: "DINNER_CIRCLE" },
  { label: "Travel Planner", value: "TRAVEL_PLANNER" },
  { label: "Dinner Only", value: "DINNER_ONLY" },
  // { label: "Tracked Following", value: "TRACKED_FOLLOWINGS" },
  // { label: "In Town", value: "In Town" },
  { label: "First Choice", value: "FIRST_CHOICE" },
  { label: "Backup Guest", value: "BACKUP_GUEST" },
  // { label: "Blacklist", value: "Blacklist" },
];

const TableComponent = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [totalProfiles, setTotalProfiles] = useState(0);
  const [avgNewProfiles, setAvgNewProfiles] = useState(57);
  const [rowsNotForFiltering, setRowsNotForFiltering] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(["all"]);
  const [citiesList, setCitiesList] = useState([]);
  const [agenciesList, setAgenciesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermForRequest, setSearchTermForRequest] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Create individual state variables for each filter
  // const [allChecked, setAllChecked] = useState(1);
  // const [openChatChecked, setOpenChatChecked] = useState(0);
  // const [nonModelChecked, setNonModelChecked] = useState(0);
  // const [eliteCircleChecked, setEliteCircleChecked] = useState(0);
  // const [setDinnerCircleChecked, setSetDinnerCircleChecked] = useState(0);
  // const [travelPlannerChecked, setTravelPlannerChecked] = useState(0);
  // const [dinnerOnlyChecked, setDinnerOnlyChecked] = useState(0);
  // const [trackedFollowingChecked, setTrackedFollowingChecked] = useState(0);
  // const [inTownChecked, setInTownChecked] = useState(0);
  // const [firstChoiceChecked, setFirstChoiceChecked] = useState(0);
  // const [backupGuestChecked, setBackupGuestChecked] = useState(0);
  // const [blacklistChecked, setBlacklistChecked] = useState(0);
  const [justForReload, setJustForReload] = useState(true);

  const navigate = useNavigate();
  const [profileToEditID, setProfileToEditID] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");
  const [message4, setMessage4] = useState("");
  const [d_city, setd_city] = useState("");
  const [non_model, setModel] = useState("");
  const [blocklist, setblockLList] = useState("");
  const [dinner, setdinner] = useState("");
  const [elite, setelite] = useState("");

  const [city, setCity] = useState("");

  const [instaProfile, setInstaProfile] = useState("");

  const [name, setName] = useState("");
  const [selectedNewClientImage, setSelectedNewClientImage] = useState(null);
  const [openAddNewMemberModal, setOpenAddNewMemberModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checkedl, setCheckedl] = useState(false);
  const [language, setlanguage] = useState("");
  const [note, setnote] = useState("");
  const [track, settrack] = useState("");
  const languages = [
    "English",
    "Urdu",
    "Spanish",
    "French",
    "German",
    "Portuguese",
    "Spanish",
  ];
  const [editmodal, seteditmodal] = useState(false);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    setMessage(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
    setMessage2(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
    setMessage3(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
    setMessage4(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange5 = (event) => {
    setChecked5(event.target.checked);
    setd_city(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange6 = (event) => {
    setChecked6(event.target.checked);
    setModel(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange7 = (event) => {
    const isChecked = event.target.checked;
    setChecked7(isChecked);
    if (isChecked) {
      const formattedDate = startDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      setblockLList(`Blocked Till ${formattedDate}`);
    } else {
      setblockLList("");
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    if (checked7) {
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      setblockLList(`Blocked Till ${formattedDate}`);
    }
  };

  const handleCheckboxChange8 = (event) => {
    setChecked8(event.target.checked);
    setdinner(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChange9 = (event) => {
    setChecked9(event.target.checked);
    setelite(event.target.checked ? "True" : "False");
  };
  const handleCheckboxChangel = (event) => {
    setCheckedl(event.target.checked);
    settrack(event.target.checked ? "True" : "False");
  };

  //For add/edit modal
  const resetAll = () => {
    setChecked(false);
    setChecked2(false);
    setChecked3(false);
    setChecked4(false);
    setChecked5(false);
    setChecked6(false);
    setChecked7(false);
    setChecked8(false);
    setChecked9(false);
    setCheckedl(false);
    setlanguage("");
    setnote("");
    settrack("");
    setStartDate(null);
    setMessage("");
    setMessage2("");
    setMessage3("");
    setMessage4("");
    setd_city("");
    setModel("");
    setblockLList("");
    setdinner("");
    setelite("");
    setCity("");
    setInstaProfile("");
    setName("");
    setSelectedNewClientImage(null);
  };

  const handleOpenAddNewMemberModal = () => {
    setOpenAddNewMemberModal(true);
  };

  const handleClosecancelAddNewClientModal = async () => {
    setOpenAddNewMemberModal(false);
    resetAll();
  };

  const handleCloseAddNewClientModal = async () => {
    console.log("closeEdit function called"); // Add this for debugging

    console.log("Instagram Profile:", instaProfile);
    console.log("Name:", name);
    console.log("Send Message:", message);
    console.log("Reply Message:", message2);
    console.log("First Choice:", message3);
    console.log("Backup Guest:", message4);
    console.log("Story City:", city);
    console.log("Dinner City:", d_city);
    console.log("Non Model:", non_model);
    console.log("Block List:", blocklist);
    console.log("Set Dinner Circle:", dinner);
    console.log("Elite Circle:", elite);
    console.log("Selected Language:", language);
    console.log("Write Note:", note);
    console.log("Tracked Following:", track);

    try {
      const data = qs.stringify({
        model_name: name || "", // Keep empty if not set
        instagram_profile: instaProfile || "", // Keep empty if not set
        send_message: message || "False", // Default to false if not set
        reply_message: message2 || "False", // Default to false if not set
        first_choice: message3 || "False", // Default to false if not set
        backup_guest: message4 || "False", // Default to false if not set
        Story_City: city || "", // Keep empty if not set
        Block_list: blocklist || "", // Default to false if not set
        non_model: non_model || "False", // Default to false if not set
        dinner_only: d_city || "False", // Keep empty if not set
        select_dinner_circle: dinner || "False", // Default to false if not set
        elite_circle: elite || "False", // Default to false if not set
        language: language || "English", // Keep empty if not set
        note: note || "", // Keep empty if not set
        tracked_following: track || "False", // Default to false if not set
      });

      console.log("Post Request Data : ", data);
      const response = await INSTA_PROFILE_LIST.addTalentPool(data);

      if (response) {
        console.log("Client added successfully:", response);
        handleClosecancelAddNewClientModal();
      } else {
        console.error("Failed to add client.");
      }
    } catch (error) {
      console.error("Error adding client:", error);
    }

    setOpenAddNewMemberModal(false);
    resetAll();
    setJustForReload(!justForReload);
  };

  const handleNewClientImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedNewClientImage(URL.createObjectURL(e.target.files[0]));
    }
    console.log("Picture URL : ", selectedNewClientImage);
  };

  useEffect(() => {
    setSelectedFilters(["all"]);
    const fetchCitiesList = async () => {
      const data = await CITIES_LIST.getCitiesList();
      // debugger;
      if (data) {
        // console.log(data);
        setCitiesList(data.cities);
      }
    };
    const fetchAgenciesList = async () => {
      const data = await AGENCIES_LIST.getAgenciesList();
      // debugger;
      if (data) {
        // console.log(data);
        const filteredAgencies = data.agencies.filter(
          (agency) => agency !== null
        );
        setAgenciesList(filteredAgencies);
      }
    };
    const fetchProfileList = async () => {
      // debugger;
      let data;
      setIsDataLoading(true);
      // Fetch filtered profile list based on selected city
      data = await INSTA_PROFILE_LIST.getFilteredProfileList(
        "",
        "",
        "",
        "",
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        page
      );

      if (data) {
        console.log(data);
        setRowsNotForFiltering(data.PROFILES);
        setRows(data.PROFILES);
        setTotalPages(data.TOTAL_PAGES);
        setTotalProfiles(data.PROFILES?.length);

        // const filteredAgencies = data.AGENCIES_FILTER.filter(
        //   (agency) => agency !== null
        // );
        // setAgenciesList(filteredAgencies);
        // setCitiesList(data.CITY_FILTER);
        setIsDataLoading(false);

        // Transform CITY_FILTER to array of objects with label property
        // setCitiesList(data.CITY_FILTER?.map((city) => ({ label: city })) || []);
      } else {
        setIsDataLoading(false);
      }
    };

    fetchProfileList();

    fetchCitiesList();
    fetchAgenciesList();
    // setRows(data);
    // debugger;
    // const countries = data.map((item) => item.localtag_story_city);
    // const citiesListSet = new Set(countries);
    // setCitiesList(Array.from(citiesListSet));
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCityChange = (event, newValue) => {
    // debugger;
    setSelectedCity(newValue);
    filterRows(
      selectedFilters,
      searchTermForRequest,
      newValue,
      selectedAgency,
      selectedCountry
    );
  };
  const handleAgencyChange = (event, newValue) => {
    setSelectedAgency(newValue);
    filterRows(
      selectedFilters,
      searchTermForRequest,
      selectedCity,
      newValue,
      selectedCountry
    );
  };
  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
    filterRows(
      selectedFilters,
      searchTermForRequest,
      selectedCity,
      selectedAgency,
      newValue
    );
  };

  const handleFilter = (filter) => {
    if (filter === "all") {
      setSelectedFilters(["all"]);
      filterRows(["all"], searchTermForRequest, selectedCountry);
    } else {
      setSelectedFilters((prevFilters) => {
        const newFilters = prevFilters.includes(filter)
          ? prevFilters.filter((f) => f !== filter)
          : [...prevFilters.filter((f) => f !== "all"), filter];
        filterRows(newFilters, searchTermForRequest, selectedCountry);
        return newFilters;
      });
    }
  };

  const filterRows = (filters, search, city, agency, country) => {
    // debugger;
    let filteredRows = rowsNotForFiltering;

    // Filter based on STATUS
    // Only proceed with filtering by STATUS if filters doesn't include "all"
    if (!filters.includes("all")) {
      filteredRows = filteredRows.filter((row) => {
        if (row.STATUS) {
          // Split the row.STATUS into an array
          const statusArray = row.STATUS.split(", ").map((status) =>
            status.trim()
          );

          // Check if all filters are present in the statusArray
          return filters.every((filter) => statusArray.includes(filter));
        } else {
          return false;
        }
      });
    }
    // if (!filters.includes("all")) {
    //   filteredRows = filteredRows.filter((row) => {
    //     if (row.STATUS) {
    //       // Split STATUS into an array and check if any status is included in filters
    //       const statusArray = row.STATUS.split(",").map((status) =>
    //         status.trim()
    //       );
    //       return statusArray.some((status) => filters.includes(status));
    //     }
    //     // Return false if STATUS is null
    //     return false;
    //   });
    // }

    // Filter based on city
    if (city) {
      filteredRows = filteredRows.filter((row) =>
        row.TALENTPOOL_STORY_CITY
          ? row.TALENTPOOL_STORY_CITY.toLowerCase().includes(
              city.trim().toLowerCase()
            )
          : false
      );
    }

    // Filter based on agency
    if (agency) {
      filteredRows = filteredRows.filter((row) =>
        row.TALENTPOOL_AGENCY_NAME
          ? row.TALENTPOOL_AGENCY_NAME.toLowerCase().includes(
              agency.trim().toLowerCase()
            )
          : false
      );
    }

    // Filter based on country
    if (country) {
      filteredRows = filteredRows.filter((row) =>
        row.TALENTPOOL_STORY_COUNTRY
          ? row.TALENTPOOL_STORY_COUNTRY.toLowerCase().includes(
              country.trim().toLowerCase()
            )
          : false
      );
    }

    // Filter based on search term
    if (search) {
      filteredRows = filteredRows.filter((row) => {
        const rowData = Object.values(row).reduce((acc, value) => {
          // Handle if value is null or undefined
          if (value === null || value === undefined) {
            return acc;
          }

          if (typeof value === "object") {
            acc += Object.values(value).join(" ").toLowerCase();
          } else {
            acc += String(value).toLowerCase();
          }
          return acc;
        }, "");

        return rowData.includes(search.toLowerCase());
      });
    }

    // Update the state with the filtered rows
    setTotalProfiles(filteredRows?.length);
    setRows(filteredRows);
  };

  const clearFilters = () => {
    setSelectedFilters(["all"]);
    handleFilter("all");
    setSelectedCity(null);
    setSearchTerm("");
    setSearchTermForRequest("");
    // setRows(data);
  };

  const handleRowClick = (id) => {
    navigate(`/talentpool/profile/${id}`); // This will navigate to the desired page, passing the id in the URL
  };

  const handleEdit = (id) => {
    setProfileToEditID(id);
    seteditmodal(true);
    // navigate(`/talentpool/edit/${id}`);
  };

  const closeEdit = (id) => {
    seteditmodal(false);
    resetAll();
    setJustForReload(!justForReload);
  };
  const handleDelete = (id) => {};

  const Row = ({ index, style }) => {
    const row = rows[index];

    // Define color and background color arrays
    const statusColorMap = {
      OPEN_CHAT: { color: "#F19941", background: "#FFFBE9" },
      NON_MODEL: { color: "#2AAB4C", background: "#F7FFF5" },
      ELITE_CIRCLE: { color: "#0F87C3", background: "#F5FDFF" },
      DINNER_CIRCLE: { color: "#4150F1", background: "#F5F8FF" },
      TRAVEL_PLANNER: { color: "#6D41F1", background: "#F7EDFF" },
      DINNER_ONLY: { color: "#EE41F1", background: "#FDF5FF" },
      FIRST_CHOICE: { color: "#F1416C", background: "#FFF5F8" },
      BACKUP_GUEST: { color: "#7239EA", background: "#F8F5FF" },
      TRACKED_FOLLOWING: { color: "#26AFA4", background: "#F5FFF9" },
    };

    // Get the first status from the row
    const firstStatus = row.STATUS ? row.STATUS.split(",")[0].trim() : null;

    // Retrieve the color based on the status
    const statusColors =
      firstStatus && statusColorMap[firstStatus]
        ? statusColorMap[firstStatus]
        : { background: "#F5FFF9", color: "#26AFA4" }; // Default color if no match

    return (
      <TableRow
        key={row.TALENTPOOL_PROFILE_USERNAME}
        style={{
          ...style,
          display: "flex",
          width: "100%",
          boxSizing: "border-box",
          background: index % 2 ? "#F2F6FA" : "#FFF",
        }}
      >
        <TableCell style={{ flex: 2 }}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={row.TALENTPOOL_PROFILE_NAME}
              src={row.TALENTPOOL_PROFILE_PICTURE_URL}
              onClick={() => handleRowClick(row.ID)}
              sx={{ cursor: "pointer" }}
            />
            <Box>
              <Typography variant="h5" paddingLeft={2}>
                {row.TALENTPOOL_PROFILE_NAME}
              </Typography>
              <Typography paddingLeft={2} display="flex">
                <Typography color="#0878FF">
                  {row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT > 1000
                    ? `${row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT / 1000}k`
                    : row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT}
                </Typography>
                <Link
                  href={row.TALENTPOOL_PROFILE_URL}
                  underline="hover"
                  color="#B5B5C3"
                  paddingLeft={1}
                >
                  Instagram Link
                </Link>
              </Typography>
            </Box>
          </Box>
        </TableCell>

        {/* Status Cell with alternating colors */}
        <TableCell style={{ flex: 1 }}>
          {firstStatus && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  background: statusColors.background,
                  color: statusColors.color,
                  padding: "10px",
                  width: "fit-content",
                  // borderRadius: "30px",
                  // border: "1.0px solid black",
                  fontWeight: "600",
                }}
              >
                {firstStatus}
              </Box>
            </Box>
          )}
        </TableCell>

        <TableCell style={{ flex: 1, fontWeight: "650" }}>
          {row.TALENTPOOL_STORY_CITY ? row.TALENTPOOL_STORY_CITY : "NA"}
        </TableCell>
        <TableCell style={{ flex: 1, fontWeight: "650" }}>
          {row.TALENTPOOL_PROFILE_CATEGORY
            ? row.TALENTPOOL_PROFILE_CATEGORY
            : "NA"}
        </TableCell>
        <TableCell style={{ flex: 1, fontWeight: "650" }}>
          {row.TALENTPOOL_PROFILE_IS_PRIVATE ? "True" : "False"}
        </TableCell>
        <TableCell style={{ flex: 1 }}>
          <IconButton aria-label="edit" onClick={() => handleEdit(row.ID)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete(row.ID)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper
      sx={{
        margin: "10px 20px 10px 10px",
        overflow: "hidden",
        height: "88%",
        width: "80vw",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "30px 15px 7px 15px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "block" }}>
          <Typography fontWeight="bold">Talent Pool</Typography>
          <Typography color="#B5B5C3">
            Avg. {avgNewProfiles} new profile added per day
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* <Typography color="#7E8299">City</Typography>
            <FormControl>
              <Select
                labelId="city-select-label"
                id="city-select"
                value={selectedCity}
                onChange={handleCityChange}
                displayEmpty
                sx={{
                  border: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
              >
                <MenuItem value="">
                  <em>--City--</em>
                </MenuItem>
                {citiesList.map((city, index) => (
                  <MenuItem key={index} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          <Autocomplete
            value={selectedCity}
            onChange={handleCityChange}
            disablePortal
            id="city-combo-box"
            options={citiesList}
            sx={{
              border: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            }}
            renderInput={(params) => <TextField {...params} label="City" />}
          />
          <Autocomplete
            value={selectedAgency}
            onChange={handleAgencyChange}
            disablePortal
            id="agency-combo-box"
            options={agenciesList}
            sx={{
              border: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            }}
            renderInput={(params) => <TextField {...params} label="Agency" />}
          />
          <Autocomplete
            value={selectedCountry}
            onChange={handleCountryChange}
            disablePortal
            id="country-combo-box"
            options={allCountriesList.countries}
            sx={{
              border: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            }}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
          <TextField
            label="Search"
            size="small"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              // filterRows(selectedFilters, e.target.value, selectedCity);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (searchTerm == "") {
                  setSearchTermForRequest("");
                } else {
                  setSearchTermForRequest(searchTerm);
                  filterRows(
                    selectedFilters,
                    searchTerm,
                    selectedCity,
                    selectedAgency,
                    selectedCountry
                  );
                }
              }
            }}
          />
          <Button
            variant="outlined"
            startIcon={<AddOutlined />}
            sx={{
              background: "#F2F6FA",
              border: "1px solid #4C6FFF",
              // borderBottom: buttonSelected === 1 ? "3px solid #4C6FFF" : "",
              color: "#4C6FFF",
            }}
            onClick={handleOpenAddNewMemberModal}
          >
            New Member
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {filtersArray.map((filter) => (
            <Button
              key={filter.value}
              onClick={() => handleFilter(filter.value)}
              color="success"
              variant={
                selectedFilters.includes(filter.value)
                  ? "contained"
                  : "outlined"
              }
              endIcon={
                <InfoOutlined
                  sx={{ background: "#FFFFFF", borderRadius: "100%" }}
                />
              }
              sx={{
                border: "1.5px solid black",
                borderRight: "3.5px solid black",
                borderBottom: "3.5px solid black",
                borderRadius: "42px",
                background: selectedFilters.includes(filter.value)
                  ? "#C3E329"
                  : "#FFFFFF",
                color: "black",
              }}
            >
              {filter.label}
            </Button>
          ))}
        </div>
        <div style={{ padding: "10px 10px 10px 0", width: "165px" }}>
          <Button onClick={clearFilters} variant="outlined">
            Clear All
          </Button>
        </div>
      </div>
      <div
        style={{
          // overflowY: "scroll",
          maxHeight: "60%",
          maxWidth: "100%",
        }}
      >
        <TableContainer>
          <Table sx={{ overflowX: "scroll" }}>
            <TableHead>
              <TableRow
                style={{
                  display: "flex",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <TableCell style={{ flex: 2, fontWeight: "650" }}>
                  Models Profiles ({totalProfiles})
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  STATUS
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  CITY
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  CATEGORY
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  IS_PRIVATE
                </TableCell>
                <TableCell style={{ flex: 1, fontWeight: "650" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataLoading ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Loading...
                </div>
              ) : rows && rows?.length > 0 ? (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      height={400}
                      itemCount={rows?.length}
                      itemSize={80}
                      width={width}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No data
                </div>
                // <TableRow>
                //   <TableCell align="center">No data</TableCell>
                // </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      /> */}
      {/* Add New Member Modal */}

      <div>
        <Modal
          open={openAddNewMemberModal}
          onClose={handleClosecancelAddNewClientModal}
          aria-labelledby="modal-title-AddNewClientModal"
          aria-describedby="modal-description-AddNewClientModal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "1px solid #DDE2E5",
              borderRadius: "20px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h4">Add New Talent Pool Profile</Typography>
            <Box display="flex" marginTop="10px">
              <Box
                minWidth="20%"
                sx={{
                  width: "10vw",
                  height: "10vw",
                  border: "2px dashed gray",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-button"
                  type="file"
                  onChange={handleNewClientImageUpload}
                />
                <label htmlFor="upload-button">
                  {selectedNewClientImage ? (
                    <img
                      src={selectedNewClientImage}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <IconButton component="span">
                      <AddPhotoAlternateIcon style={{ fontSize: 80 }} />
                    </IconButton>
                  )}
                </label>
              </Box>
              <Box maxWidth="80%">
                <Grid container spacing={2} paddingLeft="44px">
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Instagram Profile"
                      name="instaProfile"
                      fullWidth
                      value={instaProfile}
                      onChange={(e) => setInstaProfile(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Name"
                      name="name"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: "12px", marginLeft: "0px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Send Message"
                        name="Send Message"
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Reply Message"
                        name="Reply Message"
                        fullWidth
                        value={message2}
                        onChange={(e) => setMessage(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked2}
                                onChange={handleCheckboxChange2}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: "12px", marginLeft: "0px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="First Choice"
                        name="Fisrt Choice"
                        fullWidth
                        value={message3}
                        onChange={(e) => setMessage(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked3}
                                onChange={handleCheckboxChange3}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Backup Guest"
                        name="Backup Guest"
                        fullWidth
                        value={message4}
                        onChange={(e) => setMessage(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked4}
                                onChange={handleCheckboxChange4}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Story City"
                      name="Story City"
                      fullWidth
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Dinner City"
                      name="Dinner City"
                      fullWidth
                      value={d_city}
                      onChange={(e) => setMessage(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Checkbox
                              checked={checked5}
                              onChange={handleCheckboxChange5}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: "12px", marginLeft: "0px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Non Model"
                        name="Non Model"
                        fullWidth
                        value={non_model}
                        onChange={(e) => setModel(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked6}
                                onChange={handleCheckboxChange6}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={4.5}>
                      <TextField
                        label="Block List"
                        name="Block List"
                        fullWidth
                        value={blocklist}
                        onChange={(e) => setblockLList(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked7}
                                onChange={handleCheckboxChange7}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      customInput={
                        <div
                          style={{
                            backgroundColor: "#6870fa",
                            width: "50px",
                            height: "44px",
                            marginTop: "18px",
                            borderRadius: "8px",
                            marginLeft: "6px",
                          }}
                        >
                          <FaCalendar
                            style={{
                              color: "white",
                              fontSize: "22px",
                              marginLeft: "13px",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                      }
                      showPopperArrow={false}
                    />
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: "12px", marginLeft: "0px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Select Dinner Circle"
                        name="Set Dinner Circle"
                        value={dinner}
                        onChange={(e) => setdinner(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked8}
                                onChange={handleCheckboxChange8}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        label="Elite Circle"
                        name="Elite Circle"
                        value={elite}
                        onChange={(e) => setelite(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Checkbox
                                checked={checked9}
                                onChange={handleCheckboxChange9}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="language-select-label">
                        Select Language
                      </InputLabel>
                      <Select
                        labelId="language-select-label"
                        id="language-select-label"
                        name="language"
                        value={language}
                        label="Select Language"
                        onChange={(e) => setlanguage(e.target.value)}
                      >
                        {languages.map((language) => (
                          <MenuItem key={language} value={language}>
                            {language}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Write Note"
                      name="Write Note"
                      fullWidth
                      value={note}
                      onChange={(e) => setnote(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Tracked Following"
                      name="Tracked Following"
                      value={track}
                      onChange={(e) => settrack(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Checkbox
                              checked={checkedl}
                              onChange={handleCheckboxChangel}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} marginTop="1%" paddingLeft="44px">
                  <Grid item xs={12} sm={12} md={6}>
                    <Button
                      disableElevation
                      onClick={handleClosecancelAddNewClientModal}
                      sx={{
                        background: "#FFFFFF",
                        border: "1px solid #0D6EFD",
                        marginRight: "10px",
                        borderRadius: "4px",
                        textTransform: "none",
                        width: "100%",
                        color: "#0D6EFD",
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Button
                      disableElevation
                      onClick={handleCloseAddNewClientModal}
                      sx={{
                        background: "#0D6EFD",
                        border: "1px solid #0D6EFD",
                        marginRight: "10px",
                        borderRadius: "4px",
                        textTransform: "none",
                        width: "100%",
                        color: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#0256d9",
                          borderColor: "#0256d9",
                        },
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
      {/* Edit Button Modal */}
      <div>
        <Modal
          open={editmodal}
          onClose={closeEdit}
          aria-labelledby="modal-title-AddNewClientModal"
          aria-describedby="modal-description-AddNewClientModal"
        >
          <EditTalentPoolProfile ID={profileToEditID} closeEdit={closeEdit} />
        </Modal>
      </div>
    </Paper>
  );
};

export default TableComponent;
