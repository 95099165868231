import { Box, Paper, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { tokens } from "../../utils/theme.js";
import Statbox_totalAgencies from "../../components/Statbox_totalAgencies/index.js";
import Statbox_InitialMsgImpact from "../../components/Statbox_InitialMsgImpact/index.js";
import Statbox_InvitationResponseRate from "../../components/Statbox_InvitationResponseRate/index.js";
import Statbox_QueenLastWeek from "../../components/Statbox_QueenLastWeek/index.js";
import Statbox_NewFacesThisWeek from "../../components/Statbox_NewFacesThisWeek/index.js";
import Statbox_LifeTimeSavedByGESThisWeek from "../../components/Statbox_LifeTimeSavedByGESThisWeek/index.js";
import Statbox_TopCustomer from "../../components/Statbox_TopCustomer/index.js";
import Statbox_UpcomingEvents from "../../components/Statbox_UpcomingEvents/index.js";
import Statbox_AvgAgentsEarnings from "../../components/Statbox_AvgAgentsEarnings/index.js";
import Statbox_MembersAllOverTheWorld from "../../components/Statbox_MembersAllOverTheWorld/index.js";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  //'50vw'
}));

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box sx={{ flexGrow: 1, margin: "15px 25px 15px 15px", display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} minWidth="100%">
              <Grid item xs={12} sm={12} md={12}>
                {/* <Item style={{width:`${scaleWidth(831)}`}}> */}
                <Item>
                  <Statbox_totalAgencies />
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item>
                  <Statbox_NewFacesThisWeek />
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item>
                  <Statbox_LifeTimeSavedByGESThisWeek />
                </Item>
              </Grid>
              <Grid item xs={12} md={5}>
                <Item>
                  <Statbox_QueenLastWeek />
                </Item>
              </Grid>
              <Grid item xs={12} md={7}>
                <Item>
                  <Statbox_TopCustomer />
                </Item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Second Grid container, shown full width on mobile, half width on larger screens */}
            <Grid
              container
              spacing={2}
              minWidth="100%"
              marginLeft={{ xs: "0px", md: "0px" }}
            >
              <Grid item xs={12} md={6}>
                <Item>
                  <Statbox_InitialMsgImpact />
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item>
                  <Statbox_InvitationResponseRate />
                </Item>
              </Grid>
              <Grid item xs={12} md={12}>
                <Item>
                  <Statbox_UpcomingEvents />
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container spacing={2} minWidth="50%" marginLeft="0px">

        </Grid> */}
      </Box>
      <Box sx={{ flexGrow: 1, margin: "15px 25px 15px 15px", display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Item>
              <Statbox_AvgAgentsEarnings />
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item sx={{ padding: "0px" }}>
              {/* <HexbinMap /> */}
              {/* <Density2d /> */}
              <Statbox_MembersAllOverTheWorld />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;

{
  /* <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="140px"
      gap="20px"
      alignItems="start"
      margin="16px"
    >
      <Box gridColumn="span 8" display="grid">
        <Box
          backgroundColor={colors.grey[1000]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="10px"
        >
          <Statbox_totalAgencies />
        </Box>
        <Box
          backgroundColor={colors.grey[1000]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="10px"
        >
          <Statbox_totalAgencies />
        </Box>
      </Box>

      <Box gridColumn="span 4" display="grid">
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
          alignItems="start"
        >
          <Box
            backgroundColor={colors.grey[1000]}
            gridColumn="span 6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding="10px"
            height='395px'
          >
            <Statbox_InitialMsgImpact />
          </Box>
        </Box>
      </Box>
    </Box> */
}
