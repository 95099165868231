import React, { useState } from "react";
import { Box, Typography, useTheme, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { tokens } from "../../utils/theme";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ProgressBar from "@ramonak/react-progress-bar";

const Statbox_InvitationResponseRate = () => {
  const [totalApprovedText, setTotalApprovedText] = useState("2.1k");
  const [totalApproved, setTotalApproved] = useState("2.1");
  const [percentDeclined, setPercentDeclined] = useState(80);
  const [percentNoReplies, setPercentNoReplies] = useState(20);

  const TotalApprovedStateFormatter = (totalApproved) => {
    totalApproved > 1000
      ? setTotalApprovedText(totalApproved / 1000 + "k")
      : setTotalApprovedText(totalApproved);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Box className="card-header" padding="22.75px 0 6.5px 12px">
        <Box>
          <Box sx={{ flexGrow: 1}}>
            <Grid container spacing={2}>
              <Grid item xs={8} md={8}>
                <Box display="flex">
                  <Typography variant="h5" color={colors.grey[100]}>
                    Invitation Response Rate
                  </Typography>
                  <div>
                    <InfoIcon sx={{ marginLeft: "10px", color: "#4C6FFF" }} />
                  </div>
                </Box>
                <Typography variant="h6" color={colors.grey[500]}>
                  Social activities overview
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box>
                  <Typography variant="h1" color={colors.grey[100]}>
                    6.3k
                  </Typography>
                  <Typography variant="h6" color={colors.grey[500]}>
                    Total DMs Sent
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box className="card-content" display='grid' alignItems='end'>
        <Box sx={{ flexGrow: 1, margin: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              <Typography variant="h1">{totalApprovedText}</Typography>
              <Typography variant="h7" color={colors.grey[500]}>
                Total message sent
              </Typography>
            </Grid>
            <Grid item xs={7} md={7} alignSelf="center">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography variant="h7" color="textSecondary">
                  2024
                </Typography>
                <IconButton>
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <ProgressBar
                  completed={totalApproved}
                  maxCompleted="6.3"
                  customLabel=" "
                  height="6px"
                  bgColor="#7239EA"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, margin: "10px", borderTop:'dashed 1px #E1E3EA', paddingTop:'15px' }}>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              <Typography variant="h1">{percentDeclined}%</Typography>
              <Typography variant="h7" color={colors.grey[500]}>
                Replies
              </Typography>
            </Grid>
            <Grid item xs={7} md={7} alignSelf="center">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography variant="h7" color="textSecondary">
                  2024
                </Typography>
                <IconButton>
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <ProgressBar
                  completed={percentDeclined}
                  customLabel=" "
                  height="6px"
                  bgColor="#FFC700"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, margin: "10px", borderTop:'dashed 1px #E1E3EA', paddingTop:'15px' }}>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              <Typography variant="h1">{percentNoReplies}%</Typography>
              <Typography variant="h7" color={colors.grey[500]}>
                No Replies
              </Typography>
            </Grid>
            <Grid item xs={7} md={7} alignSelf="center">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography variant="h7" color="textSecondary">
                  2024
                </Typography>
                <IconButton>
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <ProgressBar
                  completed={percentNoReplies}
                  customLabel=" "
                  height="6px"
                  bgColor="#03A9F4"
                />
                {/* <BorderLinearProgress color="secondary" variant="determinate" value={80} /> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Statbox_InvitationResponseRate;
