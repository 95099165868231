import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  TextField,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Autocomplete,
  Box,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddOutlined, InfoOutlined } from "@mui/icons-material";

import INSTA_STORY from "../../client/instaStoryClient";

import allCountriesList from "../../data/countriesList.json";

const filtersArray = [
  { label: "All", value: "all" },
  { label: "Open Chat", value: "Open Chat" },
  { label: "Non Model", value: "Non Model" },
  { label: "Elite Circle", value: "Elite Circle" },
  { label: "Set Dinner Circle", value: "Set Dinner Circle" },
  { label: "Travel Planner", value: "Travel Planner" },
  { label: "Dinner Only", value: "Dinner Only" },
  { label: "Tracked Following", value: "Tracked Following" },
  { label: "In Town", value: "In Town" },
  { label: "First Choice", value: "First Choice" },
  { label: "Backup Guest", value: "Backup" },
  { label: "Blacklist", value: "Blacklist" },
  // Add more filters as needed
];

const TableComponent = ({ setMarkersData }) => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [totalProfiles, setTotalProfiles] = useState(39685);
  const [avgNewProfiles, setAvgNewProfiles] = useState(57);
  const [rows, setRows] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(["all"]);
  const [citiesList, setCitiesList] = useState([]);
  const [agenciesList, setAgenciesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermForRequest, setSearchTermForRequest] = useState(" ");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Create individual state variables for each filter
  const [allChecked, setAllChecked] = useState(1);
  const [openChatChecked, setOpenChatChecked] = useState(0);
  const [nonModelChecked, setNonModelChecked] = useState(0);
  const [eliteCircleChecked, setEliteCircleChecked] = useState(0);
  const [setDinnerCircleChecked, setSetDinnerCircleChecked] = useState(0);
  const [travelPlannerChecked, setTravelPlannerChecked] = useState(0);
  const [dinnerOnlyChecked, setDinnerOnlyChecked] = useState(0);
  const [trackedFollowingChecked, setTrackedFollowingChecked] = useState(0);
  const [inTownChecked, setInTownChecked] = useState(0);
  const [firstChoiceChecked, setFirstChoiceChecked] = useState(0);
  const [backupGuestChecked, setBackupGuestChecked] = useState(0);
  const [blacklistChecked, setBlacklistChecked] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedFilters(["all"]);
  }, []);

  useEffect(() => {
    const fetchProfileList = async () => {
      let data;

      if (
        searchTermForRequest ||
        selectedCountry ||
        selectedCity ||
        selectedAgency ||
        allChecked ||
        openChatChecked ||
        nonModelChecked ||
        eliteCircleChecked ||
        setDinnerCircleChecked ||
        trackedFollowingChecked ||
        firstChoiceChecked ||
        backupGuestChecked ||
        blacklistChecked ||
        page
      ) {
        setIsDataLoading(true);
        // Fetch filtered profile list based on selected city
        data = await INSTA_STORY.getFilteredProfileList(
          searchTermForRequest,
          selectedCountry,
          selectedCity,
          selectedAgency,
          allChecked,
          openChatChecked,
          nonModelChecked,
          eliteCircleChecked,
          setDinnerCircleChecked,
          trackedFollowingChecked,
          firstChoiceChecked,
          backupGuestChecked,
          blacklistChecked,
          page
        );
      } else {
        setIsDataLoading(true);
        // Fetch full profile list
        data = await INSTA_STORY.getProfileList(page);
      }

      if (data) {
        console.log(data);
        setRows(data.PROFILES);
        setTotalPages(data.TOTAL_PAGES);
        setTotalProfiles(data.TOTAL_PROFILES);

        const filteredCities = data.CITY_FILTER.filter((city) => city !== null);
        setCitiesList(filteredCities);

        const latLongArray = data.PROFILES.filter(
          (profile) => profile.LAT && profile.LONG
        ) // Filter out null or undefined values
          .map((profile) => ({
            location: profile.TALENTPOOL_STORY_CITY,
            geoCode: [parseFloat(profile.LAT), parseFloat(profile.LONG)], // Convert strings to floats
          }));

        setMarkersData(latLongArray);
        // console.log("LatlongArray : ", latLongArray);
        // const filteredAgencies = data.AGENCIES_FILTER.filter(
        //   (agency) => agency !== null
        // );
        // setAgenciesList(filteredAgencies);
        // setCitiesList(data.CITY_FILTER);
        setIsDataLoading(false);

        // Transform CITY_FILTER to array of objects with label property
        // setCitiesList(data.CITY_FILTER?.map((city) => ({ label: city })) || []);
      } else {
        setIsDataLoading(false);
      }
    };

    fetchProfileList();
  }, [
    searchTermForRequest,
    selectedCountry,
    selectedCity,
    selectedAgency,
    allChecked,
    openChatChecked,
    nonModelChecked,
    eliteCircleChecked,
    setDinnerCircleChecked,
    trackedFollowingChecked,
    firstChoiceChecked,
    backupGuestChecked,
    blacklistChecked,
    page,
  ]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleCityChange = (event, newValue) => {
    setSelectedCity(newValue);
  };
  const handleAgencyChange = (event, newValue) => {
    setSelectedAgency(newValue);
  };
  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
  };

  const handleFilter = (filter) => {
    if (filter === "all") {
      setSelectedFilters(["all"]);
      setAllChecked(1);
      setOpenChatChecked(0);
      setNonModelChecked(0);
      setEliteCircleChecked(0);
      setSetDinnerCircleChecked(0);
      setTravelPlannerChecked(0);
      setDinnerOnlyChecked(0);
      setTrackedFollowingChecked(0);
      setInTownChecked(0);
      setFirstChoiceChecked(0);
      setBackupGuestChecked(0);
      setBlacklistChecked(0);
      // filterRows(["all"], searchTerm, selectedCity);
    } else {
      setSelectedFilters((prevFilters) => {
        const newFilters = prevFilters.includes(filter)
          ? prevFilters.filter((f) => f !== filter)
          : [...prevFilters.filter((f) => f !== "all"), filter];
        // filterRows(newFilters, searchTerm, selectedCity);
        return newFilters;
      });
      setAllChecked(0);
      if (filter === "Open Chat") {
        setOpenChatChecked(1);
      } else if (filter === "Non Model") {
        setNonModelChecked(1);
      } else if (filter === "Elite Circle") {
        setEliteCircleChecked(1);
      } else if (filter === "Set Dinner Circle") {
        setSetDinnerCircleChecked(1);
      } else if (filter === "Travel Planner") {
        setTravelPlannerChecked(1);
      } else if (filter === "Dinner Only") {
        setDinnerOnlyChecked(1);
      } else if (filter === "Tracked Following") {
        setTrackedFollowingChecked(1);
      } else if (filter === "In Town") {
        setInTownChecked(1);
      } else if (filter === "First Choice") {
        setFirstChoiceChecked(1);
      } else if (filter === "Backup") {
        setBackupGuestChecked(1);
      } else if (filter === "Blacklist") {
        setBlacklistChecked(1);
      } else {
      }
    }
  };

  const clearFilters = () => {
    setSelectedFilters(["all"]);
    handleFilter("all");
    setSelectedCity(null);
    setSearchTerm(" ");
    setSearchTermForRequest(" ");
    // setRows(data);
  };

  return (
    <Paper
      sx={{
        margin: "10px 20px 10px 10px",
        overflow: "hidden",
        height: "88%",
        maxWidth: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "30px 15px 7px 15px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "block" }}>
          <Typography fontWeight="bold">Story Location</Typography>
          {/* <Typography color="#B5B5C3">
            Avg. {avgNewProfiles} new profile added per day
          </Typography> */}
          <Typography color="#B5B5C3">
            Total {totalProfiles} Profiles
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Autocomplete
            value={selectedCity}
            onChange={handleCityChange}
            disablePortal
            id="city-combo-box"
            options={citiesList}
            sx={{
              border: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            }}
            renderInput={(params) => <TextField {...params} label="City" />}
          />
          <Autocomplete
            value={selectedCountry}
            onChange={handleCountryChange}
            disablePortal
            id="country-combo-box"
            options={allCountriesList.countries}
            sx={{
              border: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            }}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
          <TextField
            label="Search"
            size="small"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              // filterRows(selectedFilters, e.target.value, selectedCity);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (searchTerm == "") {
                  setSearchTermForRequest(" ");
                } else {
                  setSearchTermForRequest(searchTerm);
                }
              }
            }}
          />
          {/* <Button
            variant="outlined"
            startIcon={<AddOutlined />}
            sx={{
              background: "#F2F6FA",
              border: "1px solid #4C6FFF",
              // borderBottom: buttonSelected === 1 ? "3px solid #4C6FFF" : "",
              color: "#4C6FFF",
            }}
            // onClick={handleButtonChange(1)}
          >
            New Member
          </Button> */}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {filtersArray.map((filter) => (
            <Button
              key={filter.value}
              onClick={() => handleFilter(filter.value)}
              color="success"
              variant={
                selectedFilters.includes(filter.value)
                  ? "contained"
                  : "outlined"
              }
              endIcon={
                <InfoOutlined
                  sx={{ background: "#FFFFFF", borderRadius: "100%" }}
                />
              }
              sx={{
                border: "1.5px solid black",
                borderRight: "3.5px solid black",
                borderBottom: "3.5px solid black",
                borderRadius: "42px",
                background: selectedFilters.includes(filter.value)
                  ? "#C3E329"
                  : "#FFFFFF",
                color: "black",
              }}
            >
              {filter.label}
            </Button>
          ))}
        </div>
        <div style={{ padding: "10px 10px 10px 0", width: "165px" }}>
          <Button onClick={clearFilters} variant="outlined">
            Clear All
          </Button>
        </div>
      </div>
      <div
        style={{
          maxHeight: "60%",
          maxWidth: "100%",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Total Profiles ({totalProfiles})</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Story City</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Co-ordinates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataLoading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : rows && rows.length > 0 ? (
                rows?.map((row) => (
                  <TableRow key={row.TALENTPOOL_PROFILE_USERNAME}>
                    <TableCell
                      sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          alt={row.TALENTPOOL_PROFILE_NAME}
                          src={row.TALENTPOOL_PROFILE_PICTURE_URL}
                        />
                        <Box>
                          <Typography variant="h5" paddingLeft={2}>
                            {row.TALENTPOOL_PROFILE_NAME}
                          </Typography>
                          <Typography paddingLeft={2} display="flex">
                            <Typography color="#0878FF">
                              {row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT > 1000
                                ? `${
                                    row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT /
                                    1000
                                  }k`
                                : row.TALENTPOOL_PROFILE_FOLLOWERS_COUNT}
                            </Typography>
                            <Link
                              href={row.TALENTPOOL_PROFILE_URL}
                              underline="hover"
                              color="#B5B5C3"
                              paddingLeft={1}
                            >
                              Instagram Link
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{row.STATUS ? row.STATUS : "NA"}</TableCell>
                    <TableCell>
                      {row.TALENTPOOL_STORY_CITY
                        ? row.TALENTPOOL_STORY_CITY
                        : "NA"}
                    </TableCell>
                    <TableCell>
                      {row.TALENTPOOL_STORY_COUNTRY
                        ? row.TALENTPOOL_STORY_COUNTRY
                        : "NA"}
                    </TableCell>
                    <TableCell>
                      {row.TALENTPOOL_STORY_LAST_UPDATED
                        ? new Date(
                            row.TALENTPOOL_STORY_LAST_UPDATED
                          ).toLocaleString()
                        : "NA"}
                    </TableCell>
                    <TableCell>
                      {row.LAT && row.LONG
                        ? `[${parseFloat(row.LAT).toFixed(2)} , ${parseFloat(
                            row.LONG
                          ).toFixed(2)}]`
                        : "NA"}
                    </TableCell>
                    {/* <TableCell>
                      <IconButton
                        aria-label="edit"
                        //   onClick={() => handleEdit(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        //   onClick={() => handleDelete(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      />
    </Paper>
  );
};

export default TableComponent;
