import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../utils/theme.js";
import { data } from "../../data/hexbinData.tsx";
import { Hexbin } from "../HexbinMap/HexbinMap.tsx";

const styles = {
  root: {
    width: "100%",
  },
  cardHeader: {
    display:'flex',
    alignItems:'center',
    padding: '10px 24px 10px 24px'
  },
  cardContent: {
    display: "block",
    minHeight: "407px",
    alignItems: "start",
    margin: "10px 0 0 0",
    backgroundColor: "#EEF0FA",
  },
  img: {
    width: "39px",
    height: "39px",
  },
};

const Statbox_MembersAllOverTheWorld = () => {
  const [totalMembersAllOverTheWorld, setTotalMembersAllOverTheWorld] =
    useState("320k");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const NumberFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  return (
    <Box>
      <Box className="card-header" sx={styles.cardHeader}>
        <Box display="flex" alignItems='center'>
          <img
            style={styles.img}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png"
          />
          <Box display='grid' paddingLeft='20px'>
            <Typography variant="h5" color={colors.grey[100]}>
              {totalMembersAllOverTheWorld}
            </Typography>

            <Typography variant="h7" color={colors.grey[500]}>
            Members all over the world
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        className="card-content"
        sx={styles.cardContent}
        backgroundColor="#EEF0FA"
      >
        <Hexbin data={data} width={400} height={400} />
      </Box>
    </Box>
  );
};

export default Statbox_MembersAllOverTheWorld;
