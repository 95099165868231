import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  TextField,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Box,
  Modal,
  Grid,
  InputLabel,
  Chip,
  OutlinedInput,
  Link,
  Autocomplete,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import qs from "qs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Default styles for the phone input
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa6";
import { AddOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import CITIES_LIST from "../../client/citiesClient";
import CLIENT_MANAGEMENT_LIST from "../../client/clientsManagementClient";

import EditClientManagementClientProfile from "../EditClientManagementClientProfile";

const Prefaces = ["Clubbing", "Beach club", "Dinner", "Trips"];
const SpenderCategories = ["Top Spender", "Casual Spender"];

const ClientManagement = () => {
  const [justForReload, setJustForReload] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [profileToEditID, setProfileToEditID] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsNotForFiltering, setRowsNotForFiltering] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRegisteredClients, setTotalRegisteredClients] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [club, setclub] = useState("");
  const [eventdate, seteventdate] = useState(null);
  const [datemsg, setdatemsg] = useState("");

  const [selectedNewClientImage, setSelectedNewClientImage] = useState(null);
  const [instaProfile, setInstaProfile] = useState("");
  const [name, setName] = useState("");
  const [spenderCategory, setSpenderCategory] = useState("");
  const [phone, setPhone] = useState("");
  const [totalSpend, setTotalSpend] = useState("");
  const [commissionPercent, setCommissionPercent] = useState("");
  const [city, setCity] = useState("");
  const [selectedPrefaces, setSelectedPrefaces] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [namesList, setNamesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedSpenderCategory, setSelectedSpenderCategory] = useState(null);

  const [totalSpend2, setTotalSpend2] = useState("");
  const [commissionPercent2, setCommissionPercent2] = useState("");

  const [openAddNewClientModal, setOpenAddNewClientModal] = useState(false);
  const [OpenAddClientDataModal, setOpenAddClientDataModal] = useState(false);
  const [openEditClientModal, setOpenEditClientModal] = useState(false);

  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleOpenAddNewClientModal = () => setOpenAddNewClientModal(true);
  const handleOpenAddClientDataModal = () => setOpenAddClientDataModal(true);
  const handleOpenEditClientModal = () => setOpenEditClientModal(true);

  const handleCloseEditClientModal = () => {
    setOpenEditClientModal(false);
    setJustForReload(!justForReload);
  };
  const handleCloseAddNewClientModal = () => {
    setOpenAddNewClientModal(false);
    setJustForReload(!justForReload);
  };
  const handleCloseAddClientDataModal = () => {
    setOpenAddClientDataModal(false);
    seteventdate(null);
    setclub("");
    seteventdate(null);
    setdatemsg("");
    setTotalSpend2("");
    setCommissionPercent2("");
    setSearch("");
    setSelectedResult(null);
    setResults([]);
    setShowResults(false);
    setJustForReload(!justForReload);
  };

  const handleCityChange = (event, newValue) => {
    // debugger;
    setSelectedCity(newValue);
    filterRows(searchTerm, newValue, selectedName, selectedSpenderCategory);
  };
  const handleClientNameChange = (event, newValue) => {
    setSelectedName(newValue);
    filterRows(searchTerm, selectedCity, newValue, selectedSpenderCategory);
  };
  const handleSpenderCategoryChange = (event, newValue) => {
    setSelectedSpenderCategory(newValue);
    filterRows(searchTerm, selectedCity, selectedName, newValue);
  };

  const filterRows = (search, city, name, spenderCategory) => {
    debugger;
    let filteredRows = rowsNotForFiltering;

    // Filter based on city
    if (city) {
      filteredRows = filteredRows.filter((row) =>
        row.CLIENT_CITY
          ? row.CLIENT_CITY.toLowerCase().includes(city.trim().toLowerCase())
          : false
      );
    }

    // Filter based on name
    if (name) {
      filteredRows = filteredRows.filter((row) =>
        row.CLIENT_NAME
          ? row.CLIENT_NAME.toLowerCase().includes(name.trim().toLowerCase())
          : false
      );
    }

    // Filter based on spender category
    if (spenderCategory) {
      filteredRows = filteredRows.filter((row) =>
        row.CLIENT_SPENDER_CATEGORY
          ? row.CLIENT_SPENDER_CATEGORY.toLowerCase().includes(
              spenderCategory.trim().toLowerCase()
            )
          : false
      );
    }

    // Filter based on search term
    if (search) {
      filteredRows = filteredRows.filter((row) => {
        const rowData = Object.values(row).reduce((acc, value) => {
          if (value === null || value === undefined) {
            return acc;
          }

          if (typeof value === "object") {
            acc += Object.values(value).join(" ").toLowerCase();
          } else {
            acc += String(value).toLowerCase();
          }
          return acc;
        }, "");

        return rowData.includes(search.toLowerCase());
      });
    }

    // Update the state with the filtered rows
    setTotalRegisteredClients(filteredRows.length);
    setRows(filteredRows);
  };

  const handleEditClient = (ID) => {
    setProfileToEditID(ID);
    handleOpenEditClientModal();
  };
  const handleDateChange = (date) => {
    seteventdate(date);

    if (date) {
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      setdatemsg(formattedDate);
    } else {
      setdatemsg("");
    }
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);

    if (e.key === "Enter") {
      e.preventDefault();

      if (e.target.value.trim().length > 0) {
        const fetchedResults = fetchSearchResults(e.target.value.trim());
        setResults(fetchedResults);
        setShowResults(true);
      } else {
        setShowResults(false);
      }
    }
  };

  function fetchSearchResults(query) {
    const allResults = [
      {
        name: "Kin Simmons",
        instagram_link: "instagram link",
        contact: "+12341234",
        detail: "Beach Club",
      },
      {
        name: "Kim Wood",
        instagram_link: "instagram link",
        contact: "+12341234",
        detail: "Trips",
      },
      {
        name: "Gold Kin",
        instagram_link: "instagram link",
        contact: "+12341234",
        detail: "club",
      },
    ];

    const filteredResults = allResults.filter(
      (result) =>
        result.name.toLowerCase().includes(query.toLowerCase()) ||
        result.detail.toLowerCase().includes(query.toLowerCase())
    );

    return filteredResults;
  }

  const handleNewClientImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedNewClientImage(URL.createObjectURL(e.target.files[0]));
    }
    console.log("Picture URL : ", selectedNewClientImage);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (!selectedPrefaces.includes(value)) {
      setSelectedPrefaces([...selectedPrefaces, value]);
    }

    // console.log("Selected Prefaces", selectedPrefaces.join(", "));
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setSelectedPrefaces((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  };

  const handleAddClient = async () => {
    try {
      const data = qs.stringify({
        client_name: name || "", // Keep empty if not set
        client_instagram_profile: instaProfile || "", // Keep empty if not set
        client_spender_category: spenderCategory || "Top Spender", // Default to "Top Spender" if not set,
        client_phone_number: phone || "", // Keep empty if not set,
        client_total_spend: totalSpend || "0", // Default to 0 if not set,
        client_commission: commissionPercent || "0", // Default to 0 if not set,
        client_city: city || "", // Keep empty if not set,
        client_preferences: selectedPrefaces.join(", ") || "", // Keep empty if not set,
      });

      console.log("Post Request Data : ", data);
      const response = await CLIENT_MANAGEMENT_LIST.addClient(data);

      if (response) {
        console.log("Client added successfully:", response);
        handleCloseAddNewClientModal();
      } else {
        console.error("Failed to add client.");
      }
    } catch (error) {
      console.error("Error adding client:", error);
    }

    // const config = {
    //   method: "post",
    //   maxBodyLength: Infinity,
    //   url: "http://127.0.0.1:8000/api/clients/add/",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   },
    //   data: data,
    // };

    // axios
    //   .request(config)
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data));
    //     // Handle success (e.g., close modal, show success message)
    //     handleCloseAddNewClientModal();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     // Handle error (e.g., show error message)
    //   });
  };
  const handleAddClientData = async () => {
    try {
      /*if (!eventdate || !club || !totalSpend2 || !commissionPercent2) {
        console.error("All fields are required.");
        return;
      }*/

      const newClientData = {
        name: selectedResult.name,
        insta_link: selectedResult.instagram_link,
        contact: selectedResult.contact,
        detail: selectedResult.detail,
        club: club,
        eventDate: eventdate,
        dateMsg: datemsg,
        totalSpend: totalSpend2,
        commissionPercent: commissionPercent2,
      };

      console.log("New Client Data: ", newClientData);

      handleCloseAddClientDataModal();
    } catch (error) {
      console.error("Failed to add client:", error);
    }
  };

  useEffect(() => {
    const fetchClientsList = async () => {
      // debugger
      setIsDataLoading(true);
      let data = await CLIENT_MANAGEMENT_LIST.getClientsList(page, searchTerm);
      if (data) {
        // console.log(data);
        setIsDataLoading(false);
        setRows(data.PROFILES);
        setRowsNotForFiltering(data.PROFILES);
        setTotalPages(data.TOTAL_PAGES);
        setTotalRegisteredClients(data.PROFILES?.length);
      } else {
        setIsDataLoading(false);
      }
    };

    fetchClientsList();
  }, [page, justForReload]);

  useEffect(() => {
    const fetchCitiesList = async () => {
      const data = await CITIES_LIST.getCitiesListForClientManagementPage();
      // debugger;
      if (data) {
        console.log("Cities list for filter: ", data.cities);
        setCitiesList(data.cities);
      }
    };
    const fetchNamesList = async () => {
      const data = await CLIENT_MANAGEMENT_LIST.getClientsNamesList();
      // debugger;
      if (data) {
        console.log("Names list for filter: ", data.names);
        const filteredNames = data.names.filter((name) => name !== null);
        setNamesList(filteredNames);
      }
    };

    fetchCitiesList();
    fetchNamesList();
  }, [justForReload]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewClientData((prevModel) => ({
  //     ...prevModel,
  //     [name]: value,
  //   }));
  // };

  return (
    <Paper
      sx={{
        margin: "10px 20px 10px 10px",
        overflow: "hidden",
      }}
    >
      {/* header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <Typography variant="h5" paddingX="10px" fontWeight="bold">
          Client Management
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* City Dropdown */}
            <Autocomplete
              value={selectedCity}
              onChange={handleCityChange}
              disablePortal
              id="city-combo-box"
              options={citiesList}
              sx={{
                border: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => <TextField {...params} label="City" />}
            />

            {/* Client's Name Dropdown */}
            <Autocomplete
              value={selectedName}
              onChange={handleClientNameChange}
              disablePortal
              id="client-name-combo-box"
              options={namesList}
              sx={{
                border: "none",
                width: "130px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => (
                <TextField {...params} label="Client's Name" />
              )}
            />

            {/* Spender Category Dropdown */}
            <Autocomplete
              value={selectedSpenderCategory}
              onChange={handleSpenderCategoryChange}
              disablePortal
              id="spender-category-combo-box"
              options={SpenderCategories}
              sx={{
                border: "none",
                width: "160px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              renderInput={(params) => (
                <TextField {...params} label="Spender Category" />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              alignItems: "center",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              placeholder="Search"
              size="small"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  filterRows(
                    searchTerm,
                    selectedCity,
                    selectedName,
                    selectedSpenderCategory
                  );
                }
              }}
            />
            <Button
              variant="outlined"
              startIcon={<AddOutlined />}
              sx={{
                background: "#F2F6FA",
                border: "1px solid #4C6FFF",
                // borderBottom: buttonSelected === 1 ? "3px solid #4C6FFF" : "",
                color: "#4C6FFF",
                marginX: "10px",
              }}
              onClick={handleOpenAddClientDataModal}
            >
              Add Client Data
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                background: "#4C6FFF",
                border: "1px solid #4C6FFF",
                // color: "#4C6FFF",
                marginRight: "10px",
              }}
              onClick={handleOpenAddNewClientModal}
            >
              Add New Client
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Table */}

      <div
        style={{
          overflowY: "hidden",
          // maxHeight: "60%",
          // maxWidth: "100%",
        }}
      >
        <TableContainer style={{ maxHeight: "77vh" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  REGISTERED CLIENTS ({totalRegisteredClients})
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  AMOUNT SPEND
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  COMMISSION
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  SPENDER CATEGORY
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  LAST EVENTS ADDED
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#fff", fontWeight: "650" }}
                >
                  -----------
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {console.log(rows, data, 'rows data')} */}
              {isDataLoading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : rows && rows?.length > 0 ? (
                rows?.map((row, index) => (
                  <TableRow
                    key={row.ID}
                    sx={{
                      background: index % 2 ? "#F2F6FA" : "#FFF",
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{ height: "56px", width: "56px" }}
                          alt={row.CLIENT_NAME}
                          src="https://s3-alpha-sig.figma.com/img/5744/9ba8/7714695b75ed83e945a8387f0113a115?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AUalZBVaJVOhxRQ3gXnIacp9TFlQtvPA7HHIYP8xGi9JId8TefQqa5Whz4~9QuMyfnXzF1HvTkqTXKqGH~P-GN1mIXWzu32calo8jy~aP3fp2vWtNl7HHlWgp-2ey6RUI4rkLYp~7qgDZdMXm7VfwoV6V2-Psi5noxYERhbr8NlyNbHdMQrO6aLWQFaAFLEIKM1LooIe7j87oa261DQAl28X9MbZpqG4GrnCRmYDaufqD7DsBoeai~1d7EKHIbk7v3zd6lCd4Kq22ZE7V93A3esQEoYu3VROCTNFwGjPq4t0KUkfhQo26UHbJOZasggHlwaYG2NFnLYToAr2FhcX5A__"
                          variant="rounded"
                        />
                        <Box>
                          <Typography variant="h5" paddingLeft={2}>
                            {row.CLIENT_NAME}
                          </Typography>
                          <Typography paddingLeft={2} display="flex">
                            Total amount spend:
                            <Typography paddingLeft={1} color="#4AAE8C">
                              {row.CLIENT_TOTAL_SPEND}
                            </Typography>
                          </Typography>
                          <Typography paddingLeft={2} display="flex">
                            Contact:
                            <Typography paddingLeft={1} color="#167FFE">
                              {row.CLIENT_PHONE_NUMBER}
                            </Typography>
                          </Typography>
                          <Link
                            href={row.CLIENT_INSTAGRAM_PROFILE}
                            underline="hover"
                            color="#B5B5C3"
                            paddingLeft={2}
                          >
                            Instagram Link
                          </Link>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          background: "#D4FFE9",
                          color: "#4AAE8C",
                          padding: "10px",
                          width: "fit-content",
                        }}
                      >
                        {row.CLIENT_TOTAL_SPEND}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          background: "#FFFDF5",
                          color: "#F19941",
                          padding: "10px",
                          width: "fit-content",
                        }}
                      >
                        {row.CLIENT_COMMISSION ? row.CLIENT_COMMISSION : "NA"}
                      </Box>
                    </TableCell>
                    <TableCell>{row.CLIENT_SPENDER_CATEGORY}</TableCell>
                    <TableCell>
                      {/* {new Date(row.DATE_ADDED).toLocaleString()} */}
                      09-08-2024
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditClient(row.ID)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        // onClick={() => handleDeleteClient(row.ID)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      /> */}

      {/* Add New Client Modal */}

      <div>
        <Modal
          open={openAddNewClientModal}
          onClose={handleCloseAddNewClientModal}
          aria-labelledby="modal-title-AddNewClientModal"
          aria-describedby="modal-description-AddNewClientModal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "1px solid #DDE2E5",
              borderRadius: "20px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h4">Add New Client</Typography>
            <Box display="flex" marginTop="10px">
              <Box
                minWidth="20%"
                sx={{
                  width: "10vw",
                  height: "10vw",
                  border: "2px dashed gray",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-button"
                  type="file"
                  onChange={handleNewClientImageUpload}
                />
                <label htmlFor="upload-button">
                  {selectedNewClientImage ? (
                    <img
                      src={selectedNewClientImage}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <IconButton component="span">
                      <AddPhotoAlternateIcon style={{ fontSize: 80 }} />
                    </IconButton>
                  )}
                </label>
              </Box>
              <Box maxWidth="80%">
                <Grid container spacing={2} paddingLeft="44px">
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Instagram Profile"
                      name="instaProfile"
                      fullWidth
                      value={instaProfile}
                      onChange={(e) => setInstaProfile(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Name"
                      name="name"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="spender-category-select-label">
                        Spender Category
                      </InputLabel>
                      <Select
                        labelId="spender-category-select-label"
                        id="spender-category-select"
                        name="spenderCategory"
                        value={spenderCategory}
                        label="Spender Category"
                        onChange={(e) => setSpenderCategory(e.target.value)}
                      >
                        {SpenderCategories.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <PhoneInput
                      country={"pk"} // Default country
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      inputStyle={{
                        border: "none",
                        height: "100%",
                        fontSize: "16px",
                        width: "100%",
                        borderRadius: "4px",
                      }}
                      buttonStyle={{
                        backgroundColor: "transparent",
                        borderRadius: "4px 0 0 4px",
                        border: "none",
                      }}
                      dropdownStyle={{
                        borderRadius: "8px",
                      }}
                      containerStyle={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #acacb1",
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Total Spend"
                      name="totalSpend"
                      fullWidth
                      value={totalSpend}
                      onChange={(e) => setTotalSpend(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Commission %"
                      name="commissionPercent"
                      fullWidth
                      value={commissionPercent}
                      onChange={(e) => setCommissionPercent(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="City"
                      name="city"
                      fullWidth
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="preface-label">Prefaces</InputLabel>
                      <Select
                        labelId="preface-label"
                        value=""
                        onChange={handleSelectChange}
                        input={<OutlinedInput label="Prefaces" />}
                      >
                        {Prefaces.map((preface) => (
                          <MenuItem key={preface} value={preface}>
                            {preface}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", gap: 1, marginTop: 2 }}>
                      {selectedPrefaces.map((preface, index) => (
                        <Chip
                          key={index}
                          label={preface}
                          onDelete={handleDeleteChip(preface)}
                          sx={{
                            backgroundColor: "#C3E329",
                            color: "#000",
                            fontWeight: "bold",
                            "& .MuiChip-deleteIcon": {
                              color: "#000",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop="1%" paddingLeft="44px">
                  <Grid item xs={12} sm={12} md={6}></Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Button
                      disableElevation
                      sx={{
                        background: "#0D6EFD",
                        border: "1px solid #0D6EFD",
                        marginRight: "10px",
                        borderRadius: "4px",
                        textTransform: "none",
                        width: "100%",
                        color: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#0256d9",
                          borderColor: "#0256d9",
                        },
                      }}
                      onClick={handleAddClient}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>

      {/*add client data modal*/}
      <div>
        <Modal
          open={OpenAddClientDataModal}
          onClose={handleCloseAddClientDataModal}
          aria-labelledby="modal-title-AddClientDataModal"
          aria-describedby="modal-description-AddClientDataModal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "1px solid #DDE2E5",
              borderRadius: "20px",
              boxShadow: 24,
              width: "30%",
              height: "90%",
              p: 4,
            }}
          >
            <Typography
              variant="h4"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ fontSize: "20px" }}>Add Client Data</div>
              <div style={{ flexGrow: 1, textAlign: "right" }}>
                <IoClose
                  onClick={handleCloseAddClientDataModal}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    fontSize: "27px",
                  }}
                />
              </div>
            </Typography>

            <Box display="flex" marginTop="20px">
              <Box maxWidth="100%">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      style={{ borderRadius: "4px" }}
                      label="Search"
                      name="Search"
                      fullWidth
                      value={search}
                      onChange={(e) => setSearch(e.target.value)} // This handles every change in the input
                      onKeyDown={handleSearchChange}
                    />
                    {selectedResult && (
                      <Box
                        style={{
                          backgroundColor: "#EDF2F7",
                          padding: "2px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                        sx={{
                          display: "flex",

                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Avatar
                          sx={{ height: "56px", width: "56px" }}
                          alt={selectedResult.name}
                          src="https://s3-alpha-sig.figma.com/img/5744/9ba8/7714695b75ed83e945a8387f0113a115?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AUalZBVaJVOhxRQ3gXnIacp9TFlQtvPA7HHIYP8xGi9JId8TefQqa5Whz4~9QuMyfnXzF1HvTkqTXKqGH~P-GN1mIXWzu32calo8jy~aP3fp2vWtNl7HHlWgp-2ey6RUI4rkLYp~7qgDZdMXm7VfwoV6V2-Psi5noxYERhbr8NlyNbHdMQrO6aLWQFaAFLEIKM1LooIe7j87oa261DQAl28X9MbZpqG4GrnCRmYDaufqD7DsBoeai~1d7EKHIbk7v3zd6lCd4Kq22ZE7V93A3esQEoYu3VROCTNFwGjPq4t0KUkfhQo26UHbJOZasggHlwaYG2NFnLYToAr2FhcX5A__"
                          variant="rounded"
                        />
                        <Box>
                          <Typography variant="h5" paddingLeft={2}>
                            {selectedResult.name}
                          </Typography>
                          <Typography paddingLeft={2} display="flex">
                            <Link
                              style={{ cursor: "pointer" }}
                              paddingLeft={1}
                              color="#c9c1c1"
                            >
                              {selectedResult.instagram_link}
                            </Link>
                          </Typography>
                          <Typography
                            style={{ color: "#979595" }}
                            paddingLeft={2}
                            display="flex"
                          >
                            Contact: {selectedResult.contact}
                            <Typography
                              paddingLeft={2}
                              display="flex"
                              style={{ color: "#6870fa" }}
                            >
                              {selectedResult.detail}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <div>
                    {showResults && (
                      <Modal
                        open={showResults}
                        onClose={() => setShowResults(false)}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "80%",
                            transform: "translate(-58%, -40%)",
                            bgcolor: "background.paper",
                            border: "1px solid #DDE2E5",
                            borderRadius: "4px",
                            boxShadow: 24,
                            width: "25%",
                            minHeight: "10%",
                            maxHeight: "70%",
                            overflowY: "auto",
                            p: 2,
                          }}
                        >
                          <Typography
                            variant="h4"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div style={{ fontSize: "20px" }}>
                              Search Results
                            </div>
                            <div style={{ flexGrow: 1, textAlign: "right" }}>
                              <IoClose
                                onClick={() => setShowResults(false)}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  fontSize: "27px",
                                }}
                              />
                            </div>
                          </Typography>

                          {results.map((result, index) => (
                            <Box
                              key={index}
                              onClick={() => setSelectedResult(result)}
                              style={{
                                marginBottom: "16px",
                                backgroundColor: "#EDF2F7",
                                padding: "2px",
                                borderRadius: "4px",
                                cursor: "pointer",
                              }}
                              sx={{
                                display: "flex",
                                height: "100%",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Avatar
                                sx={{ height: "56px", width: "56px" }}
                                alt={result.name}
                                src="https://s3-alpha-sig.figma.com/img/5744/9ba8/7714695b75ed83e945a8387f0113a115?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AUalZBVaJVOhxRQ3gXnIacp9TFlQtvPA7HHIYP8xGi9JId8TefQqa5Whz4~9QuMyfnXzF1HvTkqTXKqGH~P-GN1mIXWzu32calo8jy~aP3fp2vWtNl7HHlWgp-2ey6RUI4rkLYp~7qgDZdMXm7VfwoV6V2-Psi5noxYERhbr8NlyNbHdMQrO6aLWQFaAFLEIKM1LooIe7j87oa261DQAl28X9MbZpqG4GrnCRmYDaufqD7DsBoeai~1d7EKHIbk7v3zd6lCd4Kq22ZE7V93A3esQEoYu3VROCTNFwGjPq4t0KUkfhQo26UHbJOZasggHlwaYG2NFnLYToAr2FhcX5A__"
                                variant="rounded"
                              />
                              <Box>
                                <Typography variant="h5" paddingLeft={2}>
                                  {result.name}
                                </Typography>
                                <Typography paddingLeft={2} display="flex">
                                  <Link
                                    style={{ cursor: "pointer" }}
                                    paddingLeft={1}
                                    color="#c9c1c1"
                                  >
                                    {result.instagram_link}
                                  </Link>
                                </Typography>
                                <Typography
                                  style={{ color: "#979595" }}
                                  paddingLeft={2}
                                  display="flex"
                                >
                                  Contact: {result.contact}
                                  <Typography
                                    paddingLeft={2}
                                    display="flex"
                                    style={{ color: "#6870fa" }}
                                  >
                                    {result.detail}
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Modal>
                    )}
                  </div>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#EDF2F7",
                        borderColor: "none",
                      }}
                      label="Club Name"
                      name="Club Name"
                      fullWidth
                      value={club}
                      onChange={(e) => setclub(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={10.1}>
                    <TextField
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#EDF2F7",
                        borderColor: "none",
                      }}
                      label="Event Date"
                      name="Event Date"
                      fullWidth
                      value={datemsg}
                      onChange={(e) => setdatemsg(e.target.value)}
                    />
                  </Grid>
                  <DatePicker
                    selected={eventdate}
                    onChange={handleDateChange}
                    customInput={
                      <div
                        style={{
                          backgroundColor: "#6870fa",
                          width: "50px",
                          height: "46px",
                          marginTop: "24px",
                          borderRadius: "8px",
                          marginLeft: "6px",
                        }}
                      >
                        <FaCalendar
                          style={{
                            color: "white",
                            fontSize: "22px",
                            marginLeft: "13px",
                            marginTop: "10px",
                          }}
                        />
                      </div>
                    }
                    showPopperArrow={false}
                  />
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#EDF2F7",
                        borderColor: "none",
                      }}
                      label="Total Spend"
                      name="totalSpend"
                      fullWidth
                      value={totalSpend2}
                      onChange={(e) => setTotalSpend2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#EDF2F7",
                        borderColor: "none",
                        fontWeight: "bolder",
                      }}
                      label="Commission %"
                      name="commissionPercent"
                      fullWidth
                      value={commissionPercent2}
                      onChange={(e) => setCommissionPercent2(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop="1%">
                  <Grid item xs={12} sm={12} md={6}></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      disableElevation
                      sx={{
                        background: "#0D6EFD",
                        border: "1px solid #0D6EFD",
                        height: "50px",
                        borderRadius: "8px",
                        fontSize: "15px",
                        textTransform: "none",
                        width: "100%",
                        color: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#0256d9",
                          borderColor: "#0256d9",
                        },
                      }}
                      onClick={handleAddClientData}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>

      {/* Edit Client Profile Modal */}
      <div>
        <Modal
          open={openEditClientModal}
          onClose={handleCloseEditClientModal}
          aria-labelledby="modal-title-AddNewClientModal"
          aria-describedby="modal-description-AddNewClientModal"
        >
          <EditClientManagementClientProfile
            ID={profileToEditID}
            handleCloseEditClientModal={handleCloseEditClientModal}
          />
        </Modal>
      </div>
    </Paper>
  );
};

export default ClientManagement;
