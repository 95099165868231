import React, { useState } from "react";
import { Box, Typography, useTheme, IconButton, Button } from "@mui/material";
// import Grid from "@mui/material/Grid";
import { tokens } from "../../utils/theme.js";
// import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import ProgressBar from "@ramonak/react-progress-bar";

// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";

// import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ArrowUpward } from "@mui/icons-material";

// import LineChartComponent_gradient from './LineChartComponent_gradient';
import Analytics from "./Analytics";
// import { scaleWidth } from "../../utils/units.js";
const Statbox_AvgAgentsEarnings = () => {
  // const [totalApprovedText, setTotalApprovedText] = useState("2.1k");
  const [avgAgentEarning, setAvgAgentEarning] = useState("3,275.94");
  const [percentEarningChange, setPercentEarningChange] = useState(9.2);
  const [percentNoReplies, setPercentNoReplies] = useState(20);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  // const TotalApprovedStateFormatter = (totalApproved) => {
  //   totalApproved > 1000
  //     ? setTotalApprovedText(totalApproved / 1000 + "k")
  //     : setTotalApprovedText(totalApproved);
  // };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      {/* <Box className="card-header" height="82px" padding="22.75px 0 6.5px 12px"> */}
        {/* <Box> */}
          {/* <Box sx={{ flexGrow: 1 }}> */}
            {/* <Grid container spacing={2}>
              <Grid item xs={7} md={7}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" color={colors.grey[500]}>
                    $
                  </Typography>
                  <Typography
                    variant="h2"
                    color={colors.grey[200]}
                    paddingLeft={1}
                    fontWeight="bold"
                  >
                    {avgAgentEarning}
                  </Typography>
                  <Box
                    color="#4AAE8C"
                    marginLeft="10px"
                    backgroundColor="#DEFFEE"
                    padding="4px 6px 4px 3px"
                    borderRadius="15px"
                    display="flex"
                    alignSelf="center"
                    fontSize="10px"
                  >
                    <ArrowUpward sx={{ height: "15px", width: "15px" }} />{" "}
                    {percentEarningChange} %
                  </Box>
                </Box>
                <Typography variant="h6" color={colors.grey[500]}>
                  Avg. Agent Earnings
                </Typography>
              </Grid>
              <Grid item xs={5} md={5}>
                <Box position="relative" display="inline-block" width="90%">
                  <input
                    type="text"
                    readOnly
                    value={`${dateRange[0].startDate.toDateString()} - ${dateRange[0].endDate.toDateString()}`}
                    onClick={toggleOpen}
                    style={{
                      padding: "7px",
                      width: "100%",
                      textAlign: "center",
                      background: "#F9F9F9",
                      border: "0px",
                      borderRadius: "6px",
                      color: "#7E8299",
                    }}
                  />
                  {open && (
                    <Box position="absolute" zIndex="1000" top="100%" left="0">
                      <DateRangePicker
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        direction="horizontal"
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid> */}
          {/* </Box> */}
        {/* </Box> */}
      {/* </Box> */}
      <Box
        className="card-content"
        // display="grid"
        alignItems="flex-start"
        // marginTop="2vh"
        // width='200px'
        // style={{width: scaleWidth(656)}}
        // backgroundColor= 'red'
      >
        {/* <Box 
        // display="flex"
        // style={{ background: "#4C6FFF" }}
        >
          <Button
            variant="contained"
            sx={{ width: "50%" }}
            style={{ color: "#FFFFFF", backgroundColor: "#4C6FFF" }}
            disableElevation
          >
            Earnings From Events
          </Button>
          <Button
            variant="contained"
            sx={{ width: "50%" }}
            style={{ color: "#3F4254", backgroundColor: 'white' }}
            disableElevation
          >
            Earnings From Commisions
          </Button>
        </Box> */}
        {/* <Box style={{background: 'red'}}> */}
          {/* <LineChartComponent_gradient /> */}
          <Analytics />
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default Statbox_AvgAgentsEarnings;
