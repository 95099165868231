import axiosClient from "./axiosClient";
import { API_ENDPOINTS } from "./apiEndpoints";

const CITY_AGENCIES_LIST = {
  async getCityAgenciesList(pageNumber) {
    try {
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(
        `/${API_ENDPOINTS.CITY_AGENCIES_LIST}`,
        {
          params: { page: pageNumber },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profiles:", error);
      return [];
    }
  },

  async getCityAgenciesModelsListByID(ID) {
    try {
      const token = localStorage.getItem("user");
      console.log("User token: ", token);
      const response = await axiosClient.get(
        `/${API_ENDPOINTS.CITY_AGENCIES_MODELS_LIST}`,
        {
          params: { id: ID },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // debugger;
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      console.error("Error fetching profile:", error);
      return [];
    }
  },

};

export default CITY_AGENCIES_LIST;
