import axiosClient from "./axiosClient";
// import axios from "axios";
import { API_ENDPOINTS } from "./apiEndpoints";

const AGENCIES = {
  async getAgenciesList() {
    try {
      //   debugger;
      const token = localStorage.getItem("user");
      const response = await axiosClient.get(`/${API_ENDPOINTS.AGENCIES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      return error?.response?.data;
      // return { data: null, error: error.response };
    }
  },

  async getAgenciesListForCityAgenciesPage() {
    try {
      //   debugger;
      const token = localStorage.getItem("user");
      const response = await axiosClient.get(
        `/${API_ENDPOINTS.CITY_AGENCIES_AGENCIES_LIST}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.status == 200 ? response.data : null;
      // return response.status == 200 ? { data: response.data, error: null } : { data: null, error: error.response };
    } catch (error) {
      return error?.response?.data;
      // return { data: null, error: error.response };
    }
  },
};

export default AGENCIES;
