import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../utils/theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(title);
        if (onClick) onClick(); // Execute the custom onClick function if provided
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/login"); // Redirect to the login page
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        height: "100vh",
        "& .pro-sidebar-inner": {
          background: `${colors.grey[1000]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          borderLeft: "solid 3px #4C6FFF !important",
          color: "#FFFFFF !important",
        },
        "& .pro-menu-item.active .pro-inner-item": {
          background: "#4C6FFF !important",
          margin: !isCollapsed
            ? "5px 35px 5px 20px !important"
            : "5px 17px 5px 12px !important",
          padding: "5px 0px 5px 7px !important",
          borderRadius: "6px",
        },
        "& .pro-sidebar .pro-menu": {
          paddingTop: "0px !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* Menu Collapse/UnCollapse Icon */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mx="2px"
              >
                <img
                  alt="Profile-user"
                  src={`../../assets/ges_logo.png`}
                  width="130vh"
                  height="48px"
                  style={{ cursor: "pointer" }}
                />
              </Box>
            )}
          </MenuItem>

          {/* Menu Items */}
          <Box paddingLeft={isCollapsed ? undefined : "10px"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<InsertChartIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Manage Chat"
              to="/"
              icon={<ChatOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Talent Pool"
              to="/talentpool"
              icon={<PersonIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="City Agencies"
              to="/cityagencies"
              icon={<LocationCityIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Work Calendar"
              to="/workcalendar"
              icon={<CalendarMonthIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="New Faces"
              to="/newfaces"
              icon={<TagFacesIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Client Management"
              to="/clientmanagement"
              icon={<ContactMailIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Story Location"
              to="/locationtracker"
              icon={<LocationOnIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Setting"
              to="/"
              icon={<SettingsTwoToneIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Logout"
              to="#"
              icon={<LogoutOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              onClick={handleLogout} // Handle logout
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
