import React, { useState } from "react";
import { Box, Typography, useTheme, IconButton } from "@mui/material";
import { tokens } from "../../utils/theme";
import InfoIcon from "@mui/icons-material/Info";
import LineChartComponent from "../LineChart/LineChartComponent";

const Statbox_LifeTimeSavedByGESThisWeek = () => {
  const [lifeTimeSaved, setLifeTimeSaved] = useState(256);
  const [percentReplies, setPercentReplies] = useState(60);
  const [percentNoReplies, setPercentNoReplies] = useState(40);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Box className="card-header" padding="22.75px 29.25px 6.5px 12px">
        <Box display="flex">
          <Typography variant="h5" color={colors.grey[100]}>
            Life time saved by GES this week
          </Typography>
          <div>
            <InfoIcon sx={{ marginLeft: "10px", color: "#4C6FFF" }} />
          </div>
        </Box>

        <Typography variant="h6" color={colors.grey[500]}>
          Social activities overview
        </Typography>

        <Typography variant="h2" color={colors.grey[100]}>
          {lifeTimeSaved}h
        </Typography>
      </Box>
      <Box className="card-content" display="grid" alignItems="end">
        <LineChartComponent />
      </Box>
    </Box>
  );
};

export default Statbox_LifeTimeSavedByGESThisWeek;
